export const AB_MASTER_COOKIE_NAME = '_abMaster';
export const ANALYTICS_COOKIE_NAME = 'auuid';
export const CSRF_TOKEN = '_csrfToken';
export const MAM_COOKIE_NAME = 'mam';

export const getAffiliateCookies = (siteAbbreviation?: string) => [
  MAM_COOKIE_NAME,
  'mamident',
  'ata_SID',
  `${siteAbbreviation}_PA`,
];
