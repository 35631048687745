import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useAppData } from '../../helpers/useAppData';

const removeLoader = () => {
  const htmlTag = document.getElementsByTagName('html')[0];
  htmlTag.className = htmlTag.className.replace(/\bloading\b/g, '');
  const loader = document.getElementById('loader');
  if (loader) {
    loader.dataset.status = 'loaded';
  }
};

type Props = {
  setUserLoaded: (loaded: boolean) => void;
};

export const AuthFrame = (props: Props) => {
  const { setUserLoaded } = props;

  const { site, user } = useAppData();

  const iframeEl = useRef<HTMLIFrameElement>(null);
  const location = useLocation();

  const onLoad = () => {
    if (!user?.isUser) {
      iframeEl.current?.contentWindow?.postMessage('ping', '*');
      return;
    }

    setUserLoaded(true);
    removeLoader();
  };

  const ssoReceiveMessage = useCallback(
    (e: MessageEvent) => {
      if (!iframeEl.current || !site) {
        return;
      }

      if (e.origin !== site.ssoUrl) {
        return;
      }

      if (e.data === true) {
        window.removeEventListener('message', ssoReceiveMessage, false);
        window.location.replace(`/auth/man/?r=${window.location.pathname}&${location.search.substring(1)}`);
        return;
      }

      setUserLoaded(true);
      removeLoader();
    },
    [location.search, setUserLoaded, site],
  );

  useEffect(() => {
    window.addEventListener('message', ssoReceiveMessage, false);
  }, [ssoReceiveMessage]);

  if (!site) {
    return null;
  }

  return (
    <FrameWrapper
      id="auth-iframe"
      ref={iframeEl}
      title="auth-iframe"
      src={`${site.ssoUrl}/auth/iframe/${site.UUID}`}
      onLoad={onLoad}
    />
  );
};

const FrameWrapper = styled.iframe`
  display: none;
  height: 0;
`;
