import { useCallback } from 'react';
import { useAppData } from './useAppData';

export const useCDN = () => {
  const { cdnUrl } = useAppData();

  const generateCdnUrl = useCallback(
    (...pathParts: string[]) => {
      const fulUrl = [cdnUrl, ...pathParts].join('/');

      return fulUrl;
    },
    [cdnUrl],
  );

  return generateCdnUrl;
};
