import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import type { CartItem as CartItemType } from 'src/client/pages/Upgrades/useUpgradesCart';
import { CartItem } from './CartItem';
import { Button } from '../Button/Button';

type StyledComponentProps = {
  responsive?: boolean;
};

const CartWrapper = styled.div`
  color: #ffffff;
`;

const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
  }
`;

const Bar = styled.hr<StyledComponentProps>`
  border: 1px rgba(255, 255, 255, 0.2) solid;
  margin: 18px 0;
  width: 100%;

  ${({ responsive }) =>
    responsive
      ? css`
          @media (min-width: 1024px) {
            display: none;
          }
        `
      : ''}
`;

const Count = styled.span<StyledComponentProps>`
  ${({ responsive }) =>
    responsive
      ? css`
          @media (min-width: 1024px) {
            display: none;
          }
        `
      : ''}
`;

const Total = styled.div<StyledComponentProps>`
  display: flex;

  > span {
    flex-grow: 1;
  }

  ${({ responsive }) =>
    responsive
      ? css`
          @media (min-width: 1024px) {
            display: none;
          }
        `
      : ''}
`;

const TotalText = styled.span`
  flex-grow: 1;
  text-transform: uppercase;
`;

const Price = styled.span`
  text-align: right;
`;

const ButtonWrapper = styled.div`
  margin: 24px 0;

  @media (min-width: 1338px) {
    margin: 48px 0;
  }
`;

type Props = {
  items: CartItemType[];
  onStartPurchase?: (disableButton: boolean) => void;
  removePlan: (siteUUID: string) => void;
  responsive?: boolean;
  showCompleteButton?: boolean;
  total: number;
};

export const CheckoutCart = (props: Props) => {
  const {
    showCompleteButton = false,
    items = [],
    onStartPurchase = () => null,
    removePlan,
    responsive = false,
    total = 0,
  } = props;

  const [disableButton, setDisableButton] = useState(false);

  const handleStartPurchase = () => {
    onStartPurchase(true);
    setDisableButton(true);
  };

  const purchaseDisabled = items.length === 0;
  const message = `You have ${items.length} item${items.length > 1 || items.length === 0 ? 's' : ''} in your cart`;

  return (
    <CartWrapper>
      <Title>Review Cart</Title>
      <Bar />
      <Count responsive={responsive}>{message}</Count>
      <Bar responsive={responsive} />
      {items.map((item) => (
        <React.Fragment key={item.siteUUID}>
          <CartItem
            abbreviation={item.abbreviation}
            access={item.access}
            networkUUID={item.networkUUID}
            price={item.price}
            removePlan={removePlan}
            responsive={responsive}
            site={item.site}
            siteUUID={item.siteUUID}
          />
          <Bar />
        </React.Fragment>
      ))}
      <Total responsive={responsive}>
        <TotalText>Total</TotalText>
        <Price>&#36;{total.toFixed(2)}</Price>
      </Total>
      {showCompleteButton && (
        <ButtonWrapper>
          <Button disabled={disableButton || purchaseDisabled} text="Complete Order" onClick={handleStartPurchase} />
        </ButtonWrapper>
      )}
    </CartWrapper>
  );
};
