import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { addDashesToUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import { useAppData } from './useAppData';

type DataLayer = {
  gaTID?: string;
  ga4TID?: string;
  oneTrustID?: string;
  appVersion: string;
  userId?: string;
};

export const useGoogleTagManager = (userLoaded: boolean) => {
  const { commit, site, user, version } = useAppData();
  const gtmId = site?.gtm?.id;

  useEffect(() => {
    if (userLoaded && site && gtmId) {
      const dataLayer: DataLayer = {
        gaTID: site.googleAnalytics?.gaTID,
        ga4TID: site.googleAnalytics?.ga4TID,
        oneTrustID: site.oneTrustID,
        appVersion: `${version} ${commit}`,
      };

      if (user?.UUID) {
        dataLayer.userId = addDashesToUUID(user?.UUID);
      }

      TagManager.initialize({ gtmId, dataLayer });
    }
  }, [commit, gtmId, site, user, userLoaded, version]);
};
