import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export interface UpgradeProps {
  title: string;
  postData: string;
  postURL: string;
  className?: string;
}

function UpgradeFrame(props: UpgradeProps) {
  const { className, postURL, postData, title } = props;

  return (
    <div className={className}>
      <h1>{title}</h1>
      <form action={postURL} method="post">
        {postData.split('&').map((pd) => {
          const [k, v] = pd.split('=');
          return <input type="hidden" name={decodeURIComponent(k)} key={k} value={decodeURIComponent(v)} />;
        })}
        <button type="submit">Complete Purchase</button>
      </form>
    </div>
  );
}

UpgradeFrame.propTypes = {
  className: PropTypes.string,
  postURL: PropTypes.string,
  postData: PropTypes.string,
  title: PropTypes.string,
};

UpgradeFrame.defaultProps = {
  className: '',
  postURL: '',
  postData: '',
  title: 'Complete Upgrade',
};

export default styled(UpgradeFrame)`
  width: 100%;
  text-align: center;
  padding-bottom: 2rem;

  @media (min-width: 992px) {
    height: 100%;
  }

  h1 {
    font-size: 25px;
    font-weight: 300;
    text-align: center;

    @media (min-width: 578px) {
      font-size: 30px;
    }
  }
`;
