import React from 'react';
import styled from 'styled-components/macro';
import { ConstrainedContainer, TABLET_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/helpers/mediaQueries';
import { ToyThumbnail } from './ToyThumbnail';

export type Toy = {
  label: string;
  imageName: string;
};

const toys: Toy[] = [
  {
    label: 'Dorcel Perfect Lover',
    imageName: 'dorcel_perfect_lover',
  },
  {
    label: 'Rechargeable Magic Wand',
    imageName: 'magic_wand',
  },
  {
    label: 'Optimum Power\nPulsar Stroker',
    imageName: 'pulsar_stroker',
  },
  {
    label: 'Ouch! Ball Gag',
    imageName: 'ball_gag',
  },
  {
    label: 'Romp Reverb\nSuction Stimulator',
    imageName: 'suction_stimulator',
  },
  {
    label: 'The One Realistic Dildo',
    imageName: 'realistic_dildo',
  },
];

export const ToysSection = () => {
  return (
    <ToysSectionWrapper>
      <Title>Intimate Toys</Title>
      <ToysGrid>
        {toys.map((toy) => (
          <ToyThumbnail key={toy.imageName} toy={toy} />
        ))}
      </ToysGrid>
    </ToysSectionWrapper>
  );
};

const ToysSectionWrapper = styled(ConstrainedContainer)`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  text-align: center;
  color: white;
  font-size: 21px;
  font-weight: normal;
`;

const ToysGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);

  @media ${TABLET_PORTRAIT} {
    grid-template-columns: repeat(4, 1fr);
    & > :nth-last-child(-n + 2) {
      // prevent half empty row
      display: none;
    }
  }

  @media ${TABLET_LANDSCAPE} {
    grid-template-columns: repeat(6, 1fr);
    & > :nth-last-child(-n + 2) {
      display: block;
    }
  }
`;
