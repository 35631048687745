import React, { MouseEventHandler, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useAppData } from '../../helpers/useAppData';

type Props = {
  children: React.ReactNode;
  className?: string;
  onHide: () => void;
  show: boolean;
  showHeader?: boolean;
};

const ModalComponent = (props: Props) => {
  const { children, className, onHide, show, showHeader = true } = props;

  const modal = useRef(null);
  const modalRoot = useMemo(() => document.querySelector('body'), []);
  const modalElement = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    if (!modalRoot) return;
    modalRoot.appendChild(modalElement);
  }, [modalRoot, modalElement]);

  useEffect(() => {
    if (!modalRoot) return;

    if (!show) {
      modalRoot.style.removeProperty('overflow-y');
    } else {
      modalRoot.style.setProperty('overflow-y', 'hidden');
    }

    // remove the overflow-y property if the component unmounts with out
    // going through a show/hide render cycle.
    return () => {
      modalRoot.style.removeProperty('overflow-y');
    };
  }, [show, modalRoot]);

  const hide = () => {
    if (onHide) {
      onHide();
    }
  };

  const onClose: MouseEventHandler<HTMLDivElement> = ({ target }) => {
    if (target === modal.current) {
      hide();
    }
  };

  const { cdnUrl } = useAppData();

  const element = show ? (
    <div ref={modal} onClick={onClose} className={className} role="dialog">
      <div className="modal-wrapper">
        {showHeader && (
          <div className="modal-header">
            <img src={`${cdnUrl}/header/close.svg`} className="close" onClick={hide} alt="Close" />
          </div>
        )}
        <div className="modal-content">{children}</div>
      </div>
    </div>
  ) : null;

  return ReactDOM.createPortal(element, modalElement);
};

export const Modal = styled(ModalComponent)`
  background-color: rgba(0, 0, 0, 0.75);
  height: 100%;
  left: 0;
  margin: 0 auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;

  @media (min-width: 992px) {
    align-items: center;
    display: flex;
  }

  .modal-wrapper {
    background-color: #fff;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    -ms-flex: 1 0 auto; // ie11 fix
    flex: 1 0 auto;

    @media (min-width: 992px) {
      flex-basis: 0;
      flex-grow: 1;
      height: auto;
      margin: 0 auto;
      max-width: 500px;
      -ms-flex: 1 0 auto; // ie11 fix
    }
  }

  .modal-header {
    background-color: #fff;
    height: 64px;
    position: relative;
  }

  & .close {
    cursor: pointer;
    filter: invert(100%);
    height: 18px;
    position: absolute;
    right: 24px;
    top: 24px;
  }
`;
