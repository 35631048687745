import React, { ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { DiscountRibbon } from 'src/client/pages/Upgrades/DiscountRibbon';
import { JoinPageProduct } from 'src/shared/types';
import { UpgradeProduct } from 'src/shared/types/UpgradeProduct';
import { Button, LinkButton, StyledButton } from '../Button/Button';

const currencySigns: Record<string, string> = {
  USD: '$',
  BTC: 'B',
};

const Wrapper = styled.div<{ active: boolean }>`
  background-color: #e1e2e7;
  border-radius: 4px;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  padding: 12px 24px;
  position: relative;
  transition: transform 0.2s ease-in-out;
  max-width: 300px;
  width: 100%;

  :hover {
    transform: scale(1.05);

    & ${StyledButton} {
      background-color: #000000;
      opacity: 0.9;
    }
  }

  svg {
    margin-left: 0.25em;
  }

  ${({ active }) =>
    active
      ? css`
          background-color: #ff504a;
          color: #ffffff;

          :hover {
            & ${StyledButton} {
              opacity: 1;
            }
          }
        `
      : ''}
`;

const Period = styled.p`
  font-size: 16px;
  margin: 16px 0 10px;
  text-align: center;

  @media (min-width: 1920px) {
    font-size: 32px;
    margin: 28px 0 12px;
  }
`;

const Bar = styled.hr<{ active: boolean }>`
  border-color: rgba(0, 0, 0, 0.2);
  border-top: 1px;
  margin: 0;

  ${({ active }) =>
    active
      ? css`
          border-color: rgba(255, 255, 255, 0.2);
        `
      : ''}
`;

const Price = styled.div`
  font-weight: 500;
  margin: 0;
  position: relative;
  text-align: center;

  @media (min-width: 1920px) {
    margin: 12px 0 0;
  }
`;

const Amount = styled.span`
  font-size: 52px;
  letter-spacing: -2px;

  @media (min-width: 1920px) {
    font-size: 72px;
    font-weight: 700;
  }
`;

const Currency = styled.span`
  font-size: 12px;
  left: -4px;
  position: relative;
  top: -22px;

  @media (min-width: 1920px) {
    font-size: 24px;
    top: -30px;
  }
`;

const Cents = styled.span`
  font-size: 16px;
  letter-spacing: normal;
  position: relative;
  right: -2px;
  top: -24px;

  @media (min-width: 1920px) {
    font-size: 32.4px;
    top: -27px;
  }
`;

const Terms = styled.span`
  display: block;
  font-size: 10px;
  margin: 8px 0;
  text-align: center;

  @media (min-width: 1920px) {
    margin: 16px 0;
  }
`;

const ButtonText = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StyledDiscountRibbon = styled(DiscountRibbon)`
  height: 20px;
  top: 5px;
  font-size: 12px;
  left: 0;
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  margin-bottom: 10px;
`;

type Props = {
  abbreviation: string;
  active: boolean;
  className?: string;
  product: JoinPageProduct;
  selectPlan: (productUUID: string) => void;
  site: UpgradeProduct;
};

export const PricePlan = (props: Props) => {
  const { active, className, product, site } = props;
  const [dollars, cents] = product.initialPrice.split('.');

  const showDescription = product.description && site.externalSite;

  return (
    <Wrapper active={active} className={className}>
      <StyledDiscountRibbon
        discountRibbonColor={site.discountRibbonColor}
        discountRibbonText={site.discountRibbonText}
        showDiscountRibbon={product.productIsDiscounted}
        ribbonTextColor={site.ribbonTextColor}
      />
      <Period>{product.recurringPeriodText}</Period>
      <Bar active={active} />
      <Price>
        <Amount>
          <Currency>{currencySigns[product.currency]}</Currency>
          {dollars}
          <Cents>{cents}</Cents>
        </Amount>
      </Price>
      {showDescription && <Description>{product.description}</Description>}

      {/* @TODO Refactor this part to use different button component so we can
        get rid of the jank in src/client/components/Button/Button.tsx */}
      <Button {...getButtonProps({ ...props })} />
      <Terms>{product.recurringText}</Terms>
    </Wrapper>
  );
};

const getButtonText = (props: Props) => {
  const { site } = props;
  if (site.externalSite) {
    return (
      <ButtonText>
        <span>Join {site.domain}</span>
        <FontAwesomeIcon icon={faExternalLink} />
      </ButtonText>
    );
  }

  if (props.active) {
    return 'Added to Cart';
  }

  return 'Select Plan';
};

const getButtonProps = (props: Props) => {
  const { active, selectPlan, product: price, site } = props;
  const text = getButtonText(props);

  const buttonProps: ComponentProps<typeof LinkButton> = {
    bgColor: active ? 'black' : 'gray',
    textColor: 'light',
    text,
  };

  if (site.externalSite) {
    buttonProps.as = 'a';
    buttonProps.href = site.fullAffiliateLink;
    buttonProps.rel = 'noopener noreferrer';
    buttonProps.target = '_blank';
  }

  if (!buttonProps.href) {
    buttonProps.onClick = () => selectPlan(price.productUUID);
  }

  return buttonProps as ComponentProps<typeof Button> & ComponentProps<typeof LinkButton>;
};
