import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiUrls } from '../../helpers/constants';
import { ApiClient, useAppData } from '../../helpers';
import { SectionContainer } from '../../pages/PreJoinPageRedesign/SectionContainer';
import { SectionTitle } from '../../pages/PreJoinPageRedesign/SectionTitle';
import { LoginReminderModal } from '../LoginReminderModal/LoginReminderModal';
import { EmailErrors, PasswordErrors, StateType } from '../../pages/PreJoinPageRedesign/PreJoinPageRedesign';
import { mediaBreakpoints } from '../../helpers/mediaQueries';

type Props = {
  disabled: boolean;
  email: StateType<string>;
  emailError: StateType<EmailErrors | undefined>;
  emailValidating: StateType<boolean>;
  password: StateType<string>;
  passwordError: StateType<PasswordErrors | undefined>;
  showLoginModal: StateType<boolean>;
  renderEmailErrors: StateType<boolean>;
  renderPasswordErrors: StateType<boolean>;
};

export const UserForm = (props: Props) => {
  const { cdnUrl, user } = useAppData();
  const {
    disabled,
    email: [email, setEmail],
    emailError: [emailError, setEmailError],
    emailValidating: [emailValidating, setEmailValidating],
    password: [password, setPassword],
    passwordError: [passwordError, setPasswordError],
    showLoginModal: [showLoginModal, setShowLoginModal],
    renderEmailErrors: [renderEmailErrors, setRenderEmailErrors],
    renderPasswordErrors: [renderPasswordErrors, setRenderPasswordErrors],
  } = props;

  const intl = useIntl();
  const location = useLocation();

  const [previousEmail, setPreviousEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (emailError === EmailErrors.ALREADY_EXISTS) {
      setShowLoginModal(true);
    }
  }, [emailError, setShowLoginModal]);

  const emailOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);

    if (emailError && emailError !== EmailErrors.ALREADY_EXISTS) {
      setRenderEmailErrors(false);
    }
  };

  const emailOnBlur = async () => {
    if (email === previousEmail || emailValidating) {
      return;
    }

    setPreviousEmail(email);
    await validateEmail();
    setRenderEmailErrors(true);
  };

  const validateEmail = async () => {
    const isEmailValue = isEmail(email, { allow_utf8_local_part: false });

    if (isEmailValue) {
      setEmailValidating(true);
      try {
        const api = new ApiClient();
        const {
          body: { account },
        } = await api.get(apiUrls.get.account, {
          params: {
            email,
          },
        });

        if (account) {
          setEmailError(EmailErrors.ALREADY_EXISTS);
        } else {
          setEmailError(undefined);
        }
      } catch (e: unknown) {
        const error = e as Error;
        setEmailError(error.message === 'Bad Request' ? EmailErrors.TOO_MANY_TRIES : EmailErrors.AVAILABILITY_GENERIC);
      }
      setEmailValidating(false);
    } else {
      setEmailError(EmailErrors.INVALID_FORMAT);
    }
  };

  const passwordOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);

    if (passwordError && emailError !== EmailErrors.ALREADY_EXISTS) {
      setRenderPasswordErrors(false);
    }
  };

  const passwordOnBlur = () => {
    const valid = isLength(password, { min: 4 });
    if (valid) {
      setPasswordError(undefined);
    } else {
      setPasswordError(PasswordErrors.INVALID_FORMAT);
    }
    setRenderPasswordErrors(true);
  };

  const urlSafePath = [
    location.pathname.replace(/^\//, ''),
    location.search ? `&${location.search.substring(1)}` : '',
  ].join('');

  return (
    <SectionContainer>
      <SectionTitle stepNumber={1} text={intl.formatMessage({ id: 'form.redesign.title' })} />
      <Form>
        <InputWrapper>
          {emailValidating && <ValidatingIcon icon={faCircleNotch} className="fa-spin" />}
          <Input
            autoComplete="email"
            disabled={disabled || emailValidating}
            onChange={emailOnChange}
            onBlur={emailOnBlur}
            placeholder={intl.formatMessage({ id: 'email.form.email.redesign.placeholder' })}
            type="email"
            value={email}
          />
          {user.isUser && (
            <InputMessage>
              <FormattedMessage
                id="email.form.logout_message"
                values={{
                  logout: (
                    <a href={`/logout/?r=${urlSafePath}`}>
                      <FormattedMessage id="email.form.logout" />
                    </a>
                  ),
                }}
              />
            </InputMessage>
          )}
          {renderEmailErrors && emailError && (
            <InputMessage error>
              {emailError === EmailErrors.ALREADY_EXISTS && (
                <FormattedMessage
                  id={emailError}
                  values={{
                    login: (
                      <a href={`/login/?r=${urlSafePath}`}>
                        <FormattedMessage id="email.form.login" />
                      </a>
                    ),
                  }}
                />
              )}
              {emailError !== EmailErrors.ALREADY_EXISTS && emailError.toString()}
            </InputMessage>
          )}
        </InputWrapper>
        {!user.isUser && (
          <InputWrapper>
            <PasswordInputWrapper>
              <Icon
                onClick={() => setShowPassword(!showPassword)}
                src={`${cdnUrl}/join/pw_${showPassword ? 'show' : 'hidden'}.svg`}
                alt=""
              />
              <Input
                autoComplete="current-password"
                disabled={disabled}
                onChange={passwordOnChange}
                onBlur={passwordOnBlur}
                placeholder={intl.formatMessage({ id: 'email.form.password.redesign.placeholder' })}
                type={showPassword ? 'text' : 'password'}
                value={password}
              />
            </PasswordInputWrapper>
            {renderPasswordErrors && passwordError && (
              <InputMessage error>
                <FormattedMessage id={passwordError} />
              </InputMessage>
            )}
          </InputWrapper>
        )}
      </Form>
      <LoginReminderModal show={showLoginModal} onHide={() => setShowLoginModal(false)} />
    </SectionContainer>
  );
};

const ValidatingIcon = styled(FontAwesomeIcon)`
  && {
    position: absolute;
    right: 0;
    height: 24px;
    margin: 12px 16px 0 0;
    width: 24px;
    opacity: 0.5;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  gap: 26px;
  flex-direction: column;
  max-width: 100%;

  @media screen and ${mediaBreakpoints.md} {
    flex-direction: row;
    gap: 20px;
  }
`;

const PasswordInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputMessage = styled.div<{ error?: boolean }>`
  position: absolute;
  top: calc(100% + 3px);
  color: ${({ error }) => (error ? 'red' : 'black')};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Input = styled.input`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  color: #828282;
  height: 48px;
  width: 100%;
  padding: 10px 45px 10px 8px;
  border: 1px solid #0000004d;
  border-radius: 5px;
  max-width: 100%;

  @media screen and ${mediaBreakpoints.md} {
    width: 380px;
  }

  &::placeholder {
    color: #828282;
  }

  &:focus {
    outline: none;
  }
`;

const Icon = styled.img`
  position: absolute;
  right: 12px;
  height: 20px;
  width: 24px;
  cursor: pointer;
  //margin: auto 0;
`;
