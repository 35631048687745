import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { useSubscriptionRebillPeriod } from 'src/client/pages/PreJoinPage/useSubscriptionRebillPeriod';
import { JoinPageProduct } from 'src/shared/types';
import { Selectable } from 'src/client/components/TemplateA/Selectable/Selectable';
import { Price } from 'src/client/components/TemplateA/Price/Price';
import { Button } from 'src/client/components/TemplateA/Button/Button';
import { useSubscriptionInitialPeriod } from './useSubscriptionInitialPeriod';

interface Props {
  onClick: (pgpid: number) => void;
  subOption: number | undefined;
  loading: boolean | undefined;
  item: JoinPageProduct;
  itemIndex: number;
}

export const SubscriptionOption = (props: Props) => {
  const { subOption, loading, onClick, item, itemIndex } = props;
  const { currency, currencySymbol, priceGroupProductID, recurringPeriod } = item;

  const rebillMessage = useSubscriptionRebillPeriod(item);
  const initialPeriodMessage = useSubscriptionInitialPeriod(item);

  const isActive = subOption ? subOption === priceGroupProductID : itemIndex === 0;
  const itemPrice = item.perPeriod ? (Number(item.price) / 12).toFixed(2) : item.price;
  const [amount, cents] = itemPrice.split('.');

  return (
    <SelectableItemWrapper key={priceGroupProductID}>
      <SubscriptionSelectable onClick={() => onClick(priceGroupProductID)} active={isActive}>
        <InitialPeriod>{initialPeriodMessage}</InitialPeriod>
        <Price
          amount={amount}
          cents={cents}
          currency={currency}
          currencySymbol={currencySymbol}
          recurringPeriod={recurringPeriod}
        />
        <SubscriptionButton active={isActive} loading={loading}>
          <FormattedMessage id="subscription.choice.select_plan" />
        </SubscriptionButton>
        <RecurringText>
          <p>{rebillMessage}</p>
          {item.validationRequired && (
            <p className="validation">
              <FormattedMessage id="subscription.choice.validation_required" />
            </p>
          )}
        </RecurringText>
      </SubscriptionSelectable>
    </SelectableItemWrapper>
  );
};

const RecurringText = styled.div`
  text-transform: uppercase;
  font-size: 0.6rem;
  margin: 16px 0;

  > p {
    margin: 0;
  }
`;

const InitialPeriod = styled.p`
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0;
`;

export const SubscriptionButton = styled(Button)`
  padding: 10px;
  width: 160px;
  min-height: 48px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    /* min-height - 2x padding */
    height: 28px;
  }
`;

const SubscriptionSelectable = styled(Selectable)`
  transition: 0.3s all ease;
  opacity: 0.7;
  margin: 0;

  @media (max-width: 767px) {
    margin: 12px 0;
  }

  :hover,
  &.active {
    opacity: 1;
  }
`;

export const SelectableItemWrapper = styled.span`
  flex-grow: 1;
  flex-basis: 0;
  margin: 0;

  :not(:first-child) {
    @media (min-width: 768px) {
      margin: 0 0 0 8px;
    }
  }

  :nth-child(n + 4) {
    display: none;
  }
`;
