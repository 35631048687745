import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';
import { flags, languageNames, SupportedLanguage, supportedLanguages } from 'src/shared/constants/languages';
import { LanguageContext } from '../Providers';

export const LanguageSelector = () => {
  const { language, changeLanguage } = React.useContext(LanguageContext);
  const selectedLanguage = `${flags[language]} ${language.toUpperCase()}`;

  return (
    <Label htmlFor="LanguageSelector">
      <Icon icon={faGlobe} />
      {selectedLanguage}
      <Select
        id="LanguageSelector"
        value={language}
        onChange={(e) => changeLanguage(e.currentTarget.value as SupportedLanguage)}
      >
        {supportedLanguages.map((language) => (
          <option key={language} value={language}>
            {flags[language]} {languageNames[language]}
          </option>
        ))}
      </Select>
    </Label>
  );
};

const Label = styled.label`
  position: relative;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  color: lightgray;

  &:hover {
    color: white;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const Select = styled.select`
  appearance: none;
  font-size: 1.1rem;
  border: 0;
  outline: 0;
  opacity: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;
