import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { useAppData } from '../helpers/useAppData';

export const AppLoader = () => {
  const { cdnUrl, site } = useAppData();

  return (
    <Loader id="loader">
      <img src={`${cdnUrl}/logos/${site.networkUUID}/${site.abbreviation}@2x.png`} alt={site.name} />
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </Loader>
  );
};

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const Loader = styled.div`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  background: #000;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 12px;

  &[data-status='loaded'] {
    display: none;
  }

  img {
    max-width: 100%;
  }

  .spinner {
    animation: ${rotate} 2s linear infinite;
    margin: 25px;
    width: 50px;
    height: 50px;

    .path {
      stroke: #b7b6b7;
      stroke-linecap: round;
      animation: ${dash} 1.5s ease-in-out infinite;
    }
  }
`;
