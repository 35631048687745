import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { destructUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import { ApiClient, useURLSearchParams } from 'src/client/helpers';
import { apiUrls } from 'src/client/helpers/constants';
import { UpgradePage, UpgradeProduct } from 'src/shared/types/UpgradeProduct';

type FetcherParams = Partial<{
  promoCode: string;
  page: string;
}>;

type UpgradesResponse = {
  upgrades: UpgradeProduct[];
};

// The abbreviation is used for the thumbnail image, and the siteUUID is used
// as a key in the thumbnail grid.
const generatePlaceholderSite = (): UpgradeProduct => ({
  abbreviation: 'coming_soon',
  available: false,
  description: '',
  discountRibbonColor: '',
  discountRibbonText: '',
  domain: '',
  externalSite: true,
  freeSiteEligible: false,
  name: '',
  networkUUID: '00000000-0000-0000-0000-000000000000',
  products: [],
  ribbonTextColor: '',
  skipUpsales: true,
  tags: [],
  UUID: destructUUID(uuid()),
});

const placeholderThumbnails = Array.from({ length: 4 }, generatePlaceholderSite);

// The thumbnail grid is 4 columns wide, so we need to make sure we have a
// multiple of 4 thumbnails to fill the grid.  Placeholder thumbnails are
// added to the end of the list to fill the grid evenly.
const getNearestMultipleOfFour = (num: number) => {
  const remainder = num % 4;
  return remainder === 0 ? num : num + (4 - remainder);
};

const fetcher = (url: string, params: FetcherParams) =>
  new ApiClient()
    .get(url, { params })
    .then((res) => res.body)
    .then((body: UpgradesResponse) => {
      const freeSites = body.upgrades.filter(
        (upgrade) => upgrade.networkUUID !== '00000000-0000-0000-0000-000000000000',
      );

      const upgrades = body.upgrades
        .concat(placeholderThumbnails)
        .slice(0, getNearestMultipleOfFour(body.upgrades.length));

      return { freeSites, upgrades };
    });

type HookParams = {
  page: UpgradePage;
};

export const useUpgrades = ({ page }: HookParams) => {
  const query = useURLSearchParams();
  const params = { promoCode: query.get('promoCode'), page };
  const { data } = useSWR([apiUrls.get.upgrades, params], fetcher);

  const [activeSite, setActiveSite] = useState<UpgradeProduct | null>(null);

  // Filter out any external sites when clicking on the banner, and find the
  // first of our sites to select.
  const handleSpecialBannerClick = () => {
    const site = data?.upgrades.find((upgrade) => !upgrade.externalSite);
    setActiveSite(site || null);
  };

  // Once we have the data, try and open the first active site if it was set
  // in the query params.
  useEffect(() => {
    const abbreviation = query.get('site');
    const upgrades = data?.upgrades || [];

    if (upgrades.length > 0 && abbreviation) {
      const site = upgrades.find((upgrade) => upgrade.abbreviation === abbreviation);
      if (site) {
        setActiveSite(site);
      }
    }
  }, [query, data]);

  return {
    activeSite,
    freeSites: data?.freeSites || [],
    handleSpecialBannerClick,
    setActiveSite,
    upgrades: data?.upgrades || [],
  };
};
