import React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import {
  CartDrawer,
  FreeSiteModal,
  RemovePlanModal,
  ShoppingCartHeader,
  SimpleFooter,
  UpgradeHeaderStrip,
} from 'src/client/components';
import { ChatWidget } from 'src/client/components/ChatWidget/ChatWidget';
import { CAM_TRACKING_CODES, routerPaths } from 'src/client/helpers/constants';
import { ConstrainedContainer } from 'src/client/helpers/mediaQueries';
import { useUpgradesCart } from './useUpgradesCart';
import { useUpgrades } from './useUpgrades';
import { ThumbnailsGrid } from './ThumbnailsGrid';
import { HeroSection } from './HeroSection';
import { CamsSection } from './CamsSection';
import { ToysSection } from './ToysSection';

export const UpgradesPage = () => {
  const { freeSites, upgrades, activeSite, setActiveSite, handleSpecialBannerClick } = useUpgrades({
    page: 'upgrades',
  });

  const {
    addFreeSiteToCart,
    cartDrawerOpen,
    cartItems,
    finishFreeSiteModal,
    freeSiteModalOpen,
    hideCartDrawer,
    hideRemoveSiteModal,
    removeItemFromCart,
    removeSiteModalUUID,
    selectPlan,
    showCartDrawer,
    showRemoveSiteModal,
    total,
  } = useUpgradesCart({ upgrades, activeSite });

  if (upgrades.length === 0) {
    return null;
  }

  return (
    <UpgradesPageWrapper>
      <Helmet>
        <title>MetArt Network Upgrades</title>
      </Helmet>

      <UpgradeHeaderStrip onClick={handleSpecialBannerClick} />

      <ShoppingCartHeader count={cartItems.length} containMaxWidth onCartClick={showCartDrawer} sticky />

      <HeroSection />

      <ConstrainedContainer>
        <ThumbnailsGrid
          activeSite={activeSite}
          cartItems={cartItems}
          selectPlan={selectPlan}
          setActiveSite={setActiveSite}
          upgrades={upgrades}
          thumbnailPrefix="upgrades"
        />
      </ConstrainedContainer>

      <ToysSection />

      <CamsSection camTrackingId={CAM_TRACKING_CODES.UPGRADES} />

      <SimpleFooter />

      <CartDrawer
        checkoutUrl={routerPaths.upgrades.checkout}
        cartItems={cartItems}
        onClose={hideCartDrawer}
        removePlan={showRemoveSiteModal}
        show={cartDrawerOpen}
        total={total}
      />

      <RemovePlanModal removePlan={removeItemFromCart} siteUUID={removeSiteModalUUID} onHide={hideRemoveSiteModal} />

      <FreeSiteModal
        addFreeSiteToCart={addFreeSiteToCart}
        cart={cartItems}
        close={finishFreeSiteModal}
        products={freeSites}
        show={freeSiteModalOpen}
      />

      <ChatWidget jira-id="9dab1c19-00bf-3be7-b9b6-14a91ae44549" service-desk-id="3" />
    </UpgradesPageWrapper>
  );
};

const UpgradesPageWrapper = styled.main`
  background-color: #1c1c1c;
`;
