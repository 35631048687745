import React, { ComponentProps } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppData } from '../helpers';

type Props = ComponentProps<typeof Route>;

export const UserRoute = (props: Props) => {
  const { user } = useAppData();
  const isUser = user?.isUser || false;

  if (!isUser) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};
