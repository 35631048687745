export const Biller = {
  RocketGate: 1,
  CCBill: 2,
  Epoch: 3,
  GoCoin: 4,
  BitPay: 5,
  Internal: 6,
  Nats: 7,
  Vendo: 8,
  PayGarden: 9,
  CoinPayments: 10,
  CommerceGate: 11,
};

export const BillerById = Object.entries(Biller).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key,
  }),
  {} as Record<number, string>,
);

export const ChargeByPreviousBillers = [Biller.RocketGate, Biller.CCBill, Biller.Epoch];

export const SubscriptionType = {
  Trial: 1,
  OneTime: 2,
  Standard: 3,
  Lifetime: 4,
};
