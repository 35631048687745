import useSWRImmutable from 'swr/immutable';
import React, { createContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiClient } from 'src/client/helpers/ApiClient';
import { useSelectedLanguage } from 'src/client/helpers/useSelectedLanguage';
import { AppData } from 'src/shared/types';
import { apiUrls } from 'src/client/helpers/constants';
import { languagesCache } from './LanguageProvider';

export type ProvidedAppData = AppData & {
  loading: boolean;
  error?: unknown;
  siteSubdomain: string;
};

export const AppDataContext = createContext({} as ProvidedAppData);

type Options = Partial<{
  ipAddress: string;
  language: string;
  promoCode: string;
}>;

const fetcher = (url: string, options: Options) =>
  new ApiClient().get(url, { params: options }).then((res) => res.body);

const siteSubdomain = process.env.REACT_APP_SITE_SUBDOMAIN || 'www';

export const AppDataProvider: React.FC = (props) => {
  const location = useLocation();
  const [language] = useSelectedLanguage();
  const query = new URLSearchParams(location.search);

  const options: Options = {
    language: language,
  };

  if (query.has('ipAddress')) {
    options.ipAddress = query.get('ipAddress') as string;
  }

  if (query.has('promoCode')) {
    options.promoCode = query.get('promoCode') as string;
  }

  const { data, error } = useSWRImmutable<AppData>([apiUrls.get.appData, options], fetcher);

  useEffect(() => {
    if (data?.translations && !languagesCache.has(language)) {
      languagesCache.set(language, data.translations);
    }
  }, [data, language]);

  const providerValue = {
    ...(data as AppData),
    siteSubdomain,
    loading: !data && !error,
    error,
  };

  return <AppDataContext.Provider value={providerValue}>{props.children}</AppDataContext.Provider>;
};
