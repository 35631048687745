import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { ApiClient } from 'src/client/helpers';
import {
  CategoryId,
  CategoryIds,
  ConversionTypes,
  MeasurementTypes,
  NewSubscriptionProps,
  UseAnalyticsProps,
} from 'src/shared/types/userMeasurement';
import { getDeviceType } from '../../helpers/utils';
import { ANALYTICS_COOKIE_NAME } from '../../../shared/constants/cookies';

export const useAnalyticsSignals = () => {
  const [cookies] = useCookies([ANALYTICS_COOKIE_NAME]);
  const cookieAnalyticsUUID: string = cookies[ANALYTICS_COOKIE_NAME];

  const signalAnalyticsData = useCallback(
    async (useAnalyticsProps: UseAnalyticsProps) => {
      const { type, analyticsUUID, ...data } = useAnalyticsProps;
      const uniqueUUID = analyticsUUID ?? cookieAnalyticsUUID;

      const api = new ApiClient();
      try {
        await api.post('/api/analytics', {
          data: {
            data,
            type,
            uniqueUUID,
          },
        });
      } catch (err) {
        return err;
      }
    },
    [cookieAnalyticsUUID],
  );

  const signalAnalyticsPageView = useCallback(
    async (uuid: string, categoryId: CategoryId) => {
      signalAnalyticsData({
        categoryId,
        deviceType: getDeviceType(navigator.userAgent),
        type: MeasurementTypes.View,
        analyticsUUID: uuid,
      });
    },
    [signalAnalyticsData],
  );

  const signalAnalyticsUpgrade = async (categoryId: CategoryId) => {
    signalAnalyticsData({
      categoryId,
      conversionType: ConversionTypes.NewSubscription,
      deviceType: getDeviceType(navigator.userAgent),
      type: MeasurementTypes.Conversion,
    });
  };

  const signalAnalyticsNewSubscription = async (salesData?: NewSubscriptionProps) => {
    signalAnalyticsData({
      categoryId: CategoryIds.PageViewsAndNewSubscriptions,
      conversionType: ConversionTypes.NewSubscription,
      data: salesData,
      deviceType: getDeviceType(navigator.userAgent),
      type: MeasurementTypes.Conversion,
    });
  };

  return {
    signalAnalyticsPageView,
    signalAnalyticsUpgrade,
    signalAnalyticsNewSubscription,
  };
};
