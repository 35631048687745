import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faEyeSlash, faKey, faCircleNotch } from '@fortawesome/pro-light-svg-icons';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { SectionTitle } from './styles';

type Props = {
  customError: string;
  doesUserExist: boolean;
  email: string;
  emailOnBlur: () => void;
  isUser: boolean;
  password: string;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  validEmail: string | boolean;
  validPassword: string | boolean;
  validatePassword: () => void;
  validatingEmail: boolean;
};

export const EmailForm = (props: Props) => {
  const {
    customError,
    doesUserExist,
    email,
    emailOnBlur,
    isUser,
    password,
    setEmail,
    setPassword,
    validEmail,
    validPassword,
    validatePassword,
    validatingEmail,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const intl = useIntl();

  const renderEmailError = (validEmail !== '' && !validEmail) || doesUserExist;
  const renderPasswordError = validPassword !== '' && !validPassword;

  const urlSafePath = [
    location.pathname.replace(/^\//, ''),
    location.search ? `&${location.search.substring(1)}` : '',
  ].join('');

  const emailErrorId = doesUserExist ? 'email.form.login_message' : 'email.form.invalid_email';
  const titleStringId = isUser ? 'email.form.title' : 'email.form.title_new_user';

  return (
    <Container className="membership">
      <SectionTitle>
        <span>1.</span>
        <FormattedMessage id={titleStringId} />
      </SectionTitle>
      <form>
        <MembershipInputs>
          <Icon icon={faEnvelope} />
          {validatingEmail && <RightIcon icon={faCircleNotch} className="fa-spin" />}
          <Input
            autoComplete="email"
            disabled={isUser || validatingEmail}
            onChange={(event) => setEmail(event.target.value)}
            onBlur={emailOnBlur}
            placeholder={intl.formatMessage({ id: 'email.form.email.placeholder' })}
            type="email"
            value={email}
            {...(validatingEmail ? { className: 'validating' } : {})}
          />
          {renderEmailError && (
            <ErrorText>
              {customError || (
                <FormattedMessage
                  id={emailErrorId}
                  values={{
                    login: (
                      <a href={`/login/?r=${urlSafePath}`}>
                        <FormattedMessage id="email.form.login" />
                      </a>
                    ),
                  }}
                />
              )}
            </ErrorText>
          )}
          {isUser && (
            <FormattedMessage
              id="email.form.logout_message"
              values={{
                logout: (
                  <a href={`/logout/?r=${urlSafePath}`}>
                    <FormattedMessage id="email.form.logout" />
                  </a>
                ),
              }}
            />
          )}
        </MembershipInputs>

        {!isUser && (
          <MembershipInputs>
            <Icon icon={faKey} />
            {showPassword ? (
              <RightIcon icon={faEyeSlash} onClick={() => setShowPassword(false)} />
            ) : (
              <RightIcon icon={faEye} onClick={() => setShowPassword(true)} />
            )}
            <Input
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
              onBlur={validatePassword}
              placeholder={intl.formatMessage({ id: 'email.form.password.placeholder' })}
              type={showPassword ? 'text' : 'password'}
              value={password}
            />
            {renderPasswordError && (
              <ErrorText>
                <FormattedMessage id="email.form.invalid_password" />
              </ErrorText>
            )}
          </MembershipInputs>
        )}
      </form>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 24px;
`;

const MembershipInputs = styled.div`
  position: relative;

  :not(:last-child) {
    margin-bottom: 26px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  && {
    height: 24px;
    margin: 12px 0 0 16px;
    position: absolute;
    width: 24px;
    opacity: 0.5;
  }
`;

const RightIcon = styled(Icon)`
  && {
    margin: 12px 16px 0 0;
    right: 0;
  }
`;

const Input = styled.input`
  font-size: 1.25rem;
  font-weight: 300;
  height: 48px;
  width: 100%;
  padding: 0 8px 0 52px;

  &.validating {
    padding-right: 48px;
  }

  &:focus {
    outline: none;
  }
`;

const ErrorText = styled.span`
  top: 100%;
  color: #ff0000;
  left: 0;
  position: absolute;

  a {
    color: inherit;
  }
`;
