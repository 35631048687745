import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  cascadeCountDown: number;
};

const cascadeMessage1 =
  'We have encountered an issue while processing your purchase and the transaction was unsuccessful.';
const cascadeMessage2 = 'You will now be re-directed to our alternate biller, and we ask that you please try again.';

export const CascadeMessage = ({ cascadeCountDown }: Props) => (
  <CascadeRow className="row">
    <section className="col-12">
      {cascadeCountDown >= 0 ? (
        <>
          <p>
            <strong>{cascadeMessage1}</strong>
            <br />
            {cascadeMessage2}
          </p>
          {cascadeCountDown === 0 ? (
            <LoadingText>Loading…</LoadingText>
          ) : (
            <LoadingCountdown>{cascadeCountDown}</LoadingCountdown>
          )}
        </>
      ) : null}
    </section>
  </CascadeRow>
);

const CascadeRow = styled.div`
  text-align: center;
  margin-top: 12px;
  padding: 20px;

  p {
    line-height: 1.6;
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

const LoadingText = styled.p`
  color: #777;
  line-height: 28px;
`;

const LoadingCountdown = styled.span`
  display: block;
  font-size: 28px;
  line-height: 28px;
  color: #777;
  font-weight: bold;
`;
