import React from 'react';
import { useInterval } from 'react-use';
import { AppData } from 'src/shared/types';
import { AppDataContext, ProvidedAppData } from './AppDataProvider';

export const WindowAppDataProvider: React.FC = (props) => {
  const [appData, setAppData] = React.useState<AppData | undefined>(window.appData);
  const [loading, setLoading] = React.useState(true);

  // check every 100ms for window.app data
  useInterval(
    () => {
      if (window.appData) {
        setAppData(window.appData);
        setLoading(false);
      }
    },
    loading ? 100 : null,
  );

  const siteSubdomain = process.env.REACT_APP_SITE_SUBDOMAIN || 'www';

  // coerced to AppData since it may be undefined, but we handle that later.
  const providedAppData: ProvidedAppData = {
    ...(appData as AppData),
    loading,
    siteSubdomain,
  };

  return <AppDataContext.Provider value={providedAppData}>{props.children}</AppDataContext.Provider>;
};
