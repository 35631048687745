import Redis from 'ioredis';

export enum CacheLevel {
  Site = 1,
  Network = 2,
  App = 3,
}

const getPrefixByCacheLevel = (level: CacheLevel, siteUUID: string, networkUUID: string) => {
  switch (level) {
    case CacheLevel.App:
      return '';
    case CacheLevel.Network:
      return `${networkUUID}:`;
    case CacheLevel.Site:
    default:
      return `${siteUUID}:`;
  }
};

const normalizeCacheKey = (key: string) => key.replace(/\s/gi, '-').toLowerCase();

export type CustomCacheLayer = {
  get: <ReturnType>(cacheLevel: CacheLevel, cacheKey: string, json?: boolean) => Promise<ReturnType | undefined>;
  set: (
    cacheLevel: CacheLevel,
    cacheKey: string,
    // @TODO
    // Find all the places that need this to use `any` and `undefined` and fix them.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Redis.ValueType | any | undefined,
    ttl: number,
    option?: 'EX',
    json?: boolean,
  ) => Promise<Redis.Ok | null>;
  del: (cacheLevel: CacheLevel, cacheKey: string) => Promise<number>;
  redisClient: Redis.Redis;
};

export interface RedisCacheLayer extends Omit<Redis.Redis, 'get' | 'set' | 'del'>, CustomCacheLayer {}

export const createCacheLayer = (redisClient: Redis.Redis, siteUUID: string, networkUUID: string) => {
  const get = async (cacheLevel: CacheLevel, cacheKey: string, json = true) => {
    const levelPrefix = getPrefixByCacheLevel(cacheLevel, siteUUID, networkUUID);
    try {
      const cached = await redisClient.get(`${levelPrefix}${normalizeCacheKey(cacheKey)}`);
      if (!cached) {
        return undefined;
      }

      return json ? JSON.parse(cached) : cached;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`error getting redis data, cache key ${normalizeCacheKey(cacheKey)}`, error);
      throw error;
    }
  };

  const set = async (
    cacheLevel: CacheLevel,
    cacheKey: string,
    data: Redis.ValueType,
    ttl = 36000,
    option = 'EX',
    json = true,
  ) => {
    const levelPrefix = getPrefixByCacheLevel(cacheLevel, siteUUID, networkUUID);
    return redisClient.set(
      `${levelPrefix}${normalizeCacheKey(cacheKey)}`,
      json ? JSON.stringify(data) : data,
      option,
      ttl,
    );
  };

  const del = async (cacheLevel: CacheLevel, cacheKey: string) => {
    const levelPrefix = getPrefixByCacheLevel(cacheLevel, siteUUID, networkUUID);
    return redisClient.del(`${levelPrefix}${normalizeCacheKey(cacheKey)}`);
  };

  const customOverrides: CustomCacheLayer = {
    get,
    set,
    del,
    redisClient,
  };

  return Object.assign({}, redisClient, customOverrides);
};
