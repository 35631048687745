import React, { useCallback, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onEnterHandler } from 'src/client/helpers/onEnterHandler';
import { PlanSelector } from 'src/client/components/PlanSelector/PlanSelector';
import { TABLET_PORTRAIT, TABLET_LANDSCAPE } from 'src/client/helpers/mediaQueries';
import { UpgradeProduct } from 'src/shared/types';
import { SiteThumbnail } from './SiteThumbnail';
import { CartItem } from './useUpgradesCart';
import { ContinueToSiteWrapper } from './styles';

const HEADER_HEIGHT = 60;
const MARGIN_HEIGHT = 10;
const ANIMATION_NAME = 'plan-selector';

type Props = {
  activeSite: UpgradeProduct | null;
  className?: string;
  children?: React.ReactNode;
  cartItems: CartItem[];
  selectPlan: (productUUID: string) => void;
  setActiveSite: React.Dispatch<React.SetStateAction<UpgradeProduct | null>>;
  thumbnailPrefix: string;
  upgrades: UpgradeProduct[];
};

export const ThumbnailsGrid = (props: Props) => {
  const { upgrades, activeSite, setActiveSite } = props;

  const activeSiteIndex = upgrades.findIndex((site) => site.abbreviation === activeSite?.abbreviation);
  const { continueRef, thumbnailsRef } = usePlanScroller({ activeSite, activeSiteIndex });
  const { planWrapperRef, activeHeight } = usePlanWrapperHeight(activeSite);

  // This is our janky way of closing the plan selector.  We set the UUID to an
  // empty string to close it. This triggers the animation to start closing the
  // plan selector with out removing any of the visible content.
  const startHideSiteAnimation = () => {
    setActiveSite((currentSite) => {
      if (!currentSite) return null;
      return { ...currentSite, UUID: '' };
    });
  };

  // The PlanRow is in the DOM after the thumbnails, so we set the order to the
  // index of the active site + 1.  That places it after the current row, but
  // before the next row in the grid.
  const subscriptionPlanRowOrder = getRowFromIndex(activeSiteIndex) + 1;

  // This is fragile because it is based on the price.  We need to find a better
  // way to identify the discounted plans.
  const thumbnailsWithDiscounts = upgrades
    .filter((site) => site.products.some((product) => product.price === '19.00'))
    .map((site) => site.UUID);

  // Even with a `key` the CSSTransition loses track of the `PlanRow` component
  // when the activeHeight changes if it is passed in as a prop.

  // If it's passed through the style prop, then it works fine. Pretty sure this
  // has to do some race condition with styled components changing the class name
  // and CSSTransition changing it.

  // Using a css variable, instead of the height style so it can only apply it to
  // specific transition segments.
  const subscriptionPlanRowStyle = {
    order: subscriptionPlanRowOrder,
    '--active-height': activeHeight,
  } as React.CSSProperties;

  const planFromThisSite = props.cartItems.find((item) => item.siteUUID === activeSite?.UUID);
  const activeProduct = planFromThisSite?.productUUID || '';

  // CSS Grid Items do not need to be in the same order as they are in the DOM.
  // But the order in the DOM effects the order they show up when you use the
  // `order` property.

  // All the thumbnails are first since there will never be an extra row
  // above the thumbnails, then the extra rows are set so they can easily
  // be positioned after any specific row of thumbnails.

  // Not using the uuid as the key because the placeholders will not have a uuid
  // to prevent their click, and they will have randomly generated names.
  return (
    <ThumbnailsSection ref={thumbnailsRef} className={props.className}>
      {upgrades.map((upgrade, index) => (
        <SiteThumbnail
          activeSite={activeSite}
          index={index}
          key={upgrade.UUID}
          setActiveSite={setActiveSite}
          upgrade={upgrade}
          showDiscountRibbon={thumbnailsWithDiscounts.includes(upgrade.UUID)}
          thumbnailPrefix={props.thumbnailPrefix}
        />
      ))}
      <CSSTransition
        classNames={ANIMATION_NAME}
        in={!!activeSite?.UUID}
        mountOnEnter
        onEnter={onEnterHandler}
        onExited={() => setActiveSite(null)}
        timeout={250}
        unmountOnExit
      >
        <SubscriptionPlanRow style={subscriptionPlanRowStyle}>
          <PlanWrapper ref={planWrapperRef}>
            <StyledPlanSelector
              activeProduct={activeProduct}
              prices={activeSite?.products || []}
              selectPlan={props.selectPlan}
              site={activeSite as UpgradeProduct}
            />
          </PlanWrapper>
          <PlanCloseIcon onClick={startHideSiteAnimation}>
            <FontAwesomeIcon size="lg" icon={faTimes} fixedWidth />
          </PlanCloseIcon>
        </SubscriptionPlanRow>
      </CSSTransition>

      {props.children && <ContinueToSiteWrapper ref={continueRef}>{props.children}</ContinueToSiteWrapper>}
    </ThumbnailsSection>
  );
};

// To animate the plan selector, we need to know the height of the active plan
// So we render the plan selector behind the div it lives in, measure it's height
// and then set the height of the div to the height of the plan selector.
const usePlanWrapperHeight = (activeSite: UpgradeProduct | null) => {
  const planWrapperRef = React.useRef<HTMLDivElement>(null);

  const [activeHeight, setActiveHeight] = React.useState('0px');
  const updateHeight = () => {
    const newHeight = planWrapperRef.current?.offsetHeight || 0;
    setActiveHeight(newHeight + 'px');
  };

  // update the height when the active site changes
  useEffect(() => updateHeight(), [activeSite]);

  // add resize listener to keep the height updated on screen resize or device
  // orientation change
  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return { planWrapperRef, activeHeight };
};

type PlanScrollerProps = {
  activeSite: UpgradeProduct | null;
  activeSiteIndex: number;
};

const usePlanScroller = ({ activeSite, activeSiteIndex }: PlanScrollerProps) => {
  const thumbnailsRef = React.useRef<HTMLDivElement>(null);
  const continueRef = React.useRef<HTMLDivElement>(null);

  // The scroll action here scrolls to the page to the top of the thumbnails
  // for the active row. Instead of measuring where we are on the page because
  // the plan-row is shifting, we use the index and the height of the thumbnails
  // to calculate where to scroll to.
  const scrollToPlan = useCallback((index: number) => {
    const containerOffset = thumbnailsRef.current?.offsetTop || 0;
    const rowHeight = (thumbnailsRef.current?.getElementsByTagName('img')[0]?.offsetHeight || 0) + MARGIN_HEIGHT;
    const row = getRowFromIndex(index);
    const thumbnailOffset = rowHeight * row;
    const headerOffset = HEADER_HEIGHT + MARGIN_HEIGHT;

    // the CSS Grid rows are 0 indexed
    const needContinueRowHeightBoost = row > 1 && continueRef.current;
    const continueHeight = needContinueRowHeightBoost ? continueRef.current.offsetHeight + MARGIN_HEIGHT : 0;

    // The header is sticky, so we need to account for that height
    const scrollPosition = containerOffset + thumbnailOffset + continueHeight - headerOffset;

    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!activeSite) return;

    if (activeSite.UUID) {
      scrollToPlan(activeSiteIndex);
      return;
    }
  }, [activeSite, activeSiteIndex, scrollToPlan]);

  return { thumbnailsRef, continueRef, scrollToPlan };
};

export const getRowFromIndex = (index = 0) => {
  const columns = (() => {
    // From Largest to Smallest
    if (window.matchMedia(TABLET_LANDSCAPE).matches) return 4;
    if (window.matchMedia(TABLET_PORTRAIT).matches) return 2;
    return 1;
  })();

  return Math.floor(index / columns);
};

const ThumbnailsSection = styled.div`
  display: grid;
  grid-column-gap: ${MARGIN_HEIGHT}px;
  grid-row-gap: ${MARGIN_HEIGHT}px;
  grid-auto-flow: dense;
  grid-auto-rows: minmax(0, min-content);
  margin-top: 20px;
  margin-bottom: 20px;
  grid-template-columns: repeat(1, 1fr);

  @media ${TABLET_PORTRAIT} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${TABLET_LANDSCAPE} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const SubscriptionPlanRow = styled.div`
  color: #fff;
  transition-property: all;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;

  // @TODO Prices have a 12px top margin, remove that
  margin-bottom: 8px;

  grid-column: 1 / -1;
  // allows the slide down effect to work by clipping the content
  // overflow: hidden doesn't work because it alters the height of the children
  // and we measure them to run the transition
  overflow-y: clip;
  // allows the wrapper inside to extend outside the grid to the left and right
  overflow-x: visible;
  display: flex;
  justify-content: center;
  position: relative;
  height: 0;

  &.${ANIMATION_NAME}-enter-active {
    height: var(--active-height);
  }
  &.${ANIMATION_NAME}-enter-done {
    height: var(--active-height);
  }
`;

// This div is centered in the PlanRow, but forced to be the width of the page
const PlanWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

const StyledPlanSelector = styled(PlanSelector)`
  padding: 0;
  padding-bottom: 10px;

  // PlanSelector > Title
  & > h3 {
    margin-top: 20px;

    // @TODO Prices have a 12px top margin, remove that
    margin-bottom: 8px;
  }
`;

const PlanCloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  margin: calc((5px + 0.25em) * -1);
`;
