import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinAnimation = styled.svg`
  animation: ${spin} 0.5s linear infinite;
  height: 100%;

  path {
    fill: rgba(255, 255, 255, 0.2);
  }
`;

export const Spinner = () => {
  return (
    <SpinAnimation viewBox="0 0 20 20" className="spinner">
      <path d="M7.229 1.173a9.25 9.25 0 1 0 11.655 11.412 1.25 1.25 0 1 0-2.4-.698 6.75 6.75 0 1 1-8.506-8.329 1.25 1.25 0 1 0-.75-2.385z" />
    </SpinAnimation>
  );
};
