import React from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useAppData } from 'src/client/helpers/useAppData';
import { AltSubscriptionButton, SectionTitle } from './styles';

const Fieldset = styled.fieldset`
  margin: 0;
  border-radius: 4px;
  border: 1px solid gray;
  margin-bottom: 15px;
  color: #666;
`;

const Legend = styled.legend`
  text-align: center;
  color: gray;
`;

const ExampleTable = styled.table.attrs(() => ({ className: 'table ' }))`
  width: auto;
  margin: 0 auto;

  td {
    padding: 0 5px;
  }
`;

export const PaygardenPanel = () => {
  const paygardenExamples = useAppData().site?.paygardenExamples ?? [];

  return (
    <div className="price-groups subscription">
      <SectionTitle>3. How it works</SectionTitle>
      <p>
        You can now pay for your MetArt membership by trading a popular gift card. Instant access, 100% anonymous, no
        credit card required.
      </p>
      <Fieldset>
        <Legend>Examples:</Legend>
        <ExampleTable>
          <tbody>
            {paygardenExamples.map((example) => (
              <tr key={example.card}>
                <td>
                  ${example.price} {example.card}
                </td>
                <td>
                  <FontAwesomeIcon icon={faArrowRight} />
                </td>
                <td>{example.days} days of access</td>
              </tr>
            ))}
          </tbody>
        </ExampleTable>
      </Fieldset>
      <AltSubscriptionButton active={true}>Pay with Gift Card</AltSubscriptionButton>
    </div>
  );
};
