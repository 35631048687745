import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { MOBILE_LANDSCAPE } from 'src/client/helpers/mediaQueries';
import { useAppData } from '../../helpers/useAppData';

interface Props {
  className?: string;
  featuresHeader?: boolean;
}

export const FeaturesList = (props: Props) => {
  const { site, cdnUrl } = useAppData();

  return (
    <Wrapper className={props.className}>
      {props.featuresHeader && (
        <Header>
          <FormattedMessage id={`prejoin.featuresHeader.${site.abbreviation}`} />
        </Header>
      )}
      <List>
        <Feature>
          <Icon src={`${cdnUrl}/join/daily_updates.svg`} alt="" />
          <FormattedMessage id="prejoin.features.list.daily_updates" />
        </Feature>
        <Feature>
          <Icon src={`${cdnUrl}/join/4k.svg`} alt="4k" />
          <FormattedMessage id="prejoin.features.list.high_resolution" />
        </Feature>
        <Feature>
          <Icon src={`${cdnUrl}/join/compatible.svg`} alt="" />
          <FormattedMessage id="prejoin.features.list.compatibility" />
        </Feature>
        <Feature>
          <Icon src={`${cdnUrl}/join/photography.svg`} alt="" />
          <FormattedMessage id="prejoin.features.list.award_winning" />
        </Feature>
        <Feature>
          <Icon src={`${cdnUrl}/join/customer_support.svg`} alt="" />
          <FormattedMessage id="prejoin.features.list.customer_support" />
        </Feature>
        <Feature>
          <Icon src={`${cdnUrl}/join/discreet_billing.svg`} alt="" />
          <FormattedMessage id="prejoin.features.list.discreet_billing" />
        </Feature>
        <Feature>
          <Icon src={`${cdnUrl}/join/safe_transactions.svg`} alt="" />
          <FormattedMessage id="prejoin.features.list.secure_transactions" />
        </Feature>
        <Feature>
          <Icon src={`${cdnUrl}/join/fast_cdn.svg`} alt="" />
          <FormattedMessage id="prejoin.features.list.fast_cdn" />
        </Feature>
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const List = styled.ul`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-columns: 1fr;
  list-style-type: none;
  row-gap: 24px;
  column-gap: 15px;
  margin: 0;
  padding: 0;

  @media ${MOBILE_LANDSCAPE} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Feature = styled.li`
  width: 100%;
  align-items: center;
  display: flex;
  flex: 1 1 auto;
`;

const Icon = styled.img`
  display: inline-block;
  height: 22px;
  margin-right: 12px;
  width: 22px;
`;

const Header = styled.div`
  font-size: 18px;
  text-align: center;
  margin: 0 0 30px;
`;
