import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { CartItem } from 'src/client/pages/Upgrades/useUpgradesCart';
import { useURLSearchParams } from 'src/client/helpers';
import { Button } from '../Button/Button';
import { useAppData } from '../../helpers/useAppData';
import { CheckoutCart } from '.';

const CartBackground = styled.div`
  &.open {
    background-color: rgba(255, 255, 255, 0.4);
    height: 100%;
    left: 0;
    margin: 0 auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }
`;

const Drawer = styled.div`
  background-color: #000000;
  display: block;
  padding: 24px;
  max-height: 100vh;
  min-width: 300px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.3s ease transform;
  transform: translateX(100%);
  z-index: 5;

  @media (min-width: 1024px) {
    min-width: 420px;
  }

  &.open {
    transform: translateX(0%);
  }
`;

const ButtonWrapper = styled.div`
  margin: 48px 0;
`;

const Options = styled.div`
  display: inline-block;
`;

const ContinueShopping = styled.div`
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  display: flex;

  > img {
    margin-right: 12px;
    width: 12px;
  }

  > span {
    text-transform: uppercase;
  }
`;

const CloseButton = styled.img`
  cursor: pointer;
  display: block;
  height: 18px;
  margin: 0 0 8px auto;
`;

type Props = {
  cartItems: CartItem[];
  onClose: () => void;
  removePlan: (siteUUID: string) => void;
  show: boolean;
  total: number;
  checkoutUrl: string;
};

export const CartDrawer = (props: Props) => {
  const { cartItems, onClose, removePlan, show, total } = props;
  const searchParams = useURLSearchParams();
  const { cdnUrl } = useAppData();

  const checkoutUrl = props.checkoutUrl + (searchParams ? `?${searchParams.toString()}` : '');

  return (
    <CartBackground className={show ? 'open' : ''}>
      <Drawer className={show ? 'open' : ''} data-testid="cart-drawer">
        <CloseButton src={`${cdnUrl}/header/close.svg`} onClick={onClose} />
        <CheckoutCart items={cartItems} removePlan={removePlan} total={total} />
        <ButtonWrapper>
          <NavLink to={checkoutUrl}>
            <Button text="Checkout" />
          </NavLink>
        </ButtonWrapper>
        <Options>
          <ContinueShopping onClick={onClose} role="button">
            <img src={`${cdnUrl}/cart/arrow.svg`} alt="" />
            <span>Continue Shopping</span>
          </ContinueShopping>
        </Options>
      </Drawer>
    </CartBackground>
  );
};
