import React from 'react';
import styled from 'styled-components/macro';
import { TABLET_PORTRAIT } from 'src/client/helpers/mediaQueries';
import { JoinPageProduct } from 'src/shared/types';
import { UpgradeProduct } from 'src/shared/types/UpgradeProduct';
import { SiteInfo } from './SiteInfo';
import { PricePlan } from './PricePlan';

type Props = {
  activeProduct: string;
  className?: string;
  prices: JoinPageProduct[];
  selectPlan: (productUUID: string) => void;
  site: UpgradeProduct;
};

export const PlanSelector = (props: Props) => {
  const { activeProduct, className, prices, selectPlan, site } = props;
  const title = getTitle(site);

  const domain = (site.externalSite ? site.fullAffiliateLink : site.domain) as string;

  return (
    <Wrapper className={className}>
      <SiteInfo
        abbreviation={site.abbreviation}
        description={site.description}
        domain={domain}
        name={site.name}
        networkUUID={site.networkUUID}
        siteUUID={site.UUID}
        tags={site.tags}
      />
      <Title>{title}</Title>
      <Prices>
        {prices.map((price) => (
          <PricePlan
            abbreviation={site.abbreviation}
            active={price.productUUID === activeProduct}
            key={price.productUUID}
            product={price}
            selectPlan={selectPlan}
            site={site}
          />
        ))}
      </Prices>
    </Wrapper>
  );
};

const getTitle = (site: UpgradeProduct) => {
  if (site.externalSite) {
    return `Visit ${site.name}`;
  }

  return 'Choose Your Plan';
};

const Wrapper = styled.div`
  background-color: #1c1c1c;
  padding: 24px 10px;
`;

const Prices = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media ${TABLET_PORTRAIT} {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
  }
`;

const Title = styled.h3`
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  margin: 36px 0 18px;

  @media (min-width: 1024px) {
    margin: 48px 0 24px;
    font-size: 40px;
  }
`;
