import React from 'react';
import styled from 'styled-components/macro';
import { noop } from 'src/client/helpers/noop';
import { Spinner } from '../Spinner/Spinner';

type Props = {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
  onClick?: () => void;
};

export const Button = (props: Props) => {
  const { active, children, className, loading, onClick = noop } = props;
  const showSpinner = active && loading;

  return (
    <StyledButton active={active} onClick={onClick} className={className} disabled={loading} type="button">
      {showSpinner ? <Spinner /> : children}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ active?: boolean }>`
  background: ${({ active }) => (active ? '#2EBD59' : 'linear-gradient(180deg, #f3f3f3 0%, #e6e6e6 96.13%)')};
  border: 0;
  color: ${({ active }) => (active ? '#FFF' : '#000')};
  cursor: pointer;
  display: block;
  font-size: 1.125rem;
  font-weight: 700;
  height: 48px;
  margin: 0 auto;
  position: relative;

  :focus {
    outline: none;
  }

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    path {
      fill: rgba(0, 0, 0, 0.3);
    }
  }
`;
