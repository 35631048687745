import React from 'react';
import styled from 'styled-components/macro';
import { Placeholder } from 'src/client/components/Placeholder/Placeholder';
import { PlaceholderLine } from 'src/client/components/Placeholder/PlaceholderLine';
import { PlaceholderImage } from 'src/client/components/Placeholder/PlaceholderImage';
import { SubscriptionCardWrapper } from '../SubscriptionPlanSection/SubscriptionPlanCard';

export const SubscriptionPlanCardPlaceholder = () => (
  <PlaceholderWrapper>
    <Placeholder>
      <PlaceholderLine className="period" medium />
      <PlaceholderImage className="price" />
      <PlaceholderImage className="button" />
      <PlaceholderLine className="text" medium />
    </Placeholder>
  </PlaceholderWrapper>
);

const PlaceholderWrapper = styled((props) => <SubscriptionCardWrapper isPlaceHolder {...props} />)`
  height: 304px;

  ${Placeholder} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  & .line.period {
    padding-top: 25px;
  }

  & .image.price {
    width: 95px;
    padding-top: 70px;
  }

  & .image.button {
    width: 236px;
    padding-top: 60px;
    margin-top: 5px;
  }

  & .line.text {
  }
`;
