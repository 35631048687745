import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiClient } from '../../helpers/ApiClient';
import { apiUrls } from '../../helpers/constants';

export const AgeVerificationCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const verify = async (): Promise<boolean> => {
      const api = new ApiClient();
      const {
        body: { ageVerified },
      } = await api.get(apiUrls.get.ageVerificationCallback, {
        params: {
          code: query.get('code'),
        },
      });

      return ageVerified;
    };

    verify().finally(() => {
      // cookie is set by backend, there is nothing to do here
      window.location.assign('/');
    });
  }, [location.search]);

  return <></>;
};
