import React from 'react';
import styled from 'styled-components';

type Props = {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const SelectableComponent = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { active, children, className = '', onClick = () => null } = props;

  const activeClass = active ? 'active' : '';

  return (
    <div onClick={onClick} className={`${className} ${activeClass}`} ref={ref}>
      {children}
    </div>
  );
});

export const Selectable = styled(SelectableComponent)`
  border: 1px solid lightgray;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 8px;
  padding: 0 8px;
  text-align: center;
  user-select: none;

  &.active {
    border-color: dodgerblue;
  }
`;
