export type MarketingDataSite = {
  abbreviation: string;
  available?: boolean;
  description: string;
  domain: string;
  name: string;
  networkUUID: string;
  skipUpsales?: boolean;
  tags: string[];
  UUID: string;
};

type MarketingData = {
  sites: MarketingDataSite[];
};

export const networkFallbackSite: Record<string, string> = {
  'a4c247f3-ed92-4a70-846d-2722fd8b50f3': 'e6b59510-4e34-11df-9879-0800200c9a66',
};

export const vixen = {
  UUID: 'b2f8f2aa-7dc6-4057-8ea6-f8a8f38ca1fc',
  networkUUID: '00000000-0000-0000-0000-000000000000',
  abbreviation: 'vixen',
  domain: 'vixen.com',
  name: 'Vixen',
  description:
    'Why not have it all? Explicit hardcore sex films with top level production values, featuring beautiful models who push the boundaries of erotica.',
  tags: ['Hardcore sex', 'Explicit', 'Erotica', 'Oral', 'Orgasm', 'Cum'],
};

export const playboyplus = {
  UUID: '9cb683cd-ce85-42d9-9b60-57731ec72b64',
  networkUUID: '00000000-0000-0000-0000-000000000000',
  abbreviation: 'playboyplus',
  domain: 'playboyplus.com',
  name: 'Playboy +',
  description:
    'The name speaks for itself. Playmates, celebrities, adult stars and so much more… The iconic brand we all know and love. Glamour erotica at an unbeatable value.',
  tags: ['Glamour', 'Beauty', 'Celebrity', 'Erotica', 'Softcore'],
};

export const mrskin = {
  UUID: '17e42cf5-ea17-492f-a6e6-db9c52360775',
  networkUUID: '00000000-0000-0000-0000-000000000000',
  abbreviation: 'mrskin',
  domain: 'mrskin.com',
  name: 'MrSkin.com',
  description: 'The Ultimate Celebrity Nudity Website, Covering Decades of Film and TV.',
  tags: ['Celebrity', 'Erotica', 'Nudity', 'Famous', 'Softcore'],
};

export const xConfessions = {
  UUID: '1cd31e60-e366-4b32-8ea2-cf411b17b885',
  networkUUID: '00000000-0000-0000-0000-000000000000',
  abbreviation: 'xconfessions',
  domain: 'xconfessions.com',
  name: 'XConfessions',
  description:
    'Experience a variety of adult fantasies in XConfessions, where films are inspired by anonymouse confessions, offering a distinct cinematic alternative to male-centric pornography. Get ready to be aroused!',
  tags: ['Reality', 'Indie', 'Sex', 'Erotica', 'Natural'],
};

export const marketingData: MarketingData = {
  sites: [
    {
      UUID: 'e6b59510-4e34-11df-9879-0800200c9a66',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'ma',
      domain: 'metart.com',
      name: 'MetArt',
      description:
        'MetArt is the undisputed leader in premium quality nude art photography. With thousands of stunning models, and nearly 2.4 million stunning images, MetArt is unequalled. Experience the ultimate.',
      tags: ['Erotic Art', 'Erotica', 'Euro Babes', 'Lingerie', 'Natural', 'Nude Art', 'Softcore'],
    },
    {
      UUID: '94db3d00-36fc-11e1-b86c-0800200c9a66',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'sa',
      domain: 'sexart.com',
      name: 'SexArt',
      description:
        "The future of erotic entertainment starts here. SexArt combines explicit sex with exquisite art to create erotica of unrivalled quality -- and it's not available anywhere else. Gorgeous performers and lavish productions.",
      tags: ['Erotic Art', 'Euro Babes', 'Explicit', 'Feature Films', 'For Women', 'Glamcore'],
    },
    {
      UUID: 'e7dfb70d-f31c-45b3-b5e0-bf10d733d349',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'mx',
      domain: 'metartx.com',
      name: 'MetArt X',
      description:
        "MetArt, the leader in natural nudes, brings you a new sexual experience called MetArt X. As a fan of MetArt, you've waited so long, and finally, the most gorgeous MetArt models are making themselves cum on camera!",
      tags: ['Erotic Art'],
    },
    {
      UUID: 'fdafdf20-9dc3-11e0-aa82-0800200c9a66',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'tle',
      domain: 'thelifeerotic.com',
      name: 'The Life Erotic',
      description:
        'What is The Life Erotic? Beautiful women. Pushing the boundaries. Pushing themselves. Exposed, unashamed ecstasy. The passion and pleasure of masturbation. The art of the orgasm... The Life Erotic',
      tags: ['Erotic Art', 'Euro Babes', 'Fetish', 'Hairy', 'Lesbian', 'Masturbation', 'Natural'],
    },
    {
      UUID: '4f230289-82b5-42fa-9c6d-aaa747e9b5b3',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'vt',
      domain: 'vivthomas.com',
      name: 'Viv Thomas',
      description: "Viv Thomas has been producing some of the world's finest adult movies for over a decade.",
      tags: ['Anal', 'Euro Babes', 'Explicit', 'Feature Films', 'Hardcore', 'Lesbian'],
    },
    {
      UUID: '3d345d1e-1569-10b4-4db5-a80cdd746318',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'sl',
      domain: 'straplez.com',
      name: 'Straplez',
      description: 'Breathtaking lesbians with sizzling strap-on sex!',
      tags: ['Lesbian', 'Strap-on', 'Nylon', 'High Heels', 'Glamour'],
    },
    {
      UUID: '605315cf-a263-6914-2d71-5ee4e9ed534e',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'lh',
      domain: 'lovehairy.com',
      name: 'Love Hairy',
      description:
        'Love Hairy features the most gorgeous women exposing their natural beauty. Alluringly erotic, unshaved hairy girls curated from the MetArt Network. Experience the essence of female beauty in all of its natural glory.',
      tags: ['Collection', 'Erotic Art', 'Erotica', 'Hairy', 'Lesbian', 'Natural'],
    },
    {
      UUID: '15a9ffa0-4e35-11df-9879-0800200c9a66',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'ea',
      domain: 'errotica-archives.com',
      name: 'Errotica Archives',
      description:
        'A photographer takes a picture. An artist captures the essence of the model. The difference? One is a snapshop, the other is erotic art. Discover a world of extraordinary nude art in the Errotica Archives.',
      tags: ['Erotic Art', 'Euro Babes', 'Lingerie', 'Natural', 'Nude Art', 'Softcore'],
    },
    {
      UUID: 'd0e7e333-2931-1e3b-b6e0-800200c93255',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'dm',
      domain: 'domai.com',
      name: 'Domai',
      description:
        'DOMAI is the original "simple nudes" website, online since 1997. You will not find videos, or sophisticated navigation. What you will find is happy natural nudes at a very affordable price.',
      tags: ['Erotica', 'Lingerie', 'Natural', 'Nude Art', 'Softcore', 'Solo', 'Teens'],
    },
    {
      UUID: '5592e333-2421-1e3f-f640-800200c93111',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'gn',
      domain: 'goddessnudes.com',
      name: 'Goddess Nudes',
      description:
        'Goddess Nudes shares the fun and beauty that Domai has become famous for but takes things a little further. The girls show a bit more. As with Domai, there are no videos, and only basic navigation. But the value simply cannot be beat.',
      tags: ['Erotica', 'Lingerie', 'Natural', 'Nude Art', 'Softcore', 'Solo', 'Teens'],
    },
    {
      UUID: 'fda02100-4e34-11df-9879-0800200c9a66',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'eb',
      domain: 'eroticbeauty.com',
      name: 'Erotic Beauty',
      description:
        "Discover 10,000 fresh, natual, amateur models at Erotic Beauty. Whether you're looking for the girl next door of the girl of your wildest dreams, you search for female perfection begins at Erotic Beauty.",
      tags: ['Erotic Art', 'Euro Babes', 'Lingerie', 'Natural', 'Nude Art', 'Softcore'],
    },
    {
      UUID: '2163551d-11d0-4396-86ad-9d291c8dfd71',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'als',
      domain: 'alsscan.com',
      name: 'ALS Scan',
      description:
        'ALS Scan delivers the hottest, wildest girls in the world. Rule #1: All ladies are shaved. Rule #2: There are no rules! Fisting? Peeing? Public Sex? Lesbian? Crazy insertions? See it all, and more, at ALS Scan!',
      tags: ['Anal', 'Explicit', 'Fetish', 'Fisting', 'Hardcore', 'Lesbian', 'Porn Stars'],
    },
    {
      UUID: 'd99236c0-4dd0-11e1-b86c-0800200c9a66',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'ra',
      domain: 'rylskyart.com',
      name: 'Rylsky Art',
      description:
        'Uncover the beauty of artistic nudes. The most beautiful nude amateur softcore models in the world: at Rylsky Art they are our inspiration, our obsession, our reason for being.',
      tags: ['Erotic Art', 'Euro Babes', 'Lingerie', 'Natural', 'Nude Art', 'Softcore'],
    },
    {
      UUID: '706df46b-8888-4f7b-b226-097952427754',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 'ed',
      domain: 'eternaldesire.com',
      name: 'Eternal Desire',
      description:
        'The artist known simply as Arkisi possesses a keen eye for the feminine form, unmatched technical skills, and the ability to capture the beauty of female sexuality in dazzling digital images.',
      tags: ['Erotic Art', 'Euro Babes', 'Lingerie', 'Natural', 'Nude Art', 'Softcore'],
    },
    {
      UUID: '18a2e47e-aefd-45f2-9033-a5fcaf1f5b91',
      networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
      abbreviation: 's18',
      domain: 'stunning18.com',
      name: 'Stunning 18',
      description:
        'The artist known simply as Murrell possesses a keen eye for the feminine form, unmatched technical skills, and the ability to capture the beauty of female sexuality in dazzling digital images.',
      tags: ['Erotic Art', 'Euro Babes', 'Lingerie', 'Natural', 'Nude Art', 'Softcore'],
    },
    playboyplus,
    vixen,
    mrskin,
    xConfessions,
  ],
};
