import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { AltPayment } from '@tovia/man-protos/dist/types/billing.types';
import { FormattedMessage } from 'react-intl';
import { JoinPageProduct } from 'src/shared/types';
import { TABLET_LANDSCAPE } from 'src/client/helpers/mediaQueries';
import { SectionTitle } from './styles';
import { PaygardenPanel } from './PaygardenPanel';
import { PayByCheckPanel } from './PayByCheckPanel';
import { SubscriptionOption } from './SubscriptionOption';
import { SubscriptionPlaceholder } from './SubscriptionPlaceholder';

type Props = {
  subOption?: number;
  loading?: boolean;
  paymentOption?: AltPayment;
  onClick: (pgpid: number) => void;
  subscriptions?: JoinPageProduct[];
  signalPayByCheck: () => void;
};

export const SubscriptionChoice = (props: Props) => {
  const { subOption, loading, onClick, subscriptions = [], paymentOption, signalPayByCheck } = props;
  const showPaygardenPanel = paymentOption?.billerID === 9;
  const showPayByCheckPanel = paymentOption?.billerID === 12;

  const groupedSubscriptions = useMemo(() => {
    if (!subscriptions) {
      return null;
    }

    return subscriptions.reduce((acc, value) => {
      const priceGroupName = value.priceGroupName?.replace(/\s/gi, '-').toLowerCase();

      if (!priceGroupName) return acc;

      if (!acc[priceGroupName]) {
        acc[priceGroupName] = [];
      }

      acc[priceGroupName].push(value);

      return acc;
    }, {} as Record<string, JoinPageProduct[]>);
  }, [subscriptions]);

  if (showPaygardenPanel) return <PaygardenPanel />;

  if (showPayByCheckPanel) return <PayByCheckPanel onClick={signalPayByCheck} />;

  return (
    <PriceGroups className="price-groups subscription">
      <SectionTitle>
        <span>3.</span>
        <FormattedMessage id="subscription.choice.title" />
      </SectionTitle>
      <div className="placeholders">
        {!subscriptions.length && (
          <SelectablePricesWrapper className="price-group-placeholder">
            {['sp1', 'sp2', 'sp3'].map((index) => (
              <SubscriptionPlaceholder key={index} />
            ))}
          </SelectablePricesWrapper>
        )}
      </div>
      <div className="prices">
        {groupedSubscriptions &&
          Object.keys(groupedSubscriptions).map((key, index) => {
            const group = groupedSubscriptions[key];
            return (
              <SelectablePricesWrapper
                className={`group biller-${group[0] ? group[0].billerID : 'none'} price-group-${index + 1}`}
                key={key}
              >
                {group.map((item, index) => (
                  <SubscriptionOption
                    itemIndex={index}
                    loading={loading}
                    key={item.priceGroupProductID}
                    item={item}
                    onClick={onClick}
                    subOption={subOption}
                  />
                ))}
              </SelectablePricesWrapper>
            );
          })}
      </div>
    </PriceGroups>
  );
};

const SelectablePricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &.group:not(.price-group-1) {
    display: none;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const PriceGroups = styled.div`
  margin-top: 24px;

  @media ${TABLET_LANDSCAPE} {
    margin-top: 0;
  }
`;
