import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ToastProvider } from 'react-toast-notifications';
import { CustomToast } from './client/components/CustomToast/CustomToast';
import './client/index.css';
import { App } from './client/App';
import * as serviceWorker from './client/serviceWorker';
import { WindowAppDataProvider, LanguageProvider } from './client/components/Providers';

ReactDOM.render(
  <BrowserRouter>
    <CookiesProvider>
      <ToastProvider components={{ Toast: CustomToast }}>
        <WindowAppDataProvider>
          <LanguageProvider>
            <App />
          </LanguageProvider>
        </WindowAppDataProvider>
      </ToastProvider>
    </CookiesProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
