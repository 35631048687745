import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useURLSearchParams } from 'src/client/helpers';
import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';
import { routerPaths } from '../../helpers/constants';
import { useAppData } from '../../helpers/useAppData';

interface Props {
  addFreeSiteToCart: (freeSiteUUID: string) => void;
  cart: {
    siteUUID: string;
  }[];
  close: () => void;
  products: {
    abbreviation: string;
    freeSiteEligible?: boolean;
    name: string;
    networkUUID: string;
    UUID: string;
  }[];
  show: boolean;
  type?: 'discountOffer';
}

export const FreeSiteModal = (props: Props) => {
  const { addFreeSiteToCart, cart, close, products, show, type } = props;

  const [activeSite, setActiveSite] = useState('');
  const [isMissingFreeSite, setIsMissingFreeSite] = useState(false);
  const [closeAttemptCount, setCloseAttemptCount] = useState(0);
  const [wrapperElement, setWrapperElement] = useState<Element | null>(null);
  const [heightElement, setHeightElement] = useState<Element | null>(null);
  const [animateAlert, setAnimateAlert] = useState(false);

  const history = useHistory();
  const searchParams = useURLSearchParams();

  const missingFreeSiteAlert = useRef<HTMLDivElement | null>(null);

  const { titleText, subtitleText, finePrintText } = useTextHeadings(type);

  const removeNavigation = type === 'discountOffer';

  const { cdnUrl } = useAppData();

  useEffect(() => {
    if (!show) {
      setActiveSite('');
    }

    if (!wrapperElement) {
      setWrapperElement(document.querySelector('.modal-wrapper'));
    }

    if (!heightElement) {
      setHeightElement(document.querySelector('.modal-content'));
    }
  }, [heightElement, show, wrapperElement]);

  const scrollHandler = useCallback(() => {
    if (!missingFreeSiteAlert.current || !wrapperElement) {
      return;
    }

    const rect = missingFreeSiteAlert.current.getBoundingClientRect();
    const style = window.getComputedStyle(missingFreeSiteAlert.current);

    const isVisible =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= wrapperElement.clientHeight &&
      rect.right <= wrapperElement.clientWidth;

    if (isVisible && style.visibility === 'visible') {
      setAnimateAlert(true);
    }
  }, [wrapperElement]);

  useEffect(() => {
    if (wrapperElement) {
      wrapperElement.addEventListener('scroll', scrollHandler, false);
    }

    return () => {
      if (wrapperElement) {
        wrapperElement.removeEventListener('scroll', scrollHandler, false);
      }
    };
  }, [scrollHandler, wrapperElement]);

  const showMissingFreeSiteAlert = useCallback(() => {
    if (!activeSite && closeAttemptCount < 1) {
      setIsMissingFreeSite(true);
      setCloseAttemptCount((prevState) => prevState + 1);
      wrapperElement?.scrollTo({
        top: heightElement?.getBoundingClientRect().height,
        behavior: 'smooth',
      });
    }
  }, [activeSite, closeAttemptCount, heightElement, wrapperElement]);

  const onClose = useCallback(() => {
    if (activeSite || closeAttemptCount > 0) {
      setIsMissingFreeSite(false);
      setCloseAttemptCount(0);
      setAnimateAlert(false);
      setWrapperElement(null);
      setHeightElement(null);
      addFreeSiteToCart(activeSite);
      close();
    }

    showMissingFreeSiteAlert();
  }, [addFreeSiteToCart, activeSite, close, closeAttemptCount, showMissingFreeSiteAlert]);

  const proceedToCheckout = useCallback(() => {
    if (activeSite || closeAttemptCount > 0) {
      onClose();
      history.push(
        `${routerPaths.upgrades.checkout}${searchParams.values.length ? `?${searchParams.toString()}` : ''}`,
      );
    }

    showMissingFreeSiteAlert();
  }, [activeSite, closeAttemptCount, history, onClose, searchParams, showMissingFreeSiteAlert]);

  return (
    <UpgradesModal onHide={onClose} show={show}>
      <Body data-testid="free-site-modal">
        <Container>
          <TitleWrapper>
            <Title>{titleText}</Title>
            <Subtitle>{subtitleText}</Subtitle>
            <FinePrint>{finePrintText}</FinePrint>
          </TitleWrapper>
          <FreeSiteOffers>
            {products.map((upgrade) => {
              const active = activeSite === upgrade.UUID;
              const inCart = !!cart.find((item) => item.siteUUID === upgrade.UUID);
              const unavailable = !upgrade.freeSiteEligible;

              let text = '+ Add Free Site';

              if (unavailable || inCart) {
                text = '';
              }

              const onSelect = (e: React.MouseEvent<HTMLElement>) => {
                if (!active || !inCart || !unavailable) {
                  setIsMissingFreeSite(false);
                  setActiveSite((e.target as HTMLElement).dataset.id as string);
                }
              };

              return (
                <FreeSite
                  key={upgrade.UUID}
                  unavailable={active || inCart || unavailable}
                  data-testid={'free-site-' + upgrade.abbreviation}
                >
                  {(active || inCart || unavailable) && (
                    <div className={`unavailable ${active ? 'active' : ''}`}>
                      {active && <img src={`${cdnUrl}/metcart/check-circle.svg`} alt="Site Selected" />}
                      {inCart && !active && (
                        <UnavailableButtonWrapper>
                          <RoundButton>In Shopping Cart</RoundButton>
                        </UnavailableButtonWrapper>
                      )}
                      {unavailable && !inCart && (
                        <UnavailableButtonWrapper>
                          <RoundButton>You Are A Member</RoundButton>
                        </UnavailableButtonWrapper>
                      )}
                    </div>
                  )}
                  <img
                    src={`${cdnUrl}/upgrades/${upgrade.networkUUID}/${upgrade.abbreviation}_t.png`}
                    alt={upgrade.name}
                  />
                  <p>{upgrade.name}</p>
                  <div className="add-site">
                    <RoundButton
                      className={unavailable || inCart || active ? 'hidden' : ''}
                      data-id={upgrade.UUID}
                      onClick={onSelect}
                    >
                      {text}
                    </RoundButton>
                  </div>
                </FreeSite>
              );
            })}
          </FreeSiteOffers>
          <MissingFreeSite animate={animateAlert} ref={missingFreeSiteAlert} show={isMissingFreeSite}>
            <span>Please select a FREE SITE or click below to continue.</span>
          </MissingFreeSite>
          <ButtonWrapper>
            {!removeNavigation && (
              <FreeSitesButton>
                <Button bgColor="white" onClick={onClose} text="Continue Shopping" />
              </FreeSitesButton>
            )}
            <FreeSitesButton>
              <Button onClick={proceedToCheckout} text="Proceed to Checkout" />
            </FreeSitesButton>
          </ButtonWrapper>
        </Container>
      </Body>
    </UpgradesModal>
  );
};

const useTextHeadings = (type: string | undefined) => {
  switch (type) {
    case 'discountOffer':
      return {
        titleText: '25% Discount activated',
        subtitleText: 'Now choose your FREE site below',
        finePrintText: '30 days free, then renews monthly @ $19.99',
      };
    default:
      return {
        titleText: 'Thank you for choosing a Lifetime Membership!',
        subtitleText: 'Select a second site below and enjoy an entire YEAR FOR FREE as our special gift',
      };
  }
};

const UpgradesModal = styled(Modal)`
  background-color: #292929;
  font-family: 'Roboto', sans-serif;

  @media (min-width: 1024px) {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-header {
    background-color: #292929;
    height: 36px;
    position: sticky;
    top: 0;
    z-index: 2;

    @media (min-width: 1024px) {
      height: 64px;
      background-color: transparent;
    }

    .close {
      filter: unset;
      right: 12px;
      top: 8px;

      @media (min-width: 1024px) {
        right: 24px;
        top: 24px;
      }
    }
  }

  .modal-wrapper {
    background-color: transparent;
    overflow-x: hidden;

    @media (min-width: 992px) {
      height: 100%;
      max-width: 100%;
    }
  }
`;

const FreeSiteOffers = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
`;

const FreeSite = styled.div<{ unavailable: boolean }>`
  background-color: #1c1c1c;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #ffffff;
  margin: 12px 10px;
  padding: 10px 10px 12px;
  position: relative;
  width: 100%;

  @media (min-width: 640px) {
    width: 364px;
  }

  @media (min-width: 1024px) {
    width: 328px;
  }

  ${(props) =>
    !props.unavailable
      ? css`
          :hover {
            border: 1px solid #0000ff;
          }
        `
      : ''}

  > img {
    border-radius: 4px;
    width: 100%;
  }

  > p {
    font-size: 16px;
    font-weight: 500;
    margin: 8px 0;
  }

  > .add-site {
    display: block;
    text-align: right;
    text-transform: uppercase;
  }

  .unavailable {
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #444444;
    border-radius: 4px;
    display: block;
    height: 100%;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    > img {
      display: block;
      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 60px;
    }
  }

  .active {
    border: 1px solid #0000ff;
  }
`;

const RoundButton = styled.span`
  background-color: #3c4142;
  border-radius: 24px;
  cursor: pointer;
  display: inline-block;
  padding: 12px;

  &:hover {
    background-color: #88888f;
  }

  &.hidden {
    visibility: hidden;
  }
`;

const UnavailableButtonWrapper = styled.div`
  color: #000000;
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);

  & ${RoundButton} {
    background-color: #ffffff;
    cursor: default;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 12px 16px;
    margin: 0 auto;
    text-transform: uppercase;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 12px 0;
`;

const FreeSitesButton = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 300px;
  margin: 12px;

  @media (min-width: 1024px) {
    max-width: 300px;
  }
`;

const Body = styled.div`
  min-height: 100vh;
  padding: 8px 0 64px;

  @media (min-width: 1024px) {
    padding: 8px 0;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1920px;
`;

const TitleWrapper = styled.div`
  color: #ffffff;
  padding-bottom: 24px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  margin: 12px 0 0;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;

const FinePrint = styled.p`
  font-size: 10px;
`;

const animation = keyframes`
  0% {
    transform: scale(1);
  }
  
  50% {
    transform: scale(1.1);
  }
  
  100% {
    transform: scale(1);
  }
`;

const MissingFreeSite = styled.div<{ animate: boolean; show: boolean }>`
  background-color: #ffd6d9;
  border: 1px solid #f3cad2;
  border-radius: 4px;
  color: #741423;
  font-size: 16px;
  margin: 0 12px;
  padding: 6px 0;
  text-align: center;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};

  ${(props) =>
    props.animate
      ? css`
          animation: ${animation} 1.25s forwards;
          animation-delay: 0.3s;
        `
      : ''}
`;
