import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { PlaceholderLine } from './PlaceholderLine';
import { PlaceholderImage } from './PlaceholderImage';

const linear = keyframes`
  from {
    background-position: -468px 0;
  }
  to {
    background-position: 600px 0;
  }
`;

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const Placeholder = styled.div<Props>`
  display: block;
  width: 100%;

  & ${PlaceholderLine}, ${PlaceholderImage} {
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-size: 1200px 100%;
    animation: ${linear} 1s linear forwards infinite;
  }

  & ${PlaceholderLine} {
    margin: 8px 0;

    :first-child {
      margin: 0 0 8px 0;
    }

    :last-child {
      margin: 8px 0 0 0;
    }
  }
`;
