import { v4 as UUID } from 'uuid';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import { ANALYTICS_COOKIE_NAME } from '../../../shared/constants/cookies';

// Run this hook before others to set the unique UUID for analytics.
export const useAnalyticsUniqueUUID = () => {
  const [cookies, setCookie] = useCookies([ANALYTICS_COOKIE_NAME]);
  const analyticsUUID: string = cookies[ANALYTICS_COOKIE_NAME];

  useEffect(() => {
    if (analyticsUUID) {
      return;
    }

    setCookie(ANALYTICS_COOKIE_NAME, UUID(), { path: '/' });
  }, [analyticsUUID, setCookie]);
};
