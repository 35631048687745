import React, { useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useToasts } from 'react-toast-notifications';
import { useAppData } from 'src/client/helpers/useAppData';
import { useFeatureFlags } from 'src/client/helpers/useFeatureFlags';
import { useSelectedLanguage } from 'src/client/helpers/useSelectedLanguage';
import { SupportedLanguage } from 'src/shared/constants/languages';
import en from 'src/shared/translations/en.json';

export const languagesCache = new Map<SupportedLanguage, Record<string, string>>([['en', en]]);

interface Context {
  language: SupportedLanguage;
  changeLanguage: (language: SupportedLanguage) => Promise<void>;
}

export const LanguageContext = React.createContext<Context>({
  language: 'en',
  changeLanguage: () => Promise.resolve(),
});

export const LanguageProvider: React.FC = (props) => {
  const appData = useAppData();
  const { enableInternationalization } = useFeatureFlags();

  const [language, setLanguage] = useSelectedLanguage();
  const [messages, setMessages] = useState<Record<string, string>>(en);
  const { addToast } = useToasts();

  const changeLanguage = useCallback(
    async (newLanguage: SupportedLanguage | undefined) => {
      if (!newLanguage) return;

      setLanguage(newLanguage);

      if (languagesCache.has(newLanguage)) {
        // Even though typescript knows that the language is in the map,
        // it still thinks .get() could return undefined.
        setMessages(languagesCache.get(newLanguage) as Record<string, string>);
        return;
      }

      try {
        const newMessages = await import(`src/shared/translations/${newLanguage}.json`).then((m) => m.default);

        languagesCache.set(newLanguage, newMessages);

        setMessages(newMessages);
      } catch (error: unknown) {
        addToast(`Failed to load language: ${newLanguage}`, { appearance: 'error' });
        setLanguage(language);
      }
    },
    [addToast, language, setLanguage],
  );

  const hasRunInitialLanguageChange = React.useRef(false);
  useEffect(() => {
    if (!enableInternationalization) return;

    if (!hasRunInitialLanguageChange.current && !appData.loading) {
      changeLanguage(language);
      hasRunInitialLanguageChange.current = true;
    }
  }, [appData.loading, changeLanguage, language, enableInternationalization]);

  // Wait for the initial language to load to return anymore of the app
  if (Object.keys(messages).length === 0) return null;

  if (!enableInternationalization) {
    return (
      <IntlProvider locale={'en'} messages={en}>
        {props.children}
      </IntlProvider>
    );
  }

  return (
    <LanguageContext.Provider value={{ changeLanguage, language }}>
      <IntlProvider locale={language} messages={messages} defaultLocale="en">
        {props.children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
