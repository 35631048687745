import { useMemo } from 'react';
import { useAffiliateData } from 'src/client/helpers/useAffiliateData';
import { Biller } from 'src/shared/constants/billers';
import { PaymentPrice } from 'src/shared/types';

export const usePrimaryPayments = (paymentPrices?: PaymentPrice[], previousBillerID?: number) => {
  const { isMAMCookie, isPACookie } = useAffiliateData();

  return useMemo(
    () =>
      (paymentPrices ?? []).reduce((payments, payment) => {
        switch (payment.paymentType) {
          // Priority for CC Processors
          // Rocketgate => Epoch => CCBill
          case 'Credit Card': {
            // If there's nothing set, return what we got.
            if (!payments[0]) {
              payments[0] = payment;
              return payments;
            }

            const isRocketGateUser = previousBillerID === Biller.RocketGate;
            const isCCBillUser = previousBillerID === Biller.CCBill;

            if (payment.billerID === Biller.RocketGate && (isRocketGateUser || isMAMCookie)) {
              payments[0] = payment;
              return payments;
            }

            if (payment.billerID === Biller.CCBill && (isCCBillUser || isPACookie)) {
              payments[0] = payment;
              return payments;
            }

            return payments;
          }

          // Priority for PayPal
          // Notes for the future because I'm talking about it with Shaun now, and no
          // one will remember except this code
          // (if we add SegPay, its probably first) => Epoch
          case 'PayPal':
            payments[1] = payment;
            return payments;

          default:
            return payments;
        }
      }, [] as PaymentPrice[]),

    // () => result.filter((payment) => ['Credit Card', 'PayPal'].includes(payment.paymentType)),
    [isMAMCookie, isPACookie, paymentPrices, previousBillerID],
  );
};
