import React from 'react';
import styled from 'styled-components/macro';
import { CartItem } from 'src/client/pages/Upgrades/useUpgradesCart';

export const SummaryWrapper = styled.div`
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 500;
  margin: 0;
`;

const Bar = styled.hr`
  border: 1px rgba(255, 255, 255, 0.2) solid;
  margin: 18px 0;
  width: 100%;
`;

const Item = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;

  > span {
    flex-grow: 1;
  }
`;

const Site = styled.span`
  font-weight: 500;
  max-width: 170px;
  width: 100%;
`;

const Access = styled.span``;

const Price = styled.span`
  text-align: right;
`;

const Total = styled.div`
  display: flex;
  font-size: 18px;

  > span {
    flex-grow: 1;
  }
`;

const TotalText = styled.span`
  flex-grow: 1;
  text-transform: uppercase;
`;

type Props = {
  items: CartItem[];
  total: number;
};

export const Summary = (props: Props) => {
  const { items, total } = props;

  return (
    <SummaryWrapper>
      <Title>Summary</Title>
      <Bar />
      <span>{`You have ${items.length} item${items.length > 1 || items.length === 0 ? 's' : ''} in your cart`}</span>
      <Bar />
      {items.map((item) => (
        <React.Fragment key={item.site}>
          <Item>
            <Site>{item.site}</Site>
            <Access>{`${item.access} Access`}</Access>
            <Price>
              &#36;
              {item.price}
            </Price>
          </Item>
          <Bar />
        </React.Fragment>
      ))}
      <Total>
        <TotalText>Total</TotalText>
        <Price>&#36;{total.toFixed(2)}</Price>
      </Total>
    </SummaryWrapper>
  );
};

