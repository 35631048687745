import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

export const CookieMonster = () => {
  const location = useLocation();

  return <Image src={`/cm?${location.search.substring(1)}`} alt="CM!" />;
};

const Image = styled.img`
  bottom: 0;
  display: none;
  position: fixed;
  right: 0;
`;
