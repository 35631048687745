import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { AltPayment } from '@tovia/man-protos/dist/types/billing.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { SectionTitle } from '../../pages/PreJoinPageRedesign/SectionTitle';
import { SectionContainer } from '../../pages/PreJoinPageRedesign/SectionContainer';
import { useAppData } from '../../helpers';
import { Biller } from '../../../shared/constants/billers';
import { GetPricesResponse } from '../../../shared/types';
import { PaymentCard } from './PaymentCard';
import { StateType } from '../../pages/PreJoinPageRedesign/PreJoinPageRedesign';
import { OtherPaymentsDropdown } from './OtherPaymentsDropdown';
import { PaymentCardPlaceholder } from '../Placeholder/PaymentCardPlaceholder';
import { mediaBreakpoints } from '../../helpers/mediaQueries';
import { usePrimaryPayments } from './usePrimaryPayments';

type Props = {
  activePayment: StateType<AltPayment | undefined>;
  getPricesResponse?: GetPricesResponse;
  onGiftCardSelect: () => void;
  purchasing: boolean;
};

export const PaymentMethods = (props: Props) => {
  const {
    activePayment: [, setActivePayment],
    getPricesResponse,
    onGiftCardSelect,
    purchasing,
  } = props;
  const { activeSub, altPayments, paymentPrices, previousBillerID } = getPricesResponse || {};
  const { site } = useAppData();
  const intl = useIntl();

  const [isOtherPaymentsDropdownOpen, setIsOtherPaymentsDropdownOpen] = useState(false);

  const [selectedPrimaryPayment, setSelectedPrimaryPayment] = useState<AltPayment | undefined>(undefined);
  const [selectedAltPayment, setSelectedAltPayment] = useState<AltPayment | undefined>(undefined);

  const primaryPayments = usePrimaryPayments(paymentPrices, previousBillerID);

  useEffect(() => {
    if (selectedPrimaryPayment?.name === 'Gift Card') {
      onGiftCardSelect();
      return;
    }

    setActivePayment(selectedPrimaryPayment || selectedAltPayment);
  }, [selectedPrimaryPayment, paymentPrices, selectedAltPayment, setActivePayment, onGiftCardSelect]);

  // figure out initial payment method
  useEffect(() => {
    if (!paymentPrices) return;

    const allPayments = Object.values(altPayments ?? {}).flatMap((a) => [...a] as AltPayment[]);
    const epoch = allPayments.find((a) => a.billerID === Biller.Epoch);

    const activeBiller = allPayments.find((a) => a.billerID === activeSub?.billerID || a.billerID === previousBillerID);

    const paymentOption =
      previousBillerID === Biller.Epoch && !!epoch
        ? epoch
        : activeBiller || { name: paymentPrices[0].paymentType, billerID: paymentPrices[0].billerID };

    setSelectedPrimaryPayment(paymentOption);
  }, [activeSub?.billerID, altPayments, paymentPrices, previousBillerID]);

  const onPrimaryPaymentClick = (billerID?: number) => {
    const payment = paymentPrices?.find((r) => r.billerID === billerID);

    if (purchasing || !payment) {
      return;
    }

    setSelectedPrimaryPayment({ name: payment?.paymentType, billerID: payment?.billerID });
    setSelectedAltPayment(undefined);
    setIsOtherPaymentsDropdownOpen(false);
  };

  const onDropdownClick = () => {
    if (paymentPrices?.length === 0) return;
    if (selectedAltPayment) {
      setSelectedPrimaryPayment(undefined);
    }
    setIsOtherPaymentsDropdownOpen(!isOtherPaymentsDropdownOpen);
  };

  const onAlternativePaymentSelect = (altPayment: AltPayment) => {
    setSelectedPrimaryPayment(undefined);
    setSelectedAltPayment(altPayment);
  };

  const loading = !getPricesResponse;

  return (
    <SectionContainer>
      <SectionTitle stepNumber={2} text={intl.formatMessage({ id: 'payment.choice.redesign.title' })} />
      <PaymentsWrapper>
        {loading && (
          <>
            <PaymentCardPlaceholder />
            <PaymentCardPlaceholder />
            <PaymentCardPlaceholder />
          </>
        )}
        {!loading && (
          <>
            {primaryPayments.map((payment) => (
              <MainPaymentCard
                key={payment.billerID}
                isActive={!isOtherPaymentsDropdownOpen && payment.billerID === selectedPrimaryPayment?.billerID}
                onClick={() => onPrimaryPaymentClick(payment.billerID)}
                payment={payment}
              />
            ))}
            {site.enableAlternativePayments && (
              <AlternativePaymentCard isActive={!!selectedAltPayment} onClick={onDropdownClick}>
                <AlternativePaymentsContentWrapper>
                  <span>
                    {selectedAltPayment?.name ||
                      intl.formatMessage({ id: 'payment.choice.redesign.alternative_payments' })}
                  </span>
                  <FontAwesomeIcon
                    className="fa-light"
                    icon={isOtherPaymentsDropdownOpen ? faAngleUp : faAngleDown}
                    size={'2x'}
                  />
                </AlternativePaymentsContentWrapper>
                {isOtherPaymentsDropdownOpen && (
                  <OtherPaymentsDropdown items={altPayments} onItemClick={onAlternativePaymentSelect} />
                )}
              </AlternativePaymentCard>
            )}
          </>
        )}
      </PaymentsWrapper>
    </SectionContainer>
  );
};

const MainPaymentCard = styled(PaymentCard)`
  flex: 1;
  @media screen and ${mediaBreakpoints.md} {
    flex: 0 1 auto;
  }

  :nth-child(n + 4) {
    display: none;
  }
`;

const AlternativePaymentCard = styled(PaymentCard)`
  flex-basis: 100%;

  @media screen and ${mediaBreakpoints.md} {
    flex-basis: auto;
  }
`;

const PaymentsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  @media screen and ${mediaBreakpoints.md} {
    flex-wrap: nowrap;
  }
`;

const AlternativePaymentsContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
