import React from 'react';
import styled from 'styled-components';
import { useAppData } from '../../helpers/useAppData';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const SimpleFooter = (props: Props) => {
  const { site } = useAppData();

  return (
    <Bar className={props.className}>
      {props.children}
      <RecordCompliance href={`https://www.${site?.domain}/2257`} target="_blank" rel="noopener noreferrer">
        18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement
      </RecordCompliance>
      <AgeCompliance>{`Warning - This site contains adult material of a sexual nature - you must be of legal age in your area to view this material. All materials on ${
        site?.name
      }, are copyright © ${new Date().getFullYear()} ${
        site?.name
      }. All models were at least 18 years of age when photographed.`}</AgeCompliance>
    </Bar>
  );
};

const Bar = styled.div`
  background-color: #000000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding: 64px;
  text-align: center;
`;

const RecordCompliance = styled.a`
  color: #ffffff;
  text-decoration: none;
`;

const AgeCompliance = styled.p`
  color: #b1b5bb;
  margin: 6px 0;
`;
