import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { usePopper } from 'react-popper';
import { FormattedMessage } from 'react-intl';
import { StoredOrderData } from 'src/shared/types/OrderResponse';
import { getData } from '../../helpers/utils';
import { cartStorageKey } from '../../helpers/constants';
import { useComponentVisible } from '../../helpers/useComponentVisible';
import { useAppData } from '../../helpers/useAppData';

type Props = {
  cbp?: boolean;
};

export const ProductInfo = (props: Props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLSpanElement | null>(null);
  const [showPopover, setShowPopover] = useComponentVisible(false, popperElement);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 12] } },
      // doing this instead of flip so we don't cover the iframe up too much
      { name: 'preventOverflow', options: { altAxis: true } },
    ],
  });

  const { subscriptionText, vatSubscriptionText, vatPopoverText } = useSubscriptionText();
  const values = useValues();
  const { cdnUrl } = useAppData();

  if (!values) return null;

  const updatePopoverState = (show: boolean) => {
    setShowPopover(show);
    if (update) {
      update();
    }
  };

  return (
    <Wrapper className="product-info" cbp={props.cbp}>
      <p className="no-vat">
        <FormattedMessage id={subscriptionText} values={values} />
      </p>
      <p className="text-vat">
        <FormattedMessage id={vatSubscriptionText} values={values} />
      </p>
      <p className="popup-vat flex-box">
        <span>
          <FormattedMessage id={vatPopoverText} values={values} />
        </span>
        <span>
          <FormattedMessage
            id="productInfo.subscriptionPriceWithVatDetails"
            defaultMessage="Order total includes VAT"
          />
          <span className="details" ref={setReferenceElement} onClick={() => updatePopoverState(true)}>
            <FormattedMessage id="productInfo.subscriptionPriceWithVatDetailsLink" defaultMessage="See Details" />
          </span>
        </span>
      </p>
      <Popover
        className={showPopover ? 'show' : 'hide'}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <img
          src={`${cdnUrl}/header/close.svg`}
          className="close"
          onClick={() => updatePopoverState(false)}
          alt="Close"
        />
        <PopoverTitle>
          <FormattedMessage id="productInfo.orderSummary" defaultMessage="Order Summary" />
        </PopoverTitle>
        <SummaryGrid>
          <span className="column">
            <FormattedMessage id="productInfo.totalBeforeVat" defaultMessage="Total before VAT:" />
          </span>
          <span className="column price">${values.initialPrice}</span>
          <span className="column">
            <FormattedMessage id="productInfo.vatAmount" defaultMessage="VAT amount:" />
          </span>
          <span className="column price">${values.vat}</span>
          <span className="column total">
            <FormattedMessage id="productInfo.orderTotal" defaultMessage="Order Total:" />
          </span>
          <span className="column total price">${values.priceWithVat}</span>
        </SummaryGrid>
        <span className="arrow" ref={setArrowElement} style={styles.arrow} />
      </Popover>
    </Wrapper>
  );
};

const Wrapper = styled.div<Props>`
  padding: 0 16px;

  > p {
    font-size: ${(props) => (props.cbp ? '14px' : '12px')};
    text-align: center;

    &.text-vat,
    &.popup-vat {
      display: none;
    }
  }

  .details {
    cursor: pointer;
    color: #064ffd;
    margin-left: 4px;
    text-decoration: underline;

    :hover {
      opacity: 75%;
    }
  }

  .flex-box {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 576px) {
      flex-direction: row;
    }

    > span:last-child {
      margin: 0 4px;
    }
  }
`;

const Popover = styled.div`
  background: #fff;
  border: 1px solid #e5e0e0;
  border-radius: 4px;
  box-shadow: 0 1px 4px #e5e0e0;
  color: #000;
  display: none;
  font-family: 'Open Sans', sans-serif;
  padding: 20px;
  position: relative;
  text-align: left;

  &.show {
    display: block;
  }

  .arrow,
  .arrow:before {
    background: inherit;
    height: 14px;
    position: absolute;
    width: 14px;
  }

  .arrow:after {
    background: inherit;
    content: '';
    height: 26px;
    position: absolute;
    top: -6px;
    width: 14px;
  }

  .arrow:before {
    border: 1px solid #e5e0e0;
    box-shadow: 0 1px 4px #e5e0e0;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement^='left'] > .arrow {
    right: -7px;

    :after {
      left: -7px;
    }
  }

  &[data-popper-placement^='right'] > .arrow {
    left: -7px;

    :after {
      left: 7px;
    }
  }

  .close {
    cursor: pointer;
    filter: invert(50%);
    height: 8px;
    position: absolute;
    right: 10px;
    top: 12px;

    :hover {
      filter: invert(100%);
    }
  }
`;

const PopoverTitle = styled.h4`
  margin: 10px 0 8px;
`;

const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: 3.5fr 1fr;

  .column {
    font-size: 12px;
    margin: 2px 0;
  }

  .total {
    font-weight: 700;
    border-top: 1px solid #e5e0e0;
    padding-top: 6px;
  }

  .price {
    justify-self: right;
  }
`;

// Setup all the values used by the strings.
const useValues = () => {
  const { site } = useAppData();
  const { originalProduct } = getData<StoredOrderData>(cartStorageKey);

  if (!originalProduct) return null;

  const vat = +originalProduct.initialPriceOriginal * +originalProduct.vatPct;
  const priceWithVat = (+originalProduct.initialPriceOriginal + vat).toFixed(2);

  return {
    domain: site?.domain,
    initialPeriod: originalProduct.initialPeriod,
    initialPrice: originalProduct.initialPriceOriginal,
    recurringPeriod: originalProduct.recurringPeriod,
    recurringPrice: originalProduct.recurringPriceOriginal,
    vat: vat.toFixed(2),
    priceWithVat,
  };
};

const useSubscriptionText = () => {
  const { originalProduct } = getData<StoredOrderData>(cartStorageKey);

  if (!originalProduct) return {};

  switch (true) {
    case !!originalProduct.initialPeriod && !!originalProduct.recurringPeriod:
      return {
        subscriptionText: 'productinfo.subscription.price_with_initial_and_recurring',
        vatSubscriptionText: 'productinfo.subscription_with_vat.price_with_initial_and_recurring',
        vatPopoverText: 'productinfo.subscription_vat_popover.price_with_initial_and_recurring',
      };

    case !!originalProduct.initialPeriod:
      return {
        subscriptionText: 'productinfo.subscription.price_with_initial',
        vatSubscriptionText: 'productinfo.subscription_with_vat.price_with_initial',
        vatPopoverText: 'productinfo.subscription_vat_popover.price_with_initial',
      };

    case !!originalProduct.recurringPeriod:
      return {
        subscriptionText: 'productinfo.subscription.price_with_recurring',
        vatSubscriptionText: 'productinfo.subscription_with_vat.price_with_recurring',
        vatPopoverText: 'productinfo.subscription_vat_popover.price_with_recurring',
      };

    default:
      return {
        subscriptionText: 'productinfo.subscription.price_only',
        vatSubscriptionText: 'productinfo.subscription_with_vat.price_only',
        vatPopoverText: 'productinfo.subscription_vat_popover.price_only',
      };
  }
};
