import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Price } from 'src/client/components/TemplateA/Price/Price';
import { Selectable } from '../../components/TemplateA/Selectable/Selectable';
import { Placeholder } from '../../components/Placeholder/Placeholder';
import { PlaceholderLine } from '../../components/Placeholder/PlaceholderLine';
import { PlaceholderImage } from '../../components/Placeholder/PlaceholderImage';
import { SubscriptionType } from '../../../shared/constants/billers';
import { SubscriptionButton } from '../PreJoinPage/SubscriptionOption';

const placeholders = ['p1', 'p2', 'p3'];

const SelectablePlaceholder = styled(Selectable)`
  & .line.placeholder-percentage,
  & .line.placeholder-period,
  & .line.placeholder-text {
    margin: 12px auto;
  }

  & .image.placeholder-price {
    width: 85%;
    padding-top: 72px;
    margin: 12px auto 16px;
  }

  & .image.placeholder-button {
    padding-top: 30px;
    width: 140px;
    margin: 12px auto;
  }
`;

export function DiscountChoiceContainer(props) {
  const { activeOption, className, discountPrices, onClick } = props;

  const discountCount = discountPrices ? discountPrices.length : 3;
  const colSize = Math.min(12, Math.max(12 / discountCount, 4));

  return (
    <div className={`row ${className}`}>
      {!discountPrices.length &&
        placeholders.map((placeholder) => (
          <div key={placeholder} className="discount-column col-md-4 col-lg-4">
            <SelectablePlaceholder>
              <Placeholder>
                <PlaceholderLine className="placeholder-percentage" short />
                <PlaceholderLine className="placeholder-period" medium />
                <PlaceholderImage className="placeholder-price" />
                <PlaceholderLine className="placeholder-text" medium />
                <PlaceholderImage className="placeholder-button" />
              </Placeholder>
            </SelectablePlaceholder>
          </div>
        ))}
      {!!discountPrices.length &&
        discountPrices.map((discount) => {
          const active = activeOption ? discount.initialPriceOriginal === activeOption.initialPriceOriginal : false;
          const discountPrice = discount.perPeriod ? (discount.price / 12).toFixed(2) : discount.price;
          const [amount, cents] = discountPrice.split('.');
          return (
            <div key={discount.productUUID} className={`discount-column col-md-${colSize} col-lg-${colSize}`}>
              <Selectable
                className={`discount-price ${active ? 'active' : 'inactive'}`}
                onClick={() => onClick(discount)}
              >
                <p className="discount-percentage">{discount.percentage ? `Save ${discount.percentage}` : ''}</p>
                <p className="discount-period">{`${discount.recurringPeriodText} Membership`}</p>
                <Price
                  currency={discount.currency}
                  currencySymbol={discount.currencySymbol}
                  amount={amount}
                  cents={cents}
                />
                <p className="discount-recurring-text">
                  {discount.subscriptionTypeID === SubscriptionType.Lifetime || discount.initialPeriod < 30
                    ? 'Per Site'
                    : 'Per Month / Per Site'}
                </p>
                {discount.subscriptionTypeID !== 4 && discount.initialPeriod < 30 && (
                  <p className="discount-recurring-text">{discount.recurringText}</p>
                )}
                <SubscriptionButton active={active}>Select Plan</SubscriptionButton>
              </Selectable>
            </div>
          );
        })}
    </div>
  );
}

const choiceShape = PropTypes.shape({
  currency: PropTypes.string,
  initialPeriod: PropTypes.number,
  initialCurrencyPrice: PropTypes.string,
  initialPrice: PropTypes.string,
  recurringPeriod: PropTypes.number,
  recurringPrice: PropTypes.string,
  recurringCurrencyPrice: PropTypes.string,
  subscriptionTypeID: PropTypes.number,
  siteUUIDs: PropTypes.arrayOf(PropTypes.string),
});

DiscountChoiceContainer.propTypes = {
  activeOption: choiceShape,
  discountPrices: PropTypes.arrayOf(choiceShape),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

DiscountChoiceContainer.defaultProps = {
  activeOption: '',
  discountPrices: [],
  className: '',
  onClick: null,
};

export default styled(DiscountChoiceContainer)`
  background-color: #fff;
  border-radius: 4px;
  padding: 24px 18px;

  & .discount-column {
    padding: 6px 0px;
  }

  & .discount-price {
    background-color: #191919;
    color: #ccc;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 18px;

    &.active {
      background-color: #000;
      color: #fff;

      .discount-percentage {
        color: #f4ba3a;
      }

      .discount-recurring-text {
        color: #fff;
      }
    }

    .discount-percentage {
      color: #fff;
      font-size: 0.75rem;
      font-weight: 600;
      margin: 0;
      padding: 12px 0;
    }

    .discount-period {
      font-weight: 700;
      margin: 0;

      :after {
        border-bottom: 1px solid #333333;
        content: ' ';
        display: block;
        margin: 0 auto;
        padding-top: 12px;
        width: 80%;
      }
    }

    .discount-recurring-text {
      color: #999;
      font-size: 0.625rem;
      font-weight: 600;
      margin: 0 0 16px;
      min-height: 14px;
    }
  }
`;
