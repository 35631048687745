import React from 'react';
import styled from 'styled-components/macro';
import { useRouteMatch } from 'react-router-dom';
import { useAppData } from 'src/client/helpers/useAppData';
import { TABLET_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/helpers/mediaQueries';
import { routerPaths } from 'src/client/helpers/constants';

interface Props {
  className?: string;
}

export const Teasers = (props: Props) => {
  const { site, cdnUrl, showLegaLRestrictionSplash } = useAppData();

  // Any state that should have the legal spash page will get the non-nude teasers.
  // We need to keep the route match for showing them also.
  const nonNudeMatch = !!useRouteMatch({ strict: true, path: routerPaths.nonNude.path }) || showLegaLRestrictionSplash;
  const teaserAbbreviation = nonNudeMatch ? 'nn' : site?.abbreviation;

  const teaserUrl = `${cdnUrl}/teasers/${teaserAbbreviation}/${teaserAbbreviation}`;

  return (
    <Wrapper className={props.className}>
      <TeaserImage src={`${teaserUrl}_01.jpg`} alt="" />
      <TeaserImage src={`${teaserUrl}_02.jpg`} alt="" />
      <TeaserImage src={`${teaserUrl}_03.jpg`} alt="" />
      <TeaserImage src={`${teaserUrl}_04.jpg`} alt="" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 4px;

  @media ${TABLET_PORTRAIT} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${TABLET_LANDSCAPE} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const TeaserImage = styled.img`
  width: 100%;

  // Hide the last element for tablet portrait.
  // This gives us a 1x3 grid for that size.
  &:last-child {
    @media ${TABLET_PORTRAIT} {
      display: none;
    }
    @media ${TABLET_LANDSCAPE} {
      display: block;
    }
  }
`;
