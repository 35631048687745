import styled from 'styled-components/macro';
import cx from 'classnames';

type Props = {
  className?: string;
  long?: boolean;
  medium?: boolean;
  short?: boolean;
  full?: boolean;
};

export const PlaceholderLine = styled.div.attrs(({ className, theme, ...rest }) => ({
  className: cx('line', { ...rest }),
}))<Props>`
  width: 100%;
  height: 14px;
  display: block;

  &.full {
    width: 100%;
  }

  &.long {
    width: 85%;
  }

  &.medium {
    width: 50%;
  }

  &.short {
    width: 35%;
  }
`;
