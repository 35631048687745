import React from 'react';
import styled from 'styled-components/macro';
import { Button } from '../TemplateA/Button/Button';
import { ProductInfo } from './ProductInfo';

type Props = {
  loading: boolean;
  onClick: () => void;
};

const Wrapper = styled.div`
  width: 100%;

  h1,
  p {
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  width: 140px;
  margin: 36px auto 24px;
`;

export const ChargeByPrevious = (props: Props) => {
  const { loading, onClick } = props;

  return (
    <Wrapper>
      <h1>Click Below To Complete Your Purchase</h1>
      <ProductInfo cbp />
      <StyledButton active loading={loading} onClick={onClick}>
        Purchase
      </StyledButton>
    </Wrapper>
  );
};
