import React from 'react';
import styled from 'styled-components/macro';
import { AltSubscriptionButton, SectionTitle } from './styles';

const Link = styled.a`
  text-decoration: none;
`;

type Props = {
  onClick: () => void;
};

export const PayByCheckPanel = (props: Props) => {
  const { onClick } = props;
  return (
    <div className="price-groups subscription">
      <SectionTitle>3. How it works</SectionTitle>
      <p>
        You can send a cashier&apos;s/certified check or money order, (international money order if you are not in the
        US) for non recurring memberships via regular mail. If you would like to use this option, please contact us for
        the payment address.
      </p>
      <Link
        href="https://metart.atlassian.net/servicedesk/customer/portal/3/group/3/create/2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AltSubscriptionButton active={true} onClick={onClick}>
          Pay By Check or Money Order
        </AltSubscriptionButton>
      </Link>
    </div>
  );
};
