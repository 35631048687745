import React from 'react';
import styled, { css } from 'styled-components/macro';
import { maxWidthMediaQueries } from 'src/client/helpers/mediaQueries';
import { useAppData } from '../../helpers/useAppData';

type Props = {
  containMaxWidth?: boolean;
  count: number;
  onCartClick?: () => void;
  sticky?: boolean;
};

export const ShoppingCartHeader = (props: Props) => {
  const { count, onCartClick, containMaxWidth, sticky } = props;
  const { cdnUrl, site } = useAppData();

  return (
    <Bar sticky={sticky}>
      <Wrapper containMaxWidth={containMaxWidth}>
        <Logo src={`${cdnUrl}/logos/${site?.networkUUID}/${site?.abbreviation}@2x.png`} />
        <ShoppingCart onClick={onCartClick}>
          <Cart src={`${cdnUrl}/header/shopping_cart.png`} />
          <Count>{count}</Count>
        </ShoppingCart>
      </Wrapper>
    </Bar>
  );
};

const Bar = styled.div<{ sticky?: boolean }>`
  background-color: #000000;
  height: 60px;
  position: relative;
  width: 100%;

  ${({ sticky }) =>
    sticky
      ? css`
          position: sticky;
          top: 0;
          z-index: 4;
        `
      : ''}
`;

const Wrapper = styled.div<{ containMaxWidth?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  ${({ containMaxWidth }) => containMaxWidth && maxWidthMediaQueries}
`;

const Logo = styled.img`
  max-height: 100%;
  max-width: 205px;
  padding: 8px 0;
`;

const ShoppingCart = styled.div`
  cursor: pointer;
  position: relative;
  margin-right: 22px;
`;

const Cart = styled.img`
  max-height: 30px;
`;

const Count = styled.div`
  background-color: #ffcc00;
  border: 1px solid #ffcc00;
  border-radius: 50%;
  bottom: 0;
  color: #000000;
  height: 22px;
  line-height: 1.4;
  position: absolute;
  right: -22px;
  text-align: center;
  width: 22px;
`;
