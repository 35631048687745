import { AltPayment } from '@tovia/man-protos/dist/types/billing.types';
import styled, { css } from 'styled-components';
import React, { useCallback } from 'react';
import { usePopper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import { useIntl } from 'react-intl';
import { Selectable } from 'src/client/components/TemplateA/Selectable/Selectable';
import { onEnterHandler } from 'src/client/helpers/onEnterHandler';
import { makeUrlSafeName } from './makeUrlSafeName';

type Props = {
  cdnUrl?: string;
  payment: AltPayment;
  selectAltPayment: (altPayment: AltPayment) => void;
  selectedAltPayment?: AltPayment;
  paymentGroup: string;
};

const transitionName = 'popover';
const transitionDuration = 150;

export const AlternativePaymentOption = (props: Props) => {
  const { payment, paymentGroup, selectAltPayment } = props;
  const intl = useIntl();

  const [visible, setVisible] = React.useState(false);
  const referenceElement = React.useRef<HTMLDivElement>(null);
  const popperElement = React.useRef<HTMLDivElement>(null);
  const [arrowElement, setArrowElement] = React.useState<HTMLDivElement | null>(null);

  const { styles, attributes, update } = usePopper(referenceElement.current, popperElement.current, {
    placement: 'top',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 12] } },
    ],
  });

  const shortName = makeUrlSafeName(payment.name);
  const providerStringExists = !!intl.messages[`payment.provider.${shortName}`];
  const flavorText = providerStringExists
    ? intl.formatMessage({ id: `payment.provider.${shortName}` })
    : intl.formatMessage({ id: 'payment.provider.default' });

  const disabled = paymentGroup === 'international';

  const showPopover = useCallback(() => {
    setVisible(true);
    update?.();
  }, [setVisible, update]);

  const hidePopover = () => setVisible(false);

  const showGenericIcon = ({ currentTarget }: React.SyntheticEvent<HTMLImageElement>) => {
    currentTarget.onerror = null;
    currentTarget.src = `${props.cdnUrl}/payment/generic_payment.svg`;
  };

  return (
    <PopoverReference
      onMouseEnter={showPopover}
      onFocus={showPopover}
      onMouseLeave={hidePopover}
      onBlur={hidePopover}
      ref={referenceElement}
    >
      <PaymentOption
        disabled={disabled}
        onClick={() => selectAltPayment(payment)}
        active={payment.name === props.selectedAltPayment?.name}
      >
        <img
          width={60}
          height={60}
          src={`${props.cdnUrl}/payment/${shortName}.svg`}
          alt={payment.name}
          onError={showGenericIcon}
        />
        <PaymentOptionDetails>
          <PaymentOptionName>{payment.name}</PaymentOptionName>
          <PaymentOptionFlavorText role="description">{flavorText}</PaymentOptionFlavorText>
        </PaymentOptionDetails>
      </PaymentOption>
      <CSSTransition
        in={visible}
        classNames={transitionName}
        timeout={transitionDuration}
        mountOnEnter
        unmountOnExit
        onEnter={onEnterHandler}
      >
        <PopoverAnimationWrapper>
          <PaymentPopover ref={popperElement} style={styles.popper} {...attributes.popper}>
            <PopoverArrow ref={setArrowElement} style={styles.arrow} {...attributes.arrow} />
            {flavorText}
          </PaymentPopover>
        </PopoverAnimationWrapper>
      </CSSTransition>
    </PopoverReference>
  );
};

const PopoverReference = styled.div`
  min-width: 0;
  height: 100%;
  position: relative;
`;

const PaymentOption = styled(Selectable)<{ disabled?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  min-width: 0;
  margin: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: #ffffff;
        opacity: 0.7;
      }

      img {
        opacity: 0.7;
      }
    `}

  img {
    vertical-align: middle;
    filter: saturate(0%);

    @media (hover: none) {
      filter: saturate(100%);
    }
  }

  &.active,
  &:hover {
    border-color: dodgerblue;
    img {
      filter: saturate(100%);
    }
  }
`;

const PaymentOptionDetails = styled.div`
  text-align: left;
  margin-left: 10px;
  overflow: hidden;
`;

const PaymentOptionFlavorText = styled.span`
  margin-top: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.75rem;
  color: #666666;
`;

const PaymentOptionName = styled.h4`
  font-weight: 600;
  font-size: 1rem;
  flex-basis: 100%;
  margin: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PopoverAnimationWrapper = styled.div`
  width: 100%;
  transition-property: opacity top;
  transition-duration: ${transitionDuration}ms;
  position: relative;

  &.${transitionName}-enter {
    opacity: 0;
    top: 5px;
  }
  &.${transitionName}-enter-active {
    opacity: 1;
    top: 0;
    transition-timing-function: ease-in;
  }

  &.${transitionName}-exit {
    opacity: 1;
    top: 0;
  }
  &.${transitionName}-exit-active {
    opacity: 0;
    top: 5px;
    transition-timing-function: ease-out;
  }
`;

const PaymentPopover = styled.div`
  color: #ffffff;
  background: #000000;
  z-index: 1;
  font-size: 0.7rem;
  padding: 15px;
  border-radius: 4px;
  text-align: left;
  pointer-events: none;
`;

const PopoverArrow = styled.div`
  position: absolute;
  top: 100%;
  width: 12px;
  height: 12px;
  margin-top: -6px;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    transform: translateX(0px) rotate(45deg);
    transform-origin: center;
    background: #000000;
    z-index: 0;
    display: block;
  }
`;
