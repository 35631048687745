import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { useAppData } from 'src/client/helpers';

type StyledComponentProps = {
  responsive?: boolean;
  removable?: boolean;
};

const CartItemWrapper = styled.div`
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
`;

const ClearFix = styled.div`
  clear: both;
`;

const Image = styled.img<StyledComponentProps>`
  border-radius: 4px;
  float: left;
  margin: 0 12px 10px 0;
  width: 120px;

  ${({ responsive }) =>
    responsive
      ? `
    @media (min-width: 1338px) {
      margin: 0 24px 10px 0;
      width: 380px;
    }
  `
      : ''}
`;

const Site = styled.span<StyledComponentProps>`
  display: block;
  font-size: 16px;
  font-weight: 500;

  @media (min-width: 1338px) {
    ${({ responsive }) =>
      responsive &&
      css`
        font-size: 18px;
        padding: 48px 0 12px 0;
      `}
  }
`;

const Access = styled.span<StyledComponentProps>`
  display: block;
  font-size: 12px;
  @media (min-width: 1024px) {
    font-size: ${({ responsive }) => responsive && '16px'};
  }
`;

const Options = styled.div`
  display: flex;

  > div {
    flex-grow: 1;
  }
`;

const Remove = styled.div<StyledComponentProps>`
  display: flex;
  cursor: pointer;

  > img {
    margin-right: 4px;
    width: 14px;
  }

  > span {
    align-self: flex-end;
  }
`;

const Price = styled.div<StyledComponentProps>`
  font-size: 14px;
  text-align: right;

  @media (min-width: 1024px) {
    display: ${({ responsive }) => responsive && 'none'};
  }
`;

type Props = {
  abbreviation: string;
  access?: string;
  networkUUID: string;
  price: number;
  removePlan: (siteUUID: string) => void;
  responsive?: boolean;
  site: string;
  siteUUID: string;
};

export const CartItem = (props: Props) => {
  const { abbreviation, access = '', networkUUID, price, removePlan, responsive, site, siteUUID } = props;

  const { cdnUrl } = useAppData();

  return (
    <CartItemWrapper>
      <Image src={`${cdnUrl}/upgrades/${networkUUID}/${abbreviation}_t.png`} alt={site} responsive={responsive} />
      <Site responsive={responsive}>{site}</Site>
      <Access responsive={responsive}>{`${access} Access`}</Access>
      <ClearFix />
      <Options>
        <Remove onClick={() => removePlan(siteUUID)}>
          <img src={`${cdnUrl}/cart/trash.svg`} alt="trash" />
          <span>Remove</span>
        </Remove>
        <Price responsive={responsive}>&#36;{price}</Price>
      </Options>
    </CartItemWrapper>
  );
};

CartItem.propTypes = {
  abbreviation: PropTypes.string.isRequired,
  access: PropTypes.string,
  networkUUID: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  removable: PropTypes.bool,
  removePlan: PropTypes.func,
  responsive: PropTypes.bool,
  site: PropTypes.string.isRequired,
  siteUUID: PropTypes.string.isRequired,
};
