import React from 'react';
import styled from 'styled-components/macro';
import cx from 'classnames';
import { noop } from 'src/client/helpers/noop';
import { Spinner } from '../../Spinner/Spinner';

type Props = {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
  onClick?: () => void;
};

export const Button = (props: Props) => {
  const { active, children, className, loading, onClick = noop } = props;
  const showSpinner = active && loading;

  return (
    <StyledButton onClick={onClick} className={cx({ active }, className)} disabled={loading} type="button">
      {showSpinner ? <Spinner /> : children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  background-color: #2ebd59;
  border: 0;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 1.25rem;
  height: 48px;
  margin: 0 auto;
  padding: 2px 0;

  :not(.active) {
    background-color: lightgray;
  }

  :focus {
    outline: none;
  }
`;
