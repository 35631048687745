import { useEffect, useMemo } from 'react';
import { UserTypes } from '../../../shared/constants/userTypes';
import { useAppData } from '../../helpers';
import { ABTestMeasurementProps } from '../../../shared/types/userMeasurement';
import { findABTestDefinitionById, isABTestEnabled } from '../../../shared/constants/abTests';
import { useAnalyticsABMeasurement } from './useAnalyticsABMeasurement';
import { getDeviceType } from '../../helpers/utils';

export const useAnalyticsSendABTestMeasurement = (props: ABTestMeasurementProps) => {
  const { abTestId, abTestVariantId, type } = props;
  const appData = useAppData();
  const { abTests, user } = appData;
  const { sendABTestMeasurement } = useAnalyticsABMeasurement({ abTestId, abTestVariantId });

  const testDefinition = useMemo(() => findABTestDefinitionById(abTestId), [abTestId]);

  useEffect(() => {
    if (!testDefinition) {
      return;
    }

    const userType = user.userType || UserTypes.Unknown;
    const isEnabled = isABTestEnabled({
      testId: testDefinition.id,
      appData,
      deviceType: getDeviceType(navigator.userAgent),
    });
    const isUserEligible = testDefinition.userTypes.includes(userType);

    if (isEnabled && isUserEligible) {
      sendABTestMeasurement({ abTestId, type });
    }
  }, [sendABTestMeasurement, abTests, user, testDefinition, abTestId, type, appData]);
};
