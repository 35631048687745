import { useState, useEffect, useCallback } from 'react';

export const useComponentVisible = (initialVisibility: boolean, element: HTMLElement | null) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialVisibility);

  const handleClickOutside = useCallback(
    (event: Event) => {
      // only remove visibility if the element is not null
      if (element && !element.contains(event.target as Node)) {
        setIsComponentVisible(false);
      }
    },
    [element],
  );

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside, true);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return [isComponentVisible, setIsComponentVisible] as const;
};
