import React from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useFeatureFlags } from 'src/client/helpers/useFeatureFlags';
import { MOBILE_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/helpers/mediaQueries';
import { Container } from '../../Container/Container';
import { useAppData } from '../../../helpers/useAppData';
import { LanguageSelector } from '../../Header/LanguageSelector';

interface Props {
  disableLogin?: boolean;
  useAffiliateHeader?: boolean;
}

export const Header = (props: Props) => {
  const { affiliateHeader, cdnUrl, site, user } = useAppData();
  const { disableLogin, useAffiliateHeader } = props;
  const featureFlags = useFeatureFlags();

  const location = useLocation();
  const urlSafePath = [
    location.pathname.replace(/^\//, ''),
    location.search ? `&${location.search.substring(1)}` : '',
  ].join('');

  const logoUrl = `${cdnUrl}/logos/${site?.networkUUID}/${site?.abbreviation}@2x.png`;
  const showAffiliateHeader = useAffiliateHeader && affiliateHeader;

  return (
    <>
      <HeaderWrapper>
        <GridContainer>
          <Logo src={logoUrl} alt={site.name} />
          <Navigation>
            <Secure>
              <FontAwesomeIcon icon={faLockAlt} className="icon" />
              <FormattedMessage id="header.text.safe_secure" />
            </Secure>
            {!disableLogin && (
              <>
                {!user?.isUser ? (
                  <a href={`/login/?r=${urlSafePath}`}>
                    <FormattedMessage id="header.text.login" />
                  </a>
                ) : (
                  <a href={`/logout/?r=${urlSafePath}`}>
                    <FormattedMessage id="header.text.logout" />
                  </a>
                )}
              </>
            )}
            {featureFlags.enableInternationalization && <LanguageSelector />}
          </Navigation>
        </GridContainer>
      </HeaderWrapper>
      {showAffiliateHeader && <div dangerouslySetInnerHTML={{ __html: affiliateHeader }} />}
    </>
  );
};

const HeaderWrapper = styled.header`
  background-color: #000;
  color: #fff;
  height: 58px;
`;

// These grid columns solve the problem of some of the languages being too long
// for mobile screens.
//
// The Logo can shrink down to 150px wide at min, and the navigation can grow to
// the width of the container minus padding (for each side) minus the logo.
// This forces some of the languages that have multiple words for 'login' to
// wrap to the next line.
const padding = 15;
const maxLogoWidth = 150;
const maxNavConstraint = maxLogoWidth + padding * 2;

const GridContainer = styled(Container)`
  height: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-columns:
    minmax(${maxLogoWidth}px, max-content)
    minmax(calc(100% - ${maxNavConstraint}px), 1fr);
  column-gap: ${padding}px;
  padding: 0 ${padding}px;

  @media ${TABLET_PORTRAIT} {
    column-gap: ${padding * 2}px;
  }
`;

const Logo = styled.img`
  align-self: center;
  max-width: 100%;
`;

const Secure = styled.span`
  display: none;

  @media ${TABLET_PORTRAIT} {
    display: inline;
  }
`;

const Navigation = styled.div`
  align-self: center;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;

  @media ${MOBILE_LANDSCAPE} {
    gap: 30px;
  }

  .secure {
    text-transform: uppercase;
  }

  .icon {
    height: 16px;
    margin-right: 8px;
    width: 16px;
  }

  a {
    font-size: 1.1rem;
    color: lightgray;
    text-decoration: none;
    text-align: right;

    :hover {
      color: #fff;
    }
  }
`;
