import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Helmet } from 'react-helmet';
import { Header } from '../../components/TemplateA/Header/Header';
import { PurchaseFrame } from '../../components/Purchase/PurchaseFrame';
import { MainFooter } from '../../components/Footer/MainFooter';
import { SecuritySection } from '../../components/SecuritySection/SecuritySection';
import { RedirectSection } from '../../components/RedirectSection/RedirectSection';
import { routerPaths } from '../../helpers/constants';
import { Container } from '../../components/Container/Container';

type Props = {
  type: 'gallery' | 'site';
};

export const PurchaseExternal = (props: Props) => {
  const [redirecting, setRedirecting] = useState(false);

  const location = useLocation();
  const { addToast } = useToasts();

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const iframeURL = queryParams.get('iframeURL');
  const redirectURL = queryParams.get('redirectURL');

  useEffect(() => {
    if (redirectURL) {
      setRedirecting(true);
      window.location.assign(decodeURIComponent(redirectURL));
    }
  }, [redirectURL]);

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (
        e &&
        e.data &&
        e.data.source === 'onCompletePage' &&
        e.origin === `${window.location.protocol}//${window.location.host}`
      ) {
        if (e.source) {
          (e.source as WindowProxy).close();
        }

        if (+e.data.errcat > 0) {
          addToast('Unable to complete the purchase, please try again or use a different credit card.', {
            appearance: 'error',
            autoDismiss: false,
          });
          return;
        }

        const redirectType = props.type === 'gallery' ? 'pps' : 'cc';

        if (e.data.success) {
          const params = new URLSearchParams(e.data);
          params.set('type', redirectType);

          const successUrl = `${routerPaths.purchase.success}?${params.toString()}`;

          window.location.assign(successUrl);
        } else {
          addToast('Unable to complete the purchase', { appearance: 'error', autoDismiss: false });
        }
      }
    };

    window.addEventListener('message', handleMessage, false);

    return function cleanup() {
      window.removeEventListener('message', handleMessage);
    };
  }, [addToast, props.type]);

  return (
    <>
      <Helmet>
        <title>Complete Your Purchase</title>
      </Helmet>
      <PurchaseWrapper>
        {redirecting ? (
          <RedirectSection />
        ) : (
          <>
            <Header disableLogin />
            <Container>
              <div className="iframe">{iframeURL && <PurchaseFrame simpleAmount={true} iframeUrl={iframeURL} />}</div>
              <SecuritySection />
              <MainFooter />
            </Container>
          </>
        )}
      </PurchaseWrapper>
    </>
  );
};

const PurchaseWrapper = styled.div`
  background-color: #fff;
  min-height: 100vh;

  .iframe {
    display: flex;
    margin-top: 12px;
  }
`;
