import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { noop } from 'src/client/helpers/noop';

export const StyledButton = styled.button`
  appearance: none;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 12px;
  text-transform: uppercase;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;

  @media (min-width: 1152px) {
    font-size: 18px;
    padding: 20px 12px;
  }

  &.gold {
    background-color: #ffcc00;
  }

  &.transparent {
    background-color: rgb(0, 0, 0, 0);
  }

  &.black {
    background-color: #000000;
  }

  &.gray {
    background-color: #b1b5bb;
  }

  &.white {
    background-color: #ffffff;
  }

  &.bold {
    font-weight: 700;
  }

  &.underline {
    text-decoration: underline;
  }

  &.dark {
    color: #000000;
  }

  &.light {
    color: #ffffff;
  }

  &:focus {
    outline: none;
  }

  &:active {
    position: relative;
    top: 1px;
  }
`;

type Props = {
  bgColor?: 'black' | 'gold' | 'gray' | 'transparent' | 'white';
  bold?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  text: ReactNode;
  textColor?: 'dark' | 'light';
  underline?: boolean;
};

export const Button = (props: Props) => {
  const { bold, disabled, onClick = noop, text, textColor = 'dark', underline, ...rest } = props;
  const bgColor = disabled ? 'gray' : props.bgColor;

  return (
    <StyledButton
      {...rest}
      className={`${bgColor} ${textColor} ${bold ? 'bold' : ''} ${underline ? 'underline' : ''}`}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {text}
    </StyledButton>
  );
};

// This is kind of a weird thing to appeas the type checker over in the PricePlan
// component.   We can do some refactoring over there and remove it, but right
// now we're just getting rid of the javascript.
type LinkButtonProps = {
  rel: string;
  target: string;
  href: string;
} & Props;

export const LinkButton = styled(Button).attrs({ as: 'a' })<LinkButtonProps>``;
