export type SupportedLanguage = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'nl' | 'pt';

export const supportedLanguages = ['de', 'en', 'es', 'fr', 'it', 'ja', 'nl', 'pt'] as const;

export const flags: Record<SupportedLanguage, string> = {
  de: '🇩🇪',
  en: '🇺🇸',
  es: '🇪🇸',
  fr: '🇫🇷',
  it: '🇮🇹',
  ja: '🇯🇵',
  nl: '🇳🇱',
  pt: '🇵🇹',
};

export const languageNames: Record<SupportedLanguage, string> = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  ja: '日本語',
  nl: 'Nederlands',
  pt: 'Português',
};
