import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import { CategoryId, CategoryIds } from 'src/shared/types/userMeasurement';
import { useAnalyticsSignals } from './useAnalyticsSignals';
import { ANALYTICS_COOKIE_NAME, CSRF_TOKEN } from '../../../shared/constants/cookies';
import { useAppData } from '../../helpers';
import { UserType, UserTypes } from '../../../shared/constants/userTypes';

type Props = {
  categoryId: CategoryId;
};

// This should be the first analytics hook that's loaded
// as it's responsible for setting the cookie.
export const useAnalyticsPageView = (props: Props) => {
  const { categoryId } = props;
  const { signalAnalyticsPageView } = useAnalyticsSignals();
  const { user } = useAppData();
  const [cookies] = useCookies([ANALYTICS_COOKIE_NAME, CSRF_TOKEN]);
  const analyticsUUID: string = cookies[ANALYTICS_COOKIE_NAME];
  const csrfToken = cookies[CSRF_TOKEN];

  useEffect(() => {
    if (!csrfToken || !analyticsUUID) {
      return;
    }

    const pageViewMeasurementUserTypes: UserType[] = [UserTypes.Guest, UserTypes.Free];
    const shouldMeasureUserPageView = user && user.userType && pageViewMeasurementUserTypes.includes(user.userType);
    if (categoryId !== CategoryIds.PageViewsAndNewSubscriptions || shouldMeasureUserPageView) {
      signalAnalyticsPageView(analyticsUUID, categoryId);
    }
  }, [analyticsUUID, signalAnalyticsPageView, csrfToken, categoryId, user]);
};
