import React, { useEffect } from 'react';

const chatWidgetCSS = `
  .chat-widget-wrapper {
    bottom:10px!important;
    right:10px!important;
  }
  .chat-icon-button {
    background:url('https://assets.metartnetwork.com/livechat/chat@2x.png') no-repeat!important;
    background-size:contain!important;
    border-radius:0!important;
    width:53px!important;
    height:52px!important;
  }
  .chat-icon-button:hover {
    width:56px!important;
    height:55px!important;
  }
  .knowledge-base-wrapper {
    display:none!important;
  }
  .chat-close-icon,.chat-offline,.chat-icon {
    background-image:none!important;
  }
  .chat-popup-wrapper {
    margin-bottom:10px!important;
  }
  .chat-button[data-spacing=default] {
    background:#587BCE!important;
  }
  .chat-button[data-spacing=default]:hover {
    background:#34549E!important;
  }
  .container-header {
    background-color:#8F9196!important;
  }
  div {
    --main-accent-color:#587BCE!important;
  }
  .pre-chat-form-field-label .required {
    color:#587BCE!important;
  }
`;

export const ChatWidget = (props: { 'jira-id': string; 'service-desk-id': string }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://chat-api.spartez-software.com/chat-widget.js';
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      customElements.whenDefined('chat-widget').then(() => {
        const chatWidget = document.querySelector('chat-widget');
        if (chatWidget && chatWidget.shadowRoot) {
          const style = document.createElement('style');
          style.textContent = chatWidgetCSS;
          chatWidget.shadowRoot.appendChild(style);
        }
      });
    };
  }, []);

  return React.createElement('chat-widget', props);
};
