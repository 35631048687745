import React from 'react';
import styled from 'styled-components/macro';
import { useAppData } from 'src/client/helpers';
import { Toy } from './ToysSection';

type Props = {
  toy: Toy;
};

export const ToyThumbnail = ({ toy: { label, imageName } }: Props) => {
  const { cdnUrl } = useAppData();

  return (
    <ToyWrapper href={'https://goddess.me'} target="_blank" rel="nofollow noopener noreferrer">
      <ImageWrapper>
        <ToyImage src={`${cdnUrl}/toys/${imageName}.png`} alt={label.replace('\n', ' ')} />
      </ImageWrapper>
      <ToyName>{label}</ToyName>
    </ToyWrapper>
  );
};

const ToyWrapper = styled.a`
  background: #111;
  border-radius: 3px;
  overflow: hidden;
  text-decoration: none;
  aspect-ratio: 1 / 1;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 75%;
  background-color: #e3e2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
`;

const ToyImage = styled.img`
  display: block;
  object-fit: contain;
  height: 100%;
`;

const ToyName = styled.div`
  background-color: #c2c4cd;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000;
  height: 25%;
  white-space: pre-line;
`;
