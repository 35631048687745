import React from 'react';
import styled from 'styled-components/macro';
import { mediaBreakpoints } from '../../helpers/mediaQueries';

type Props = {
  stepNumber: number;
  text: string;
};

export const SectionTitle = (props: Props) => {
  const { stepNumber, text } = props;

  return (
    <Wrapper>
      {stepNumber}:&nbsp;{text}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 30px;

  @media ${mediaBreakpoints.md} {
    font-size: 1.5rem;
  }
`;
