import React from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';

export const RedirectSection = () => (
  <RedirectWrapper>
    <FontAwesomeIcon icon={faExternalLink} className="icon" />
    <h1 className="redirect-text">Redirecting to complete your purchase</h1>
  </RedirectWrapper>
);

const RedirectWrapper = styled.div`
  align-items: center;
  background-color: #eff0f2;
  display: grid;
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
  justify-content: center;
  text-align: center;

  .icon {
    align-self: end;
    height: 64px;
    justify-self: center;
    width: 64px;
  }

  .redirect-text {
    align-self: start;
    font-size: 2.25rem;
    font-weight: 700;
    margin: 12px;
  }
`;
