import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { useAppData } from '../../helpers/useAppData';

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

export function MetCartContainer(props) {
  const {
    checkout,
    className,
    errored,
    iframeURL,
    onClick,
    price,
    purchased,
    redirectURLs,
    shoppingCart,
    upsale,
    postURL,
    postData,
  } = props;

  const {
    cdnUrl,
    site: { domain },
  } = useAppData();

  const total = shoppingCart.length * price;
  const complete =
    shoppingCart.length === purchased.length + errored.length ||
    shoppingCart.length === Object.keys(redirectURLs).length;

  const renderUpsale = () => (
    <>
      <div className="cart-item" />
      {!!shoppingCart.length &&
        shoppingCart.map((item) => {
          const itemPurchased = purchased.includes(item.UUID);
          const itemErrored = errored.includes(item.UUID);
          const itemLoading = !itemPurchased && !itemErrored;
          const redirectURL = redirectURLs[item.UUID];

          return (
            <div key={item.UUID} className="cart-item">
              {itemPurchased && <img className="success-icon" src={`${cdnUrl}/metcart/check-circle.svg`} alt="" />}
              {itemErrored && (
                <span className="error-icon" role="img" aria-label="Purchase Error">
                  &#10060;
                </span>
              )}
              {itemLoading && !redirectURL && (
                <div className="circle-border">
                  <div className="circle-core" />
                </div>
              )}
              {redirectURL && (
                <span className="redirect-icon" role="img" aria-label="Purchase Redirect">
                  &#8635;
                </span>
              )}
              <span>{item.name}</span>
              {itemPurchased && (
                <a className="visit-site" href={`https://www.${item.domain}`} target="_blank" rel="noopener noreferrer">
                  Access Site
                  <img src={`${cdnUrl}/metcart/visit.svg`} alt="" />
                </a>
              )}
              {itemErrored && !redirectURL && <span className="visit-site">Purchase failed</span>}
              {itemLoading && !redirectURL && <span className="visit-site">Processing...</span>}
              {redirectURL && !postURL && (
                <span className="visit-site">
                  <a href={redirectURL} target="_blank" rel="noopener noreferrer">
                    Click Here to Complete
                  </a>
                </span>
              )}
              {postURL && postData && (
                <span className="visit-site">
                  <form action={postURL} method="post">
                    {postData.split('&').map((pd) => {
                      const [k, v] = pd.split('=');
                      return <input type="hidden" name={decodeURIComponent(k)} key={k} value={decodeURIComponent(v)} />;
                    })}
                    <button type="submit">Complete Purchase</button>
                  </form>
                </span>
              )}
            </div>
          );
        })}
      <p className="purchased-links">
        <a href="https://metart.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noopener noreferrer">
          Contact Support
        </a>
        {' or '}
        <a href={`//${domain}`} target="_blank" rel="noopener noreferrer">
          Enter the Member&apos;s Area
        </a>
      </p>
      {complete && (
        <p>
          <button onClick={onClick} type="button">
            Close Purchases
          </button>
        </p>
      )}
      {iframeURL ? (
        <div className="cart-iframe">
          <iframe
            style={{
              width: '100%',
              height: '100%',
              margin: '0 auto',
              display: 'block',
            }}
            title="Purchase"
            src={iframeURL}
            frameBorder="0"
          />
        </div>
      ) : null}
    </>
  );

  const renderCart = () => (
    <>
      {!!shoppingCart.length &&
        shoppingCart.map((item) => (
          <div key={item.UUID} className="cart-item">
            <span>{item.name}</span>
            <img
              className="remove-item"
              onClick={() => onClick(item.UUID)}
              src={`${cdnUrl}/metcart/remove.svg`}
              alt="remove item"
            />
          </div>
        ))}
      <p className="cart-total">{`Total: ${total.toFixed(2)}`}</p>
      <button onClick={checkout} type="button" disabled={shoppingCart.length === 0}>
        Checkout
      </button>
    </>
  );

  return (
    <div className={`${className} ${upsale ? 'upsale' : ''} ${iframeURL ? 'has-iframe' : ''}`}>
      <div className="cart-head">
        <img className="logo metcart-logo" src={`${cdnUrl}/metcart/metcart.svg`} alt="" />
        <img className="logo cart-logo" src={`${cdnUrl}/metcart/shopping-cart.svg`} alt="" />
      </div>
      {upsale ? renderUpsale() : renderCart()}
    </div>
  );
}

MetCartContainer.propTypes = {
  checkout: PropTypes.func,
  className: PropTypes.string,
  errored: PropTypes.arrayOf(PropTypes.string),
  iframeURL: PropTypes.string,
  postURL: PropTypes.string,
  postData: PropTypes.string,
  onClick: PropTypes.func,
  price: PropTypes.number,
  purchased: PropTypes.arrayOf(PropTypes.string),
  redirectURLs: PropTypes.object,
  shoppingCart: PropTypes.arrayOf(
    PropTypes.shape({
      domain: PropTypes.string,
      name: PropTypes.string,
      UUID: PropTypes.string,
    }),
  ),
  upsale: PropTypes.bool,
};

MetCartContainer.defaultProps = {
  checkout: null,
  className: '',
  errored: [],
  iframeURL: null,
  onClick: null,
  price: 0,
  purchased: [],
  redirectURLs: {},
  shoppingCart: [],
  upsale: false,
  postURL: '',
  postData: '',
};

export default styled(MetCartContainer)`
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  max-height: calc(100% - 42px);
  padding: 6px;
  width: 100%;
  position: relative;

  &.has-iframe {
    height: 100%;
    max-height: 50vh;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    &:not(.upsale) {
      max-width: 156px;
    }
  }

  .cart-head {
    align-items: center;
    background-color: #000;
    border-radius: 4px 4px 0 0;
    display: flex;
    height: 28px;
    position: relative;

    .logo {
      margin: 0;
      position: absolute;
    }

    .metcart-logo {
      left: 6px;
    }

    .cart-logo {
      right: 6px;
    }
  }

  .cart-item {
    align-items: center;
    border-bottom: 1px solid lightgrey;
    display: flex;
    min-height: 36px;
    padding: 8px 4px;
    position: relative;

    .remove-item {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .visit-site {
      color: #001fff;
      position: absolute;
      right: 0;
      text-decoration: none;
      text-transform: uppercase;

      &:not(a) {
        color: inherit;
        text-transform: none;
      }

      img {
        filter: invert(92%) sepia(100%) saturate(7450%) hue-rotate(242deg) brightness(105%) contrast(124%);
        padding: 0 8px;
      }
    }
  }

  .cart-total {
    text-align: center;
    text-transform: uppercase;
  }

  button {
    background-color: #f4ba3a;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    height: 30px;
    margin: 0 auto 12px;
    min-width: 130px;

    :disabled {
      cursor: unset;
    }

    :focus {
      outline: none;
    }
  }

  .purchased-links {
    font-size: 0.875rem;
    margin: 18px 0;
    text-align: center;

    a {
      color: #001fff;
      text-decoration: none;
    }
  }

  .circle-border {
    animation: ${spin} 1s linear infinite;
    background: rgb(63, 189, 123);
    background: linear-gradient(to top, rgba(63, 189, 123, 0.05) 33%, rgba(63, 189, 123, 1) 100%);
    border-radius: 50%;
    display: inline-block;
    height: 14px;
    margin-right: 8px;
    padding: 2px;
    width: 14px;
  }

  .circle-core {
    background-color: #fff;
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }

  .error-icon {
    display: inline-block;
    font-size: 0.85rem;
    margin-right: 8px;
    width: 14px;
  }

  .success-icon {
    display: inline-block;
    margin-right: 8px;
    width: 16px;
  }

  .redirect-icon {
    display: inline-block;
    margin-right: 8px;
    width: 16px;
  }

  .cart-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
  }
`;
