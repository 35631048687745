import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Modal } from '../Modal/Modal';
import { Button } from '../TemplateA/Button/Button';
import { useAppData } from '../../helpers/useAppData';

type Props = {
  show: boolean;
  onHide: () => void;
};

export const LoginReminderModal = (props: Props) => {
  const { show, onHide } = props;
  const { site } = useAppData();

  const location = useLocation();

  const loginRedirect = useCallback(() => {
    const originalQueryString = location.search ? `&${location.search.substring(1)}` : '';
    const url = `/login/?r=${location.pathname.replace(/^\//, '')}${originalQueryString}`;
    window.location.assign(url);
  }, [location.pathname, location.search]);

  return (
    <StyledModal show={show} showHeader onHide={onHide}>
      <p className="bold">You already have an account with this email address.</p>
      <p>Please login to create your subscription.</p>
      <Button active onClick={loginRedirect}>
        Login Now
      </Button>
      <p>Forgot your password?</p>
      <a href={`${site?.ssoUrl}/forgot-password`}>Reset here</a>
      {' or '}
      <a href="https://metart.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noopener noreferrer">
        Contact Support
      </a>
    </StyledModal>
  );
};
const StyledModal = styled(Modal)`
  text-align: center;

  .modal-content {
    margin: 0 12px 24px;

    p {
      margin: 4px 0;
    }

    .bold {
      font-weight: 700;
    }

    Button {
      background-color: #ceab58;
      text-transform: uppercase;
      width: 225px;
      margin: 24px auto;
    }
  }
`;
