import { Biller, SubscriptionType } from '../../../shared/constants/billers';

export const DefaultDiscountProduct = {
  currency: 'USD',
  initialPeriod: 30,
  initialPrice: '22.49',
  limitDownloads: -1,
  networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
  price: '22.49',
  recurringPeriod: 30,
  recurringPeriodText: 'Recurring @ 22.49 / Monthly',
  recurringPrice: '22.49',
  subscriptionTypeID: SubscriptionType.Standard,
  trialRejoinPeriod: -1,
};

export const DefaultFreeSiteProduct = {
  currency: 'USD',
  initialPeriod: 30,
  initialPrice: '0',
  limitDownloads: -1,
  networkUUID: 'a4c247f3-ed92-4a70-846d-2722fd8b50f3',
  price: '0',
  recurringPeriod: 30,
  recurringPeriodText: 'Recurring @ 29.99 / Monthly',
  recurringPrice: '29.99',
  subscriptionTypeID: SubscriptionType.Trial,
  trialRejoinPeriod: -1,
};

export const DiscountProducts = {
  [Biller.RocketGate]: {
    '15a9ffa0-4e35-11df-9879-0800200c9a66': '9d391310-ce2a-4e77-8090-2d1b8ba6bff7',
    '18a2e47e-aefd-45f2-9033-a5fcaf1f5b91': '448b5a49-ab04-4d77-9b29-7f1ef324764b',
    '2163551d-11d0-4396-86ad-9d291c8dfd71': 'a0fab145-9d49-445a-ad58-f48586efb6f2',
    '3d345d1e-1569-10b4-4db5-a80cdd746318': 'bc31907a-0313-4357-a090-d62d60c8e525',
    '4f230289-82b5-42fa-9c6d-aaa747e9b5b3': '0986001e-b04b-473c-bb87-8aabb4ea0b68',
    '5592e333-2421-1e3f-f640-800200c93111': '34557adb-1ce1-4af4-a149-75ccd3c318c2',
    '605315cf-a263-6914-2d71-5ee4e9ed534e': 'eda5d623-9f5d-49c1-a5e3-c9942c1b50db',
    '706df46b-8888-4f7b-b226-097952427754': '1d9ce868-04c9-4520-8bbb-faabdfdf2da5',
    '94db3d00-36fc-11e1-b86c-0800200c9a66': 'c1c95b49-afb9-4ca4-bbdb-8a191a140489',
    'd0e7e333-2931-1e3b-b6e0-800200c93255': '540a3b16-a50b-412f-a967-2975552dd7ef',
    'd99236c0-4dd0-11e1-b86c-0800200c9a66': 'b5b2962d-8399-4b42-932e-2a95b7770bd2',
    'e6b59510-4e34-11df-9879-0800200c9a66': '7b7196c9-2130-4569-8c65-9454ad6c562b',
    'e7dfb70d-f31c-45b3-b5e0-bf10d733d349': '33594f4b-d3cd-4e51-b010-8e4f741bfba5',
    'fda02100-4e34-11df-9879-0800200c9a66': '7ea26364-3e6e-4516-a171-74a102de02ac',
    'fdafdf20-9dc3-11e0-aa82-0800200c9a66': 'a5448905-8475-40d9-9ff7-32b9cda75d68',
  } as { [key: string]: string },
};

export const DiscountFreeSiteProducts = {
  [Biller.RocketGate]: {
    '15a9ffa0-4e35-11df-9879-0800200c9a66': '2c98c43f-972d-4fba-a858-8beaa5521b87',
    '18a2e47e-aefd-45f2-9033-a5fcaf1f5b91': '8a7271c1-fefb-410f-971e-dae7e457dfd9',
    '2163551d-11d0-4396-86ad-9d291c8dfd71': 'b4987e7c-63cb-45d4-9384-8ada47c95678',
    '3d345d1e-1569-10b4-4db5-a80cdd746318': 'cb548446-c37a-4829-a238-3b9ad6d97dfe',
    '4f230289-82b5-42fa-9c6d-aaa747e9b5b3': 'c52102de-1753-4127-ba7b-4cdfec460766',
    '5592e333-2421-1e3f-f640-800200c93111': '0b3e3d23-4fb4-426e-88b5-aa63d543bf34',
    '605315cf-a263-6914-2d71-5ee4e9ed534e': '9047144b-01a5-44b9-a088-096b9aaac822',
    '706df46b-8888-4f7b-b226-097952427754': 'bdbeca64-c9a9-4366-987b-8f1c2ef24635',
    '94db3d00-36fc-11e1-b86c-0800200c9a66': 'b801712b-1cbd-4b86-8412-2791afcf0144',
    'd0e7e333-2931-1e3b-b6e0-800200c93255': '91b5887b-a907-40c0-b61c-5a9525a8c4ba',
    'd99236c0-4dd0-11e1-b86c-0800200c9a66': '71d2b87d-507d-452a-b8c1-1471183ba065',
    'e6b59510-4e34-11df-9879-0800200c9a66': 'd7788ec9-d270-4668-a120-9c68bffcfb39',
    'e7dfb70d-f31c-45b3-b5e0-bf10d733d349': 'b1e51fa5-dc0e-4e78-81c7-5f9cd85fa984',
    'fda02100-4e34-11df-9879-0800200c9a66': 'de5f3119-2622-48d2-b91d-dc7e4f752b19',
    'fdafdf20-9dc3-11e0-aa82-0800200c9a66': '21e08ed6-4586-4e16-8692-c4040b2e7f0c',
  } as { [key: string]: string },
};
