import styled from 'styled-components/macro';
import { mediaBreakpoints } from '../../helpers/mediaQueries';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media ${mediaBreakpoints.md} {
    gap: 22px;
  }
`;
