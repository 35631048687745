import styled from 'styled-components/macro';

export const ContinueToSiteWrapper = styled.div`
  grid-column: 1 / -1;
  order: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const CamsButton = styled.a`
  margin-top: 10px;
  border: 1px solid #fff;
  border-radius: 3px;
  height: 30px;
  background: #111111;
  display: flex;
  color: #fff;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  flex-basis: 50%;
`;
