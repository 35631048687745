import styled from 'styled-components/macro';
import { Button } from 'src/client/components/TemplateA/Button/Button';
import { DESKTOP, MOBILE_LANDSCAPE, TABLET_LANDSCAPE } from 'src/client/helpers/mediaQueries';

export const JoinLayout = styled.div`
  width: 100%;
  display: grid;
  column-gap: 30px;
  grid-auto-rows: minmax(min-content, auto);
  grid-template:
    'membership'
    'payment-method'
    'alternative-payments'
    'subscription';

  @media ${TABLET_LANDSCAPE} {
    grid-template:
      'membership             subscription'
      'payment-method         subscription'
      'alternative-payments   alternative-payments';

    /* Bootstrap LG-4 and LG-8 */
    grid-template-columns: 4fr 8fr;
  }

  @media ${DESKTOP} {
    /* Bootstrap XL-5 and XL-7 */
    grid-template-columns: 5fr 7fr;
  }

  > div {
    min-width: 0;
  }

  .membership {
    grid-area: membership;
  }

  .payment-method {
    grid-area: payment-method;
  }

  .subscription {
    grid-area: subscription;
  }

  .alternative-payments {
    grid-area: alternative-payments;
  }
`;

export const SectionTitle = styled.h1`
  display: flex;
  flex-direction: row;
  font-weight: 300;
  font-size: 25px;
  justify-content: flex-start;
  margin: 10px 0;

  @media ${MOBILE_LANDSCAPE} {
    font-size: 30px;
  }

  // For the translations, the numbers inside each SectionTitle are just in a
  // span with a margin-right of 0.25em. This is to make sure that the numbers
  // indent properly when the text wraps in longer languages.
  > span {
    margin-right: 0.25em;
  }
`;

export const AltSubscriptionButton = styled(Button)`
  width: auto;
  padding: 0 20px;
`;
