import React from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import styled from 'styled-components/macro';
import { useAppData } from '../../helpers/useAppData';

const settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 8000,
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface Props {
  className?: string;
}

export const Testimonials = (props: Props) => {
  const { site } = useAppData();
  const hasTestimonials = site?.testimonials && site.testimonials.length > 0;

  if (!hasTestimonials) {
    return (
      <Wrapper className={props.className}>
        <Quote>
          <span className="begin-quote">&quot;</span>
          <div className="description">
            {site?.description}
            <span className="end-quote">&quot;</span>
          </div>
        </Quote>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={props.className}>
      <StyledSlider {...settings}>
        {site?.testimonials.map((ttml) => (
          <Quote key={ttml.UUID}>
            <span className="quote-large">
              <span className="begin-quote">&quot;</span>
              <FormattedMessage id={`${ttml.UUID}.title`} />
              {!ttml.quote && <span className="end-quote">&quot;</span>}
            </span>
            <p>
              {ttml.quote && (
                <>
                  <FormattedMessage id={`${ttml.UUID}.quote`} />
                  <span className="end-quote">&quot;</span>
                </>
              )}
            </p>
            <div className="quote-footer">- {ttml.name}</div>
          </Quote>
        ))}
      </StyledSlider>
    </Wrapper>
  );
};

// We override the padding here because the Quote component needs the padding
// for it's display
const Wrapper = styled.div`
  && {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Quote = styled.div`
  color: #000;
  font-size: 12px;
  // Base 36px padding plus 10px for the "margin" so the quote marks fit
  padding: 0 46px;
  text-transform: uppercase;

  .quote-large {
    font-size: 36px;
    font-weight: 700;
    line-height: 30px;
  }

  .description {
    font-size: 1rem;
    margin-top: 6px;
  }

  .begin-quote,
  .end-quote {
    display: inline-block;
    font-style: italic;
    font-weight: 700;
    padding-right: 1em;
    position: absolute;
  }

  .begin-quote {
    font-size: 36px;
    margin-left: -27px;
  }

  .end-quote {
    font-size: 30px;
    line-height: 23px;
    margin-left: 5px;
    margin-top: 1px;
  }

  .quote-footer {
    font-weight: 700;
    margin-bottom: 10px;
    padding-top: 10px;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-dots {
    position: relative;
    bottom: 0;

    li button:before {
      font-size: 1rem;
    }
  }
`;
