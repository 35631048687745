import superagent, { SuperAgentRequest } from 'superagent';

type Method = 'get' | 'post' | 'put' | 'patch' | 'del';
const methods: Method[] = ['get', 'post', 'put', 'patch', 'del'];

function formatUrl(path: string) {
  return path[0] !== '/' ? `/${path}` : path;
}

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
  return undefined;
};

export type SuperAgentError = Error & {
  code: string;
  status: number;
  response: {
    status: number;
  };
};

type CustomSuperAgentRequest = (
  path: string,
  options?: {
    params?: Record<string, unknown>;
    data?: Record<string, unknown>;
  },
) => SuperAgentRequest;

export interface ApiClient {
  get: CustomSuperAgentRequest;
  post: CustomSuperAgentRequest;
  put: CustomSuperAgentRequest;
  patch: CustomSuperAgentRequest;
  del: CustomSuperAgentRequest;
}

export class ApiClient {
  constructor() {
    methods.forEach((method) => {
      this[method] = (path, { params, data } = {}) => {
        const request = superagent[method](formatUrl(path));
        const csrf = getCookie('_csrfToken');

        if (params) {
          request.query(params);
        }

        if (csrf) {
          request.set('csrf-token', csrf);
        }

        if (data) {
          request.send(data);
        }

        return request;
      };
    });
  }
}
