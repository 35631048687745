import React from 'react';
import styled from 'styled-components/macro';
import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';

const RemovePlanModalWrapper = styled(Modal)`
  align-items: center;
  color: #ffffff;
  display: flex;
  font-family: 'Roboto', sans-serif;
  text-align: center;

  .modal-header {
    background-color: #000000;

    .close {
      filter: unset;

      @media (max-width: 1023px) {
        right: 12px;
        top: 12px;
      }
    }
  }

  .modal-wrapper {
    background-color: #000000;
    flex-basis: 0;
    flex-grow: 1;
    height: auto;
    margin: 0 auto;
    max-width: 280px;
    padding-bottom: 48px;
    -ms-flex: 1 0 auto; // ie11 fix
    flex: 1 0 auto;

    @media (min-width: 767px) {
      max-width: 500px;
    }
  }

  .modal-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 0 12px;

    h1 {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 500;

      @media (min-width: 768px) {
        font-size: 24px;
      }

      @media (min-width: 1024px) {
        font-size: 32px;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      margin: 8px 0 38px;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin: 12px auto;
  width: 200px;
`;

type Props = {
  onHide: () => void;
  removePlan: (siteUUID: string) => void;
  siteUUID: string | null;
};

export const RemovePlanModal = (props: Props) => {
  const { onHide, removePlan, siteUUID } = props;

  if (!siteUUID) return null;

  return (
    <RemovePlanModalWrapper onHide={onHide} show={true}>
      <h1>Remove Product?</h1>
      <p>Are you sure that you want to remove the product from the cart?</p>
      <ButtonWrapper>
        <Button onClick={() => removePlan(siteUUID)} text="Yes, remove it" />
      </ButtonWrapper>
      <ButtonWrapper>
        <Button bgColor="transparent" onClick={onHide} text="No, keep it" textColor="light" underline />
      </ButtonWrapper>
    </RemovePlanModalWrapper>
  );
};
