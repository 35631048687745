import { ConversionType, ConversionTypes, CategoryIds, DeviceTypes } from 'src/shared/types/userMeasurement';
import { NetworkSite } from '../../shared/types';
import { CAMPAIGNS } from './constants';

export const saveData = (key: string, data: Record<string, unknown> | Record<string, unknown>[]) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(key, JSON.stringify(data));
  } else if (window.localStorage) {
    window.localStorage.setItem(key, JSON.stringify(data));
  }
};

export const getData = <T extends Record<string, unknown>>(key: string): Partial<T> => {
  let data = {};
  try {
    if (window.sessionStorage) {
      data = JSON.parse(window.sessionStorage.getItem(key) as string);
    } else if (window.localStorage) {
      data = JSON.parse(window.localStorage.getItem(key) as string);
    }
  } catch (err) {
    //
  }
  return data;
};

export const removeData = (key: string) => {
  if (window.sessionStorage) {
    window.sessionStorage.removeItem(key);
  } else if (window.localStorage) {
    window.localStorage.removeItem(key);
  }
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const initialPeriods: Record<number, string> = {
  365: '1 Year',
  360: '1 Year',
  30: '1 Month',
  0: 'Lifetime',
};

const recurringPeriods: Record<number, string> = {
  365: 'Yearly',
  360: 'Yearly',
  30: 'Monthly',
};

type Product = {
  initialPeriod: number;
  isPPS: boolean;
  recurringPeriod: number;
  siteUUIDs: string[];
};

export const resolveProduct = (item: Product, networkSites: NetworkSite[]) => {
  const { initialPeriod, isPPS, recurringPeriod, siteUUIDs } = item;
  let itemName = '';
  if (!isPPS) {
    itemName = initialPeriods[initialPeriod] || `${initialPeriod} day${initialPeriod > 1 ? 's' : ''}`;
    if (recurringPeriod > 0) {
      itemName = initialPeriods[initialPeriod]
        ? recurringPeriods[recurringPeriod]
        : `${initialPeriod} Day${initialPeriod > 1 ? 's' : ''}`;
    }
  }
  const brandSites = networkSites.filter((cSite) => siteUUIDs.includes(cSite.UUID));
  return {
    itemBrand: brandSites.length > 0 ? brandSites[0].name : '',
    itemName,
  };
};

export const getDeviceType = (userAgent: string) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
  const isTablet = /iPad|Android/i.test(userAgent) && !isMobile;

  if (isMobile) {
    return DeviceTypes.Mobile;
  } else if (isTablet) {
    return DeviceTypes.Tablet;
  } else {
    return DeviceTypes.Desktop;
  }
};

type PurchaseTypeOptions = {
  purchaseType: string | null;
  paymentType: string | null;
};

export const getConversionTypeByPurchase = (args: PurchaseTypeOptions): ConversionType => {
  const { paymentType, purchaseType } = args;

  if (paymentType && paymentType === 'PP') {
    return ConversionTypes.PreJoinPayPalNewSub;
  }
  if (!paymentType && purchaseType === 'cc') {
    return ConversionTypes.PreJoinCreditCardNewSub;
  }

  return ConversionTypes.PreJoinAlternativePaymentNewSub;
};

export const getCategoryIdByCampaignName = (campaignID: string | null) => {
  switch (campaignID) {
    case CAMPAIGNS.FLOATY:
      return CategoryIds.MembersPortalFloaty;
    case CAMPAIGNS.MEMBERSPORTAL_GATEWAY:
      return CategoryIds.MembersPortal;
    default:
      return CategoryIds.PageViewsAndNewSubscriptions;
  }
};
