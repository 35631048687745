import React from 'react';
import styled from 'styled-components/macro';
import { Placeholder } from 'src/client/components/Placeholder/Placeholder';
import { PlaceholderLine } from 'src/client/components/Placeholder/PlaceholderLine';
import { PaymentCard } from '../PaymentMethodSection/PaymentCard';

export const PaymentCardPlaceholder = () => {
  return (
    <PaymentCard>
      <PlaceholderWrapper>
        <Placeholder>
          <PlaceholderLine long />
        </Placeholder>
      </PlaceholderWrapper>
    </PaymentCard>
  );
};

const PlaceholderWrapper = styled.div`
  width: 100%;

  ${Placeholder} {
    width: 100%;
    text-align: center;

    ${PlaceholderLine} {
      margin: 0 auto;
    }
  }
`;
