import React from 'react';
import styled from 'styled-components/macro';
import { DESKTOP_WIDE, TABLET_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/helpers/mediaQueries';

type Props = {
  className?: string;
  discountRibbonColor?: keyof typeof colorMap | string;
  discountRibbonText: string;
  showDiscountRibbon?: boolean;
  ribbonTextColor: string;
};

const colorMap: Record<string, string> = {
  blue: '#5D83D9',
  green: '#25CD8C',
  red: '#ED1C1F',
};

export const DiscountRibbon = (props: Props) => {
  const { discountRibbonText } = props;
  if (!props.showDiscountRibbon || !discountRibbonText) return null;

  // @TODO Remove colormap after release
  const discountRibbonColor = colorMap[props.discountRibbonColor || ''] || props.discountRibbonColor;

  return (
    <Ribbon
      className={props.className}
      discountRibbonColor={discountRibbonColor}
      ribbonTextColor={props.ribbonTextColor}
    >
      {discountRibbonText}
    </Ribbon>
  );
};

const DEFAULT_BLUE = '#5D83D9';
const DEFAULT_WHITE = '#FFFFFF';

const Ribbon = styled.div<{ discountRibbonColor?: string; ribbonTextColor?: string }>`
  position: absolute;
  // 10px margin around the image
  left: 6px;
  // this includes the margin
  top: 12px;

  height: 20px;
  width: min-content;
  min-width: 70px;
  color: ${(props) => props.ribbonTextColor || DEFAULT_WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.discountRibbonColor || DEFAULT_BLUE};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: auto;
  white-space: nowrap;
  padding: 0 8px;

  @media ${TABLET_PORTRAIT} {
    // 10px margin around the image
    left: 10px;
    // this includes the margin
    top: 20px;
    height: 24px;
    min-width: 80px;
    font-size: 14px;
  }

  @media ${TABLET_LANDSCAPE} {
    // 10px margin around the image
    left: 12px;
    // this includes the margin
    top: 22px;
    height: 30px;
    min-width: 100px;
  }

  @media ${DESKTOP_WIDE} {
    left: 16px;
    top: 32px;
  }
`;
