import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { useSubscriptionRebillPeriod } from '../../pages/PreJoinPage/useSubscriptionRebillPeriod';
import { useSubscriptionInitialPeriod } from '../../pages/PreJoinPage/useSubscriptionInitialPeriod';
import { JoinPageProduct } from '../../../shared/types';
import { Price } from './Price';
import { Button } from './Button';
import { mediaBreakpoints } from '../../helpers/mediaQueries';
import { noop } from '../../helpers/noop';

type DeviceSubscriptionPlanCardProps = SubscriptionPlanCardProps & {
  amount: string;
  cents: string;
  initialPeriodMessage: string;
  rebillMessage: string;
};

const MobileSubscriptionPlanCard = (props: DeviceSubscriptionPlanCardProps) => {
  const { amount, cents, initialPeriodMessage, isActive, item, loading, onClick, rebillMessage } = props;
  const { currency, currencySymbol, recurringPeriod } = item;

  return (
    <SubscriptionCardWrapper isActive={isActive} onClick={loading ? noop : onClick}>
      <InitialPeriodWrapperMobile>
        <InitialPeriod>{initialPeriodMessage}</InitialPeriod>
        <RecurringText>
          <p>{rebillMessage}</p>
          {item.validationRequired && (
            <p>
              <FormattedMessage id="subscription.choice.validation_required" />
            </p>
          )}
        </RecurringText>
      </InitialPeriodWrapperMobile>
      <Price
        amount={amount}
        cents={cents}
        currency={currency}
        currencySymbol={currencySymbol}
        recurringPeriod={recurringPeriod}
      />
    </SubscriptionCardWrapper>
  );
};

const DesktopSubscriptionPlanCard = (props: DeviceSubscriptionPlanCardProps) => {
  const { amount, cents, initialPeriodMessage, isActive, item, loading, onClick, rebillMessage } = props;
  const { currency, currencySymbol, recurringPeriod } = item;

  return (
    <SubscriptionCardWrapper isActive={isActive}>
      <InitialPeriod>{initialPeriodMessage}</InitialPeriod>
      <Price
        amount={amount}
        cents={cents}
        currency={currency}
        currencySymbol={currencySymbol}
        recurringPeriod={recurringPeriod}
      />
      <SubscriptionButton active={isActive} loading={loading} onClick={onClick}>
        <FormattedMessage id="subscription.choice.select_plan" />
      </SubscriptionButton>
      <RecurringText>
        <p>{rebillMessage}</p>
        {item.validationRequired && (
          <p>
            <FormattedMessage id="subscription.choice.validation_required" />
          </p>
        )}
      </RecurringText>
    </SubscriptionCardWrapper>
  );
};

type SubscriptionPlanCardProps = {
  isActive: boolean;
  isMobile?: boolean;
  item: JoinPageProduct;
  loading: boolean;
  onClick: () => void;
};

export const SubscriptionPlanCard = (props: SubscriptionPlanCardProps) => {
  const { isMobile, item } = props;

  const rebillMessage = useSubscriptionRebillPeriod(item);
  const initialPeriodMessage = useSubscriptionInitialPeriod(item);

  const itemPrice = item.perPeriod ? (Number(item.price) / 12).toFixed(2) : item.price;
  const [amount, cents] = itemPrice.split('.');

  return isMobile ? (
    <MobileSubscriptionPlanCard
      amount={amount}
      cents={cents}
      initialPeriodMessage={initialPeriodMessage}
      rebillMessage={rebillMessage}
      {...props}
    />
  ) : (
    <DesktopSubscriptionPlanCard
      amount={amount}
      cents={cents}
      initialPeriodMessage={initialPeriodMessage}
      rebillMessage={rebillMessage}
      {...props}
    />
  );
};

export const SubscriptionCardWrapper = styled.div<{ isActive?: boolean; isPlaceHolder?: boolean }>`
  width: 100%;
  border-radius: 2px;
  border: 2px solid ${({ isActive }) => (isActive ? '#1E90FF' : 'rgba(0, 0, 0, 0.3)')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 14px 14px 10px;

  @media screen and ${mediaBreakpoints.md} {
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    width: 288px;
    box-shadow: ${({ isActive }) => (isActive ? '0px 4px 4px 2px #0000001A' : 'none')};
    padding: ${({ isActive, isPlaceHolder }) =>
      isActive || isPlaceHolder ? '45px 30px 25px 30px' : '40px 25px 20px 25px'};
  }

  :nth-child(n + 4) {
    display: none;
  }
`;

const RecurringText = styled.div`
  text-transform: uppercase;
  font-size: 0.6rem;
  margin: -2px 0;

  @media screen and ${mediaBreakpoints.md} {
    margin: 16px 0;
  }

  > p {
    margin: 0;
  }
`;

const InitialPeriod = styled.span`
  text-transform: uppercase;
  font-size: 1.12rem;
  font-weight: 600;

  @media screen and ${mediaBreakpoints.md} {
    font-size: 1.25rem;
  }
`;

const SubscriptionButton = styled(Button)`
  padding: 19px 32px;
  width: 100%;
  min-height: 59px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin-top: 10px;

  svg {
    /* min-height - 2x padding */
    height: 28px;
  }
`;

const InitialPeriodWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
`;
