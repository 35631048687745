import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSuccessPageQueryParams } from 'src/client/pages/SuccessPage/Hooks/useSuccessPageQueryParams';

type UsePurchaseValidationArgs = {
  setValidating: (validating: boolean) => void;
  setSuccess: (success: boolean) => void;
};

export const useHandlePurchaseResponse = ({ setValidating, setSuccess }: UsePurchaseValidationArgs) => {
  const history = useHistory();
  const { purchaseType, answer, successResponse, cartItemUUID } = useSuccessPageQueryParams();

  useEffect(() => {
    const pps = purchaseType === 'pps' ? '&pps=true' : '';

    if (answer && answer[0] === 'N') {
      history.push(`/?response=declined&payment=3${pps}`);
      return;
    }

    if (successResponse && (successResponse === 'false' || successResponse === '0')) {
      history.push(`/?response=declined${pps}`);
      return;
    }

    if (cartItemUUID) {
      setValidating(true);
    } else {
      setSuccess(true);
    }
  }, [answer, cartItemUUID, history, purchaseType, successResponse, setValidating, setSuccess]);
};
