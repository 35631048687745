import { useCookies } from 'react-cookie';
import { useQueryParams } from '../pages/PreJoinPage/PreJoinPage';

export const useAffiliateData = () => {
  const [cookies] = useCookies(['mam']);

  const { affiliateID } = useQueryParams();
  const { mam } = cookies;

  const isMAMCookie = Boolean(mam);
  const isPACookie = !isMAMCookie && Boolean(affiliateID);

  return {
    affiliateID,
    isMAMCookie,
    isPACookie,
    mam,
  };
};
