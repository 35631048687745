import React from 'react';
import styled from 'styled-components/macro';
import { Placeholder } from 'src/client/components/Placeholder/Placeholder';
import { PlaceholderLine } from 'src/client/components/Placeholder/PlaceholderLine';
import { PlaceholderImage } from 'src/client/components/Placeholder/PlaceholderImage';
import { Selectable } from 'src/client/components/TemplateA/Selectable/Selectable';
import { SelectableItemWrapper } from './SubscriptionOption';

export const SubscriptionPlaceholder = () => (
  <SelectableItemWrapper>
    <SelectablePlaceholder>
      <Placeholder>
        <PlaceholderLine className="period" medium />
        <PlaceholderImage className="price" />
        <PlaceholderImage className="button" />
        <PlaceholderLine className="text" medium />
      </Placeholder>
    </SelectablePlaceholder>
  </SelectableItemWrapper>
);

const SelectablePlaceholder = styled(Selectable)`
  & .line.period {
    margin: 22px auto;
  }

  & .image.price {
    width: 135px;
    margin: 0 auto;
    padding-top: 66px;
  }

  & .image.button {
    width: 160px;
    margin: 12px auto;
    padding-top: 48px;
  }

  & .line.text {
    margin: 12px auto;
  }
`;
