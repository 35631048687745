import React from 'react';
import styled from 'styled-components/macro';
import { useAppData } from '../../helpers/useAppData';

export const SecuritySection = () => {
  const { cdnUrl } = useAppData();

  return (
    <SecurityFeatures>
      <img src={`${cdnUrl}/join/security-icons.png`} alt="security features" />
    </SecurityFeatures>
  );
};

const SecurityFeatures = styled.section`
  margin: 24px 0;
  text-align: center;

  img {
    width: 100%;

    @media (min-width: 425px) {
      width: 400px;
    }
  }
`;
