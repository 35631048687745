import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { PurchaseProductSubscription } from '@tovia/man-protos/dist/types/billing.types';
import { useEffectOnce } from 'react-use';
import { CheckoutCart, ShoppingCartHeader, PurchaseModal, FreeSiteModal, RemovePlanModal } from 'src/client/components';
import { Biller } from 'src/shared/constants/billers';
import { marketingData } from 'src/shared/constants/marketingData';
import { AppData, NetworkSite } from 'src/shared/types';
import { useAppData, constants, useURLSearchParams } from 'src/client/helpers';
import { ChatWidget } from 'src/client/components/ChatWidget/ChatWidget';
import { Summary, SummaryWrapper as FixedSummary } from './Summary';
import {
  DefaultDiscountProduct,
  DefaultFreeSiteProduct,
  DiscountFreeSiteProducts,
  DiscountProducts,
} from './retentionProducts';
import { CartItem, useUpgradesCart } from '../Upgrades';

export interface CartPageProduct extends PurchaseProductSubscription {
  abbreviation: string;
  billerID: number;
  freeSite?: boolean;
  price: string;
  networkUUID: string;
  recurringPeriodText: string;
  site: string;
  siteUUID: string;
}

export interface PromoData {
  billerID: number;
  offerFreeSiteProduct: boolean;
  siteUUID: string;
}

export const CartPage = () => {
  const { cdnUrl, networkSites, user } = useAppData();

  const searchParams = useURLSearchParams();
  const promoParam = searchParams.get('promo');
  const promoData = useMemo(
    () => (promoParam ? (JSON.parse(window.atob(promoParam)) as PromoData) : undefined),
    [promoParam],
  );

  const source = useOrderSource();

  const {
    cartItems,
    clearCartItems,
    freeSiteModalOpen,
    hideFreeSiteModal,
    hideRemoveSiteModal,
    removeItemFromCart,
    removeSiteModalUUID,
    setCartItems,
    showFreeSiteModal,
    showRemoveSiteModal,
    total,
  } = useUpgradesCart({
    activeSite: null,
  });

  const [showPurchaseModal, setShowPurchaseModal] = useState(false);

  useEffectOnce(() => {
    const initialProducts = computeInitialProductState(promoData, user, networkSites);
    if (initialProducts.length > 0) {
      setCartItems(initialProducts);

      if (promoData?.offerFreeSiteProduct) {
        showFreeSiteModal();
      }
    }
  });

  const freeSiteProducts = useFreeSiteProducts(promoData?.offerFreeSiteProduct);
  const showShoppingButton = !promoData;

  const addFreeSiteModal = (freeSiteUUID: string) => {
    // do nothing if no site uuid, this happens when a user closes the free site modal without choosing a site
    if (!freeSiteUUID) {
      return;
    }

    const site = networkSites.find((site) => site.UUID === freeSiteUUID);

    // this shouldn't happen at this point, but just in case we stop here if the site isn't part of the network
    if (!site) {
      return;
    }

    const billerID = cartItems[0].billerID;
    const productUUID = DiscountFreeSiteProducts[billerID][freeSiteUUID];

    setCartItems((currentItems) => [
      ...currentItems,
      {
        ...DefaultFreeSiteProduct,
        abbreviation: site.abbreviation,
        billerID,
        productUUID,
        site: site.name,
        siteUUID: freeSiteUUID,
        access: DefaultFreeSiteProduct.recurringPeriodText,
        domain: site.domain,
        freeSite: true,
        freeSiteParent: cartItems[0].siteUUID,
        price: parseFloat(DefaultFreeSiteProduct.price),
      },
    ]);
  };

  const closePurchaseModal = () => setShowPurchaseModal(false);

  const upgradesUrl = [
    constants.routerPaths.upgrades.sites,
    searchParams.values.length > 0 ? '?' + searchParams.toString() : '',
  ].join('');

  return (
    <PageWrapper>
      <Helmet>
        <title>Complete Your Purchase</title>
      </Helmet>
      <HeaderBarWrapper>
        <ShoppingCartHeader count={cartItems.length} />
      </HeaderBarWrapper>
      <CartWrapper>
        <Items>
          <CheckoutCart
            items={cartItems}
            onStartPurchase={setShowPurchaseModal}
            showCompleteButton={cartItems.length > 0}
            removePlan={showRemoveSiteModal}
            responsive
            total={total}
          />
          {showShoppingButton && (
            <ContinueShopping>
              <NavLink to={upgradesUrl}>
                <img src={`${cdnUrl}/cart/arrow.svg`} alt="" />
                <span>Continue Shopping</span>
              </NavLink>
            </ContinueShopping>
          )}
        </Items>
        <SummaryWrapper>
          <Summary items={cartItems} total={total} />
          <PurchaseModal
            onClick={closePurchaseModal}
            onComplete={clearCartItems}
            products={cartItems}
            showPurchaseModal={showPurchaseModal}
            type="upgrade"
            source={source}
          />
        </SummaryWrapper>
      </CartWrapper>

      <RemovePlanModal removePlan={removeItemFromCart} siteUUID={removeSiteModalUUID} onHide={hideRemoveSiteModal} />

      <FreeSiteModal
        addFreeSiteToCart={addFreeSiteModal}
        cart={cartItems}
        close={hideFreeSiteModal}
        products={freeSiteProducts}
        show={freeSiteModalOpen}
        type="discountOffer"
      />

      <ChatWidget jira-id="9dab1c19-00bf-3be7-b9b6-14a91ae44549" service-desk-id="3" />
    </PageWrapper>
  );
};

const useOrderSource = () => {
  // We're gonna get a path like /<something>/checkout here.
  // we want that <something>
  const { path } = useRouteMatch();
  const [, part] = path.split('/');

  switch (part) {
    case 'members-portal':
      return 'members-portal';
    case 'upgrades':
      return 'upgrade-portal';
    default:
      return 'unknown-source';
  }
};

const computeInitialProductState = (
  promoData: PromoData | undefined,
  user: AppData['user'],
  networkSites: NetworkSite[],
) => {
  const products: CartItem[] = [];

  if (promoData) {
    const site = networkSites.find((site) => site.UUID === promoData.siteUUID);

    // won't add product to the cart unless we have the site data
    if (site) {
      const billerID = promoData.billerID || user.previousBillerID || Biller.RocketGate;
      const productUUID = DiscountProducts[billerID][promoData.siteUUID];

      products.push({
        ...DefaultDiscountProduct,
        abbreviation: site.abbreviation,
        billerID,
        productUUID,
        site: site.name,
        access: DefaultDiscountProduct.recurringPeriodText,
        domain: site.domain,
        freeSite: false,
        networkUUID: site.networkUUID,
        price: parseFloat(DefaultDiscountProduct.price),
        siteUUID: promoData.siteUUID,
      });
    }
  }

  return products;
};

const useFreeSiteProducts = (freeSiteProduct: boolean | undefined) => {
  const { user } = useAppData();

  if (!freeSiteProduct) {
    return [];
  }

  // The marketingData sites have the cross promo sites in them too, so we filter
  // those out by checking for an empty networkUUID.
  return marketingData.sites
    .filter((upgrade) => upgrade.networkUUID !== '00000000-0000-0000-0000-000000000000')
    .map((site) => ({
      abbreviation: site.abbreviation,
      freeSiteEligible: user.subscribedSites ? !user.subscribedSites[site.abbreviation] : true,
      name: site.name,
      networkUUID: site.networkUUID,
      UUID: site.UUID,
    }));
};

const PageWrapper = styled.div`
  background-color: #1c1c1c;
  position: relative;
  min-height: 100vh;
`;

const HeaderBarWrapper = styled.div`
  background-color: #000000;
  width: 100%;
`;

const CartWrapper = styled.div`
  display: flex;
  color: #ffffff;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px 24px 100px;
  @media (min-width: 1338px) {
    padding: 64px;
  }
`;

const Items = styled.div`
  width: 500px;

  @media (min-width: 1024px) {
    padding: 0 64px 64px;
  }

  @media (min-width: 1338px) {
    width: 840px;
  }
`;

const SummaryWrapper = styled.div`
  display: none;
  flex-grow: 0;
  width: 370px;

  @media (min-width: 1024px) {
    display: unset;
  }

  & ${FixedSummary} {
    position: fixed;
    width: inherit;
  }
`;

const ContinueShopping = styled.div`
  cursor: pointer;
  display: inline-block;

  > a {
    color: #ffffff;
    text-decoration: none;
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
      width: 12px;
    }

    span {
      text-transform: uppercase;
    }
  }
`;
