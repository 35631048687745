import { useState } from 'react';
import createPersistedState from 'use-persisted-state';
// Pull this directly from it's file to not pull in anything else from man-app-utils
import { ensure } from '@tovia/man-app-utils/lib/helpers/type-utils';
import { upgradeCartStorageKey } from 'src/client/helpers/constants';
import { Biller, SubscriptionType } from 'src/shared/constants/billers';
import { UpgradeProduct } from 'src/shared/types';
import { useAppData } from 'src/client/helpers';

export type CartItem = {
  abbreviation: string;
  access: string;
  billerID: number;
  domain: string;
  freeSite?: boolean;
  freeSiteParent?: string;
  networkUUID: string;
  price: number;
  productUUID: string;
  site: string;
  siteUUID: string;
  subscriptionTypeID?: number;
};

const freeSiteProduct = {
  // These values are added below in the `selectPlan` function, they are
  // the "dynamic" values for the products.

  // access: product.recurringPeriodText,
  // abbreviation: activeSite.abbreviation,
  // domain: activeSite.domain,
  // networkUUID: activeSite.networkUUID,
  // site: activeSite.name,
  // siteUUID: activeSite.siteUUID,
  // price: parseFloat(product.price),

  // These are required values for the object in use, they aren't used for the
  // free sites, so just define them to be static.

  currency: 'USD',
  freeSite: true,
  initialPeriod: 365,
  initialPrice: '0',
  price: '0',
  productUUID: '',
  recurringPeriod: 0,
  recurringPrice: '0',
  recurringPeriodText: '1 Year',
  subscriptionType: SubscriptionType.OneTime,
};

const useCartState = createPersistedState<CartItem[]>(upgradeCartStorageKey);

type Params = {
  activeSite: UpgradeProduct | null;
  upgrades?: UpgradeProduct[];
};

export const useUpgradesCart = ({ activeSite, upgrades }: Params) => {
  const { user } = useAppData();
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
  const [freeSiteModalOpen, setFreeSiteModalOpen] = useState<boolean>(false);
  const [removeSiteModalUUID, setRemoveSiteModalUUID] = useState<string | null>(null);
  const [cartItems, setCartItems] = useCartState([]);

  const selectPlan = (productUUID: string) => {
    if (!activeSite) return;

    // Make sure you can't pick the same product
    const existingProduct = cartItems.find((item) => item.siteUUID === activeSite?.UUID);
    if (existingProduct?.productUUID === productUUID) {
      return;
    }

    // If the existing product is from the same site as the active product, we
    // remove it from the cart to replace it with the new product
    const sitesToRemove = [existingProduct?.siteUUID];

    // If the existing product is a lifetime product, we also remove the free site
    // that was attached to it
    const existingFreeSite = cartItems.find(
      (item) => item.freeSite && item.freeSiteParent === existingProduct?.siteUUID,
    );
    if (existingProduct?.subscriptionTypeID === SubscriptionType.Lifetime && existingFreeSite) {
      sitesToRemove.push(existingFreeSite.siteUUID);
    }

    // If the new product is a lifetime product, show them the 'add free site' modal
    const product = ensure(activeSite.products.find((product) => product.productUUID === productUUID));
    const isLifetimeProduct = product.subscriptionTypeID === SubscriptionType.Lifetime;
    if (isLifetimeProduct) {
      const newFreeSite = cartItems.find((item) => item.freeSite && item.freeSiteParent === activeSite.UUID);
      if (!newFreeSite) {
        showFreeSiteModal();
      }
    } else {
      showCartDrawer();
    }

    // need to spread here to create a new array, or else dependent props won't
    // update in the render because the Cart is using the site uuid for the key.
    // We should probably use the productUUID instead
    const updatedCart = [...cartItems].filter((item) => !sitesToRemove.includes(item.siteUUID));

    updatedCart.push({
      ...product,
      access: product.recurringPeriodText,
      abbreviation: activeSite.abbreviation,
      domain: activeSite.domain || '',
      networkUUID: activeSite.networkUUID,
      site: activeSite.name,
      siteUUID: activeSite.UUID,
      price: parseFloat(product.price),
    });

    setCartItems(updatedCart);
  };

  const removeItemFromCart = (siteUUID: string) => {
    // Same as above, if it's a lifetime subscription, we also remove the free site
    const itemToRemove = cartItems.find((item) => item.siteUUID === siteUUID);

    const freeSite = cartItems.find(
      (item) =>
        itemToRemove?.subscriptionTypeID === SubscriptionType.Lifetime &&
        item.freeSite &&
        item.freeSiteParent === siteUUID,
    );

    const sitesToRemove = [itemToRemove, freeSite];
    const updatedCart = cartItems.filter((item) => !sitesToRemove.includes(item));
    setCartItems([...updatedCart]);
    hideRemoveSiteModal();
  };

  const total = cartItems.reduce((acc, val) => val.price + acc, 0);

  const addFreeSiteToCart = (freeSiteUUID: string) => {
    if (!upgrades) return;

    const freeSite = upgrades.find((site) => site.UUID === freeSiteUUID);
    if (!freeSite) return;

    const newCart = [...cartItems];
    const existingProductIndex = newCart.findIndex((item) => item.siteUUID === freeSiteUUID);

    if (existingProductIndex !== -1) {
      newCart.splice(existingProductIndex, 1);
    }

    const billerID = user.previousBillerID || Biller.RocketGate;

    newCart.push({
      ...freeSiteProduct,
      access: freeSiteProduct.recurringPeriodText,
      abbreviation: freeSite.abbreviation,
      billerID,
      domain: freeSite.domain || '',
      networkUUID: freeSite.networkUUID,
      site: freeSite.name,
      siteUUID: freeSite.UUID,
      freeSiteParent: activeSite?.UUID,
      price: parseFloat(freeSiteProduct.price),
    });

    setCartItems(newCart);
  };
  const clearCartItems = () => setCartItems([]);
  const hideCartDrawer = () => setCartDrawerOpen(false);
  const hideFreeSiteModal = () => setFreeSiteModalOpen(false);
  const hideRemoveSiteModal = () => setRemoveSiteModalUUID(null);
  const showCartDrawer = () => setCartDrawerOpen(true);
  const showFreeSiteModal = () => setFreeSiteModalOpen(true);
  const showRemoveSiteModal = (uuid: string) => setRemoveSiteModalUUID(uuid);

  const finishFreeSiteModal = () => {
    hideFreeSiteModal();
    showCartDrawer();
  };

  return {
    addFreeSiteToCart,
    cartDrawerOpen,
    cartItems,
    clearCartItems,
    freeSiteModalOpen,
    finishFreeSiteModal,
    hideCartDrawer,
    hideFreeSiteModal,
    hideRemoveSiteModal,
    removeItemFromCart,
    removeSiteModalUUID,
    selectPlan,
    setCartItems,
    showCartDrawer,
    showFreeSiteModal,
    showRemoveSiteModal,
    total,
  };
};
