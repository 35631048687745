import React from 'react';
import useSWR from 'swr';
import styled from 'styled-components/macro';
import { getCamsResponse } from '@tovia/man-protos/dist/types/cams.types';
import { ApiClient } from 'src/client/helpers/ApiClient';
import { apiUrls } from 'src/client/helpers/constants';
import { ConstrainedContainer, TABLET_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/helpers/mediaQueries';
import { useAppData } from 'src/client/helpers';
import { CamThumbnail } from './CamThumbnail';
import { CamsButton } from './styles';

type Params = Record<string, unknown>;

const fetcher = (url: string, options: Params) => new ApiClient().get(url, { params: options }).then((res) => res.body);

type Props = {
  camTrackingId?: string;
  children?: React.ReactNode;
};

export const CamsSection = (props: Props) => {
  const { data } = useSWR<{ cams: getCamsResponse['siteCams'] }>([apiUrls.get.cams], fetcher);
  const { site, siteSubdomain } = useAppData();

  const camTrackingSearchParam = props.camTrackingId
    ? `?${new URLSearchParams({ AFNO: props.camTrackingId }).toString()}`
    : '';
  const allCamsLink = new URL(`/cams${camTrackingSearchParam}`, `https://${siteSubdomain}.${site.domain}`);

  if (!data?.cams) {
    return null;
  }

  return (
    <CamsSectionWrapper>
      <Title>Live Models</Title>
      <CamsGrid>
        {data.cams.map((cam) => (
          <CamThumbnail key={cam.id} cam={cam} camTrackingId={props.camTrackingId} />
        ))}
      </CamsGrid>
      <ButtonGroup>
        <CamsButton href={allCamsLink.href} target="_blank" rel="nofollow noopener noreferrer">
          View all cams
        </CamsButton>
        {props.children}
      </ButtonGroup>
    </CamsSectionWrapper>
  );
};

const CamsSectionWrapper = styled(ConstrainedContainer)`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  text-align: center;
  color: white;
  font-size: 21px;
  font-weight: normal;
`;

const CamsGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);

  @media ${TABLET_PORTRAIT} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${TABLET_LANDSCAPE} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const ButtonGroup = styled.div`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  @media ${TABLET_PORTRAIT} {
    padding: 0 25%;
  }

  @media ${TABLET_LANDSCAPE} {
    padding: 0 calc(100% / 3);
  }
`;
