import React from 'react';
import styled from 'styled-components';
import { DefaultToast, ToastProps } from 'react-toast-notifications';

const Wrapper = styled.div`
  & > div {
    height: auto !important; // must be important because ToastElement has a style prop which will override css

    & .react-toast-notifications__toast {
      min-width: 300px;
      max-width: 360px;
      width: auto;
    }
  }
`;

export const CustomToast = (props: ToastProps) => {
  const { children, ...rest } = props;

  return (
    <Wrapper>
      <DefaultToast {...rest}>{children}</DefaultToast>
    </Wrapper>
  );
};
